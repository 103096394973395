/**
 *  Login.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio - Kath
 *  @description: Modal del Login para desktop
*/

import React, { Component } from "react";
import Request from "../../core/httpClient";
import { Consumer } from "../../context";
import { withRouter, Link } from "react-router-dom";
import superagent from "superagent";

class ModalLogin extends Component {

	constructor(props) {
		super(props);
		this.state = {
			error: '', user: { id: 0, tipo: 0 }
		}
	}
	
	render() {
		return (
			<div className="column">
				<div className="modal justify-center hidden" id="modal-login">
					<div className="container modal-content align-center column" >
						<div className="white-space-48"></div>
						<h1 className="text-center">
							Inicio de sesión
						</h1>
						<button className="btn auto" id="close-modal-login">
							<i className="fas fa-times"></i>
						</button>
						<div className="white-space-16"></div>
						<form className="column align-center justify-center" onSubmit={this.handleSubmit.bind(this)}> 
							<div className="basic-data column justify-center align-center">
								<div className="input-container align-center justify-center">
									<input type="email" name="email" placeholder="Correo electrónico" className="input input-login" minLength="8" maxLength="32" required />
								</div>
								<div className="white-space-8"></div>
								<div className="input-container align-center justify-center">                     
									<input type="password" name="password" placeholder="Contraseña" className="input input-login" minLength="8" maxLength="16" required />
								</div>
								<div className="white-space-16"></div>
								<div className="btn-container justify-center">
									<button type="submit" className="btn-modal-login btn-pink font-small text-center color-white weight-bold">
									{
										this.state.loading ?
										(<i className="fas fa-spin fa-spinner color-white"></i>) :
										("INICIAR SESIÓN")
									}
									</button>
								</div>
								<div className="white-space-8"></div>
								<div className="btn-container justify-center">
									<button type="button" className="btn-border-pink text-center color-pink weight-semi" onClick = {this.connectInstagram.bind(this)} >
										<i className="fab fa-instagram icon-insta"></i>
										INCIAR CON INSTAGRAM
									</button>
								</div>
								<div className="white-space-16"></div>
								<div className="justify-center wow fadeIn faster">
									<Link to="/recoverpassword" className="text-center">
										¿Olvidaste tu contraseña?
									</Link>
								</div>
								<div className="white-space-8"></div>
								<p className="text-center font-mini">
									{
										this.state.message ? 
										<strong>{ this.state.message }</strong> :
										<strong className="color-white">{ "" }</strong>
									}
								</p>
							</div>                 
						</form>
						<div className="white-space-32"></div>
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		let modalNewsLetter = document.getElementById('modal-login');
		document.getElementById('close-modal-login').onclick = () => {
			modalNewsLetter.classList.add('fadeOut');
			setTimeout(() => {
				modalNewsLetter.classList.add('hidden');
			}, 500);
		}

		if (this.props.location.hash) {
			const hash = String(this.props.location.hash).split("=");
			this.instagramRequest(hash[1]);
		}
	}

	async connectInstagram() {
		this.setState({ loadingInstagram: true });
		window.location = 'https://api.instagram.com/oauth/authorize/?client_id=e889ae006edb437dbdc8bddc7d8c859a&redirect_uri=https://www.waalainfluencers.com/&response_type=token&hl=en&scope=likes+follower_list';
	}

	async instagramRequest(token) {
		this.setState({ loadingInstagram: true });
		const accesToken = token;
		try {
			const instagramData = await superagent.get(`https://api.instagram.com/v1/users/self/?access_token=${accesToken}`)
			if (instagramData && instagramData.body.data) {
				this.setState({ id_instagram: instagramData.body.data.id, loadingInstagram: false });
				this.hanldeSubmitInstagram();
			}
		} catch (error) {
			console.log(error);
			this.setState({ loadingInstagram: false });
		}
	}

	async hanldeSubmitInstagram() {
		this.setState({ loading: true });
		const data = { id_instagram: this.state.id_instagram };
		const request = new Request();
		const response = await request.post("/users/login/instagram", data);
		if (response) {
			if (response.error) {
				this.setState({ message: response.message });
			} else {
				if ( response.user ) {
					this.props.context.logIn({ id: response.user, auth: true });
					const user = await this.props.context.loadUser();
					this.setState({ user: user.id });
					if (user) {
						if (user.id.tipo === 1) {
							if (user.id.first_login === 1) {
								this.props.history.push("/ajustes/perfil");
							} else {
								this.props.history.push("/inicio");
							}
						} else {
							window.localStorage.removeItem('whtab');
							window.localStorage.removeItem('wftab');
							if (user.id.tipo === 2) {
								if (user.id.first_login === 1) {
									this.props.history.push("/ajustes/perfil/marca");
								} else {
									this.props.history.push("/inicio");
								}
							}
						}
					} else {
						this.setState({ message: "NO EXISTE EL USUARIO" });
					}
				} else {
					this.setState({ message: "NO EXISTE EL USUARIO SOLICIDATO" });
				}
			}
		} else {
			this.setState({ message: "ERROR DE COMUNICACIÓN LA WEB" });
		}
		this.setState({ loading: false });
	}

	async handleSubmit(event) {
		this.setState({ loading: true });
		event.preventDefault();
		const form = event.target;
		const data = { Email: form.email.value, Password: form.password.value };
		const request = new Request();
		const response = await request.post("/users/login", data);
		if (response) {
			if (response.error) {
				this.setState({ message: response.message });
			} else {
				if ( response.user ) {
					this.props.context.logIn({ id: response.user, auth: true });
					const user = await this.props.context.loadUser();
					this.setState({ user: user.id });
					if (user) {
						if (user.id.tipo === 1) {
							if (user.id.first_login === 1) {
								this.props.history.push("/ajustes/perfil");
							} else {
								this.props.history.push("/inicio");
							}
						} else {
							if (user.id.tipo === 2) {
								if (user.id.first_login === 1) {
									this.props.history.push("/ajustes/perfil/marca");
								} else {
									this.props.history.push("/inicio");
								}
							}
						}
					} else {
						this.setState({ message: "NO EXISTE EL USUARIO" });
					}
				} else {
					this.setState({ message: "NO EXISTE EL USUARIO SOLICIDATO" });
				}
			}
		} else {
			this.setState({ message: "ERROR DE COMUNICACIÓN LA WEB" });
		}
		this.setState({ loading: false });
	}

}



export default withRouter(Consumer(ModalLogin));

/**
 *  blocked-accounts.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath, Mariano, Sergio
 *  @description: Página de cuentas bloqueadas
*/

import React, { Component } from 'react';
import { HeaderBloqued, HeaderWebHomeLoggedIn } from '../components/header/header';
import BottomBar from '../components/bottom-bar/bottombar';
import { Consumer } from '../context';
import BloquedCard from '../components/cards/bloqued';
import Request from '../core/httpClient';
import ModalAlert from '../components/modals/profileAlert';
import LeftBarSetting from '../components/left-bar/left-settings-bar';
import LeftBar from '../components/left-bar/left-bar';
import { Link } from 'react-router-dom';
import ModalPublicationAdd from '../components/modals/publicationsAdd'; 
import MediaQuery from 'react-responsive';
import WOW from 'wowjs';

const req = new Request();

class Blocked extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {id: 0, tipo: 0},
            bloqueados: []
        }
    }

    async load() {
        const user = await this.props.context.loadUser();
        if(user) {
            this.setState({userData: user.id});
            const get = this.state.userData.tipo === 2? 'influencers' : 'marcas';
            const res = await req.post(`/bloqueados/${get}`, {id: user.id.id});
            if(res.bloqueados) {
                this.setState({bloqueados: res.bloqueados});
            } else {
                this.setState({bloqueados:[]});
            };
        }
    }

    async block() {
        this.setState({ loading: true });
        const input = document.getElementById('usuario');
        console.log(this.state);
        if(input) {
            const data = { usuario: input.value, user: this.state.userData }
            const res = await req.post('/bloqueados/block', data);
            if(res.blocked) {
                this.setState({ loading: false });
                this.load();
            } else {
                this.setState({ loading: false });
                let modal = document.getElementById('modal-profileAlert');
				if (modal.classList.contains('hidden')) {
					modal.classList.remove('fadeOut');
					modal.classList.add('animated','fadeIn');
					modal.classList.remove('hidden');
                }
                setTimeout(() => {
                    let modal = document.getElementById('modal-profileAlert');
                    modal.classList.remove('fadeIn');
					modal.classList.add('fadeOut');
                    modal.classList.add('hidden');
                }, 2000);
            }
        }
    }

    render() {
        return(
            <div className="settings blocked column justify-center align-center">
            	<MediaQuery minDeviceWidth={769}>
                <div className="settings view-web column">
                <HeaderWebHomeLoggedIn/>
                <div className="row">
                    <LeftBar/>
                    <div className="column align-center">
                    <div className="white-space-32"></div>
                       <div className="row-responsive container">
							<LeftBarSetting link="/ajustes/perfil" option="3"/>
                              <div className="column container-web-blocked ">                               
                               
                                <div className="white-space-64"></div>
                                <div className="justify-center">
                                <div className="column blocked-container grid-container">
                                    {this.state.bloqueados.map((user)=>{
                                        return (
                                            <BloquedCard view = {"desktop"} key = { user.id } { ...user } userData = { this.state.userData } load = { this.load.bind(this)}/>
                                        )
                                    })}
                                    </div>
                                </div>
                                <div className="controlls top">
                                    <input id="usuario" type="text" className="input" placeholder="Nombre de usuario a bloquear"/>
                                    <button className="btn btn-square btn-pink color-white weight-semi" onClick = { this.block.bind(this) }>
                                        {
                                            this.state.loading ? ( <i className="fas fa-spinner fa-spin color-white"></i> ) : ("BLOQUEAR")
                                        }
                                    </button>
                                </div>                               
                            </div>
                       </div>
                       <ModalPublicationAdd/>
                    </div>
                </div>
                </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                <HeaderBloqued />
                <div className="container justify-center">
                <div className="column blocked-container">
                    {this.state.bloqueados.map((user)=>{
                        return (
                            <BloquedCard key = { user.id } { ...user } userData = { this.state.userData } load = { this.load.bind(this) }/>
                        )
                    })}
                    </div>
                </div>
                <div className="controlls">
                    <input id="usuario" type="text" className="input" placeholder="Nombre de usuario a bloquear"/>
                    <button className="btn btn-square btn-pink color-white weight-semi" onClick = { this.block.bind(this) }>
                        {
                            this.state.loading ? ( <i className="fas fa-spinner fa-spin color-white"></i> ) : ("BLOQUEAR")
                        }
                    </button>
                </div>
                <BottomBar />
                </MediaQuery>
                <ModalAlert title = "NO EXISTE USUARIO" description = "Verifica el nombre de usuario a bloquear" />
            </div>
        );
    }

    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        this.load();
    }
}

export default Consumer(Blocked);
/**
 *  favorites.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de favoritos
*/

import React, { Component, Fragment } from 'react';
import Header, { HeaderWebHomeLoggedIn } from '../components/header/header';
import Tabs from '../components/tabs/tabs';
import FavInfluencer from '../components/favorites/influencers';
import FavBrands from '../components/favorites/brands';
import FavPublications from '../components/favorites/publications';
import BottomBar from '../components/bottom-bar/bottombar';
import { Consumer } from '../context';
import Request from '../core/httpClient';
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import { Helmet } from 'react-helmet';
import ModalPublicationAdd from '../components/modals/publicationsAdd'; 

const req = new Request();
class Favorites extends Component {
    constructor(props) {
        super(props);
        this.state={
            user: {id: 0, tipo: 0},
            tab: 'first',
            publicaciones: [],
            marcas: [],
            influencers: []
        }
    }

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        this.props.context.setPrevLocation(this.props.location);
    }

    handleWebAdd() {
        let modalAdd = document.getElementById('modal-Add');
        modalAdd.classList.remove('fadeOut');
        modalAdd.classList.add('animated', 'fadeIn','faster');
        modalAdd.classList.remove('hidden');
        
    }

    async load() {
        const tab = window.localStorage.getItem('wftab');
        if(tab) {
            this.setState({tab});
        }
        const user = await this.props.context.loadUser();
        if(user) {
            this.setState({user: user.id});
            if(user.id.tipo.toString() === "1") {
                this.loadPublications();
                this.loadBrands();
            } else {
                this.loadInfluencers();
            }
        }
    }

    async loadPublications() {
        this.setState({loading: true});
        const data = { id_influencer: this.state.user.id };
        const publicaciones = await req.post('/favoritos/get/publications', data);
        if(publicaciones.favoritos) {
            this.setState({publicaciones: publicaciones.favoritos, loading: false});
        }
        else {
            this.setState({ emptyPublicaciones: true, loading: false });
        }
    }

    async loadBrands() {
        this.setState({ loading: true });
        const data = { id_influencer: this.state.user.id };
        const marcas = await req.post('/favoritos/get/brands', data);
        if(marcas.favoritos) {
            this.setState({marcas: marcas.favoritos, loading: false});
        } else {
            this.setState({ emptyMarcas: true, loading: false });
        }
    }

    async loadInfluencers() {
        this.setState({ loading: true });
        const data = { id_marca: this.state.user.id };
        const influencers = await req.post('/favoritos/get/influencers', data);
        if(influencers.favoritos) {
            this.setState({influencers: influencers.favoritos, loading: false});
        } else {
            this.setState({emptyInfluencers: true, loading: false});
        }
    }

    changeTab(tab) {
        window.localStorage.setItem('wftab', tab)
        this.setState({tab});
    }

    render() {
        const user = this.state.user;
        const tipo = user.tipo ? user.tipo.toString() : '0';
        return (
            <div className="favorites column">
                <Helmet>
                    <title>WAALA - Favoritos</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Favoritos" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <HeaderWebHomeLoggedIn />
                        <div className="row">
                            <LeftBar active = "menuFavorites" />
                            <div className="column">
                                <div className="row justify-center">
                                    <div className="container column align-center justify-center">
                                        <div className="white-space-24"></div>
                                        {
                                            this.state.user.tipo.toString() === "1" ?
                                                <Tabs tab = { this.state.tab } first = { this.state.user.tipo.toString() === "1" ? "PUBLICACIONES" : "" }
                                                second = "MARCAS" user = { this.state.user }  changeTab = { this.changeTab.bind(this)} />
                                            : null
                                        }
                                        <div className="white-space-24"></div>
                                        <div className="row container-favorites wrap">
                                            {
                                                this.state.user.tipo.toString() === "1" ?
                                                <Fragment>
                                                    {
                                                        this.state.tab === 'first' ?
                                                            this.state.loading ?
                                                            (
                                                                <div className="column loading-center">
                                                                    <div className="white-space-16"></div>
                                                                    <div className="justify-center">
                                                                        <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                                    </div>
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                this.state.emptyPublicaciones ?
                                                                (
                                                                    <div className="column loading-center">
                                                                        <div className="white-space-32"></div>
                                                                        <div className="justify-center">
                                                                            <h3>SIN FAVORITOS</h3>
                                                                        </div>
                                                                        <div className="white-space-16"></div>
                                                                        <p className="text-center">
                                                                            Pulsa <i className="far fa-heart color-pink"></i> para añadir marcas o publicaciones a favoritos.</p>
                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    this.state.publicaciones.map((publicacion, index) =>
                                                                        <FavPublications key={index} {...publicacion} tipo={this.state.user.tipo} />)
                                                                )
                                                            )
                                                        :

                                                        (
                                                            this.state.loading ?
                                                            (
                                                                <div className="column loading-center">
                                                                    <div className="white-space-16"></div>
                                                                    <div className="justify-center">
                                                                        <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                                    </div>
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                this.state.emptyMarcas ? 
                                                                (
                                                                    <div className="column loading-center">
                                                                        <div className="white-space-32"></div>
                                                                        <div className="justify-center">
                                                                            <h3>SIN FAVORITOS</h3>
                                                                        </div>
                                                                        <div className="white-space-16"></div>
                                                                        <p className="text-center">
                                                                            Pulsa <i className="far fa-heart color-pink"></i> para añadir marcas o publicaciones a favoritos.
                                                                        </p>
                                                                    </div>
                                                                ) 
                                                                :
                                                                (
                                                                    this.state.marcas.map((marca, index) => <FavBrands key={index} {...marca} />)
                                                                )
                                                            )
                                                        )
                                                    }
                                                </Fragment>
                                                :
                                                (
                                                    this.state.loading ?
                                                    (
                                                        <div className="column loading-center">
                                                            <div className="white-space-16"></div>
                                                            <div className="justify-center">
                                                                <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        this.state.emptyInfluencers ? 
                                                        (
                                                            <div className="column loading-center">
                                                                <div className="white-space-32"></div>
                                                                <div className="justify-center">
                                                                    <h3>SIN FAVORITOS</h3>
                                                                </div>
                                                                <div className="white-space-16"></div>
                                                                <p className="text-center">
                                                                    Pulsa <i className="far fa-heart color-pink"></i> para añadir influencers a favoritos.
                                                                </p>
                                                            </div>
                                                        ) 
                                                        :
                                                        (
                                                            this.state.influencers.map((influencer, index) => <FavInfluencer key={index} {...influencer} tipo={this.state.user.tipo} idMarca = { this.state.user.id }/>)
                                                        )
                                                    )
                                                )}
                                        </div>
                                        <div className="white-space-32"></div>
                                    </div>
                                </div>
                                <ModalPublicationAdd/>
                            </div>
                        </div>                 
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                    <div className="column sticky-header">
                        <Header context={this.props.context} showBack={false} tipo={tipo} />
                        {
                            this.state.user.tipo.toString() === "1" ?
                                <Tabs tab={this.state.tab} first={this.state.user.tipo.toString() === "1" ? "PUBLICACIONES" : ""} second="MARCAS" user={this.state.user} changeTab={this.changeTab.bind(this)} /> : null
                        }
                    </div>
                    <div className="justify-center">
                        <div className="container container-favorites column">
                            {
                                this.state.user.tipo.toString() === "1" ?
                                    <Fragment>
                                        {
                                            this.state.tab === 'first' ?
                                                this.state.loading ?
                                                (
                                                    <div className="column">
                                                        <div className="white-space-16"></div>
                                                        <div className="justify-center">
                                                            <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                (
                                                    this.state.emptyPublicaciones ?
                                                    (
                                                        <div className="column">
                                                            <div className="white-space-32"></div>
                                                            <div className="justify-center">
                                                                <h3>SIN FAVORITOS</h3>
                                                            </div>
                                                            <div className="white-space-16"></div>
                                                            <p className="text-center">
                                                            Pulsa <i className="far fa-heart color-pink"></i> para añadir publicaciones a favoritos.
                                                            </p>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        this.state.publicaciones.map((publicacion, index) =>
                                                            <FavPublications key = { index } { ...publicacion } tipo = { this.state.user.tipo } />)
                                                    )
                                                )
                                            :

                                            (
                                                this.state.loading ?
                                                (
                                                    <div className="column">
                                                        <div className="white-space-16"></div>
                                                        <div className="justify-center">
                                                            <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                (
                                                    this.state.emptyMarcas ? 
                                                    (
                                                        <div className="column">
                                                            <div className="white-space-32"></div>
                                                            <div className="justify-center">
                                                                <h3>SIN FAVORITOS</h3>
                                                            </div>
                                                            <div className="white-space-16"></div>
                                                            <p className="text-center">
                                                                Pulsa <i className="far fa-heart color-pink"></i> para añadir marcas a favoritos.
                                                            </p>
                                                        </div>
                                                    ) 
                                                    :
                                                    (
                                                        <React.Fragment>
                                                            { this.state.marcas.map((marca, index) => <FavBrands key = { index } { ...marca } idInfluencer = { this.state.user.id } tipo = { this.state.user.tipo } />) }
                                                        </React.Fragment>
                                                    )
                                                )
                                            )
                                        }
                                    </Fragment>
                                :
                                (
                                    this.state.loading ?
                                    (
                                        <div className="column">
                                            <div className="white-space-16"></div>
                                            <div className="justify-center">
                                                <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        this.state.emptyInfluencers ? 
                                        (
                                            <div className="column">
                                                <div className="white-space-32"></div>
                                                <div className="justify-center">
                                                    <h3>SIN FAVORITOS</h3>
                                                </div>
                                                <div className="white-space-16"></div>
                                                <p className="text-center">
                                                    Pulsa <i className="far fa-heart color-pink"></i> para añadir influencers a favoritos.
                                                </p>
                                            </div>
                                        ) 
                                        :
                                        (
                                            this.state.influencers.map((influencer, index) => <FavInfluencer key = { index } { ...influencer } tipo = { this.state.user.tipo } idMarca = { this.state.user.id } />)
                                        )
                                    )
                                )
                            }

                        </div>
                    </div>
                    <div className="white-space-64"></div>
                    <BottomBar active = "bottomFavorites" />                
                </MediaQuery>
            </div>
        );
    }

}

export default Consumer(Favorites);


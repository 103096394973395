/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath, Sergio
 *  @description: vista de ajustes seguridad y privacidad
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HeaderWebHomeLoggedIn } from '../components/header/header';
import LeftBar from '../components/left-bar/left-bar';
import MediaQuery from 'react-responsive';
import LeftBarSetting from '../components/left-bar/left-settings-bar';
import Contact from '../components/modals/contact';

class SettingsSecurity extends Component {
    render() {
        return (
            <div className="settings column">
                <MediaQuery minDeviceWidth = { 768 }>
                    <HeaderWebHomeLoggedIn />
                    <div className="row">
                        <LeftBar active = "menuSettings"/>
                        <div className="column align-center">
                        <div className="white-space-32"></div>
                            <div className="row-responsive container">
                                <LeftBarSetting link="/ajustes/perfil" option = "5"/>
                                <div className="column">
                                    <form className="column">                               
                                        <div className="row">
                                            <div className="column right-main content-forms align-center">
                                                <h4>
                                                    Seguridad y Privacidad
                                                </h4>
                                                <div className="white-space-8"></div>
                                                <div className="input-container align-center justify-center">
                                                    <i className="fas fa-folder-open  font-medium color-pink"></i>
                                                    <Link to="/ajustes/bloqueos" className="input input-profile">
                                                        Cuentas bloqueadas
                                                    </Link>
                                                </div>
                                                <div className="white-space-8"></div>
                                                <div className="input-container align-center justify-center">
                                                    <i className="fas fa-shield-alt font-medium color-pink"></i>
                                                    <div className="input input-profile" onClick = { this.handleContact.bind(this) }>
                                                        Enviar comentarios o reportar
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Contact />
                </MediaQuery>
            </div>
        );
    }

    handleContact() {
        const modal = document.getElementById('modal-contact');
        if (modal) {
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
        }
    }

}

export default SettingsSecurity;
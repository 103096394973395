/**
 *  interests.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio - Kath, Mariano
 *  @description: Página de selección de interés
*/

import React, { Component } from 'react';
import { HeaderSimple } from '../components/header/header';
import { Consumer } from '../context';
import { withRouter, Link } from 'react-router-dom';
import Request from '../core/httpClient';
import MediaQuery from 'react-responsive';
import WOW from 'wowjs';
import { Helmet } from 'react-helmet';
import ModalAlert from '../components/modals/profileAlert';

class Interests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categorias: [], intereses: [], user: {id: 0, tipo: 0}
        };
    }
        
    handleClick(categoria) {
        let intereses = this.state.intereses;
        const idCat = intereses.findIndex(interes => interes.cat === categoria);
        if(idCat >= 0) {
            intereses = intereses.filter((interes, index) => index !== idCat);
        } else {
            intereses.push({cat: categoria});
        }
        this.setState({intereses, message: "FINALIZAR REGISTRO"});
    }

    async registerUser(desktop) {
        this.setState({loading: true});
        if(this.state.intereses.length <= 0) {
            this.setState({message: 'SELECCIONA POR LO MENOS 1 INTERÉS', loading: false});
            return;
        }
        const registerData = this.props.context.registerData;
        if(registerData) {
            const req = new Request();
            const data = {
                ...registerData,
                intereses: JSON.stringify(this.state.intereses)
            }
            const res = await req.post('/users/register', data);
            this.setState({message: res.message});
            if(res.register) {
                if (desktop) {
                    let modal = document.getElementById('modal-profileAlert');
                    if (modal.classList.contains('hidden')) {
                        modal.classList.remove('fadeOut');
                        modal.classList.add('animated', 'fadeIn');
                        modal.classList.remove('hidden');
                    }
                    setTimeout(()=>{
                        this.props.history.push('/');
                    }, 5000);
                } else {
                    let modal = document.getElementById('modal-profileAlert');
                    if (modal.classList.contains('hidden')) {
                        modal.classList.remove('fadeOut');
                        modal.classList.add('animated', 'fadeIn');
                        modal.classList.remove('hidden');
                    }
                    setTimeout(()=>{
                        this.props.history.push('/login');
                    }, 5000);
                }
            }
        } else {
            this.props.history.push('/registro');
        }
        this.setState({ loading: false });
    }

    render() {
        return (
            <div className="interests column">
                <Helmet>
                    <title>WAALA - Intereses</title>
                    <meta property="og:title" content="WAALA - Intereses" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <header className="header-web-interest justify-center">
                            <div className="container">
                                <div className="header-search align-center">
                                    <div className="row justify-center align-center">  
                                        <div className="column auto">   
                                            <Link to="/"> 
                                                <i className="fas fa-angle-left fa-1x color-white back-icon font-regular" id="back-interest"></i>
                                            </Link> 
                                        </div> 
                                        <div className="column">
                                            <h2 className="color-white font-regular">
                                                Seleciona tus intereses
                                            </h2>
                                        </div> 
                                        <div className="column">
                                            <div className="row justify-end">
                                                <div className="btn-container auto btn-splash justify-center">
                                                    <a href="#" className="btn-web btn-border-white font-small  text-center color-white weight-bold" onClick = { this.registerUser.bind(this, true) }>
                                                    {
                                                        this.state.loading ? 
                                                        ( <i className="fas fa-spin fa-spinner color-white"></i> ) :
                                                        (
                                                            this.state.message ?
                                                            ( this.state.message ) :
                                                            ( "FINALIZAR REGISTRO" )
                                                        )
                                                    }
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="column justify-center align-center">
                            <div className="white-space-32"></div>
                            <div className="container">
                                <div className="interests-options">
                                    <div className="container-grid">
                                    {
                                        this.state.categorias.map((cat, index) => 
                                            (
                                                <div className="photo wow animated fadeIn faster responsive-img" key = { index }>
                                                    <div className = { `tittle align-center justify-center ${ this.state.intereses.find(inter => inter.cat === cat.categoria) ? 'active-option': null }`} onClick = { this.handleClick.bind(this, cat.categoria) }>
                                                        <h4 className="color-white font-regular text-center">
                                                            { cat.categoria }
                                                        </h4>
                                                    </div>
                                                    <img  className="img-gallery" src = { cat.image } alt="Imagen de interes" title="Imagen de interes" />
                                                </div>
                                            )
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalAlert title = "USUARIO REGISTRADO" description = "Por favor active su cuenta por correo" />
                </MediaQuery>
                <MediaQuery maxWidth = { 768 } >
                    <HeaderSimple />
                    <div className="white-space-32"></div>
                    <div className="justify-center">
                        <div className="container column">
                            <h2 className="text-center wow fadeIn">
                                Selecciona tus intereses
                            </h2>
                            <div className="white-space-16"></div>
                            <div className="interests-container wrap">
                                {
                                    this.state.categorias.map((cat, index) => (
                                        <div className="interests-element column auto justify-center align-center wow animated faster fadeIn" key={index}>
                                            <div className = {`circle column justify-center align-center ${this.state.intereses.find(inter => inter.cat === cat.categoria)? 'active': null }`}
                                                onClick = { this.handleClick.bind(this, cat.categoria) }>
                                                <div className="icon justify-center">
                                                    <i className = { cat.icon }></i>
                                                </div>
                                            </div>
                                            <div className="white-space-8"></div>
                                            <p className="text-center">
                                                { cat.categoria }
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="white-space-16"></div>
                        </div>
                    </div>
                    <ModalAlert title = "Te has registrado correctamente" description = "Por favor, comprueba tu email." />
                    <div className="white-space-8"></div>
                    <div className="btn-container justify-center" id="btn-top-intereses">
                        <button className="btn btn-purple btn-square color-white weight-semi font-regular text-center" onClick = { this.registerUser.bind(this, false) }>
                            {
                                this.state.loading ? 
                                ( <i className="fas fa-spin fa-spinner color-white"></i> ) :
                                (
                                    this.state.message ?
                                    ( this.state.message ) :
                                    ( "FINALIZAR REGISTRO" )
                                )
                            }
                        </button>
                    </div>
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        new WOW.WOW({ live: false }).init();
        this.load();
    }

    async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({user: user.id});
        	this.validateUser();
        }
        const req = new Request();
        const res = await req.get('/intereses/');
        if (res.categorias) {
            this.setState({ categorias: res.categorias });
            this.state.intereses.find(inter => inter.cat === 'animales' ? 'active' : null);
        }
    }    

    validateUser() {
        if (this.state.user && this.state.user.id) { window.location = '/inicio'; }
    }

}

export default withRouter(Consumer(Interests));
/**
 *  suscription.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: vista de suscripcion
*/

import React, { Component } from 'react';
import { Consumer } from "../context";
import { Helmet } from 'react-helmet';
import { HeaderBack } from '../components/header/header';
import ModalAlert from '../components/modals/profileAlert';

class Suscription extends Component {

    render() {
        return (
            <div className="suscription column">
                <Helmet>
                    <title>WAALA - Suscripción</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Suscripción" />
                </Helmet>
                <HeaderBack back = "/ajustes" />
                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-32"></div>
                        <h2 className="text-center wow fadeIn">
                            SUSCRIPCIÓN A WAALA
                        </h2>
                        <p className="text-center">
                            Explora y contacta a Influencers sin límites, con un pago recurrente mensual de <b>€ 9</b>
                        </p>
                        <div className="white-space-16"></div>
                        <div className="suscription-card column">
                            <div className="top">
                                <div>
                                    <p className="font-mini color-darkgray">
                                        Estado:
                                    </p>
                                    <p className="font-mini weight-semi color-pink">
                                        &nbsp; ACTIVO
                                    </p>
                                </div>
                                <div>
                                    <p className="font-mini color-darkgray">
                                        Renovación:
                                    </p>
                                    <p className="font-mini weight-semi color-purple">
                                        &nbsp; 10/10/19
                                    </p>
                                </div>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="controlls">
                                <div className="div">
                                    <button className="btn btn-square btn-pink color-white weight-semi">
                                        <i className="fas fa-sync-alt"></i> RENOVAR
                                    </button>
                                </div>
                                <div className="div">
                                    <button className="btn btn-square btn-red color-white weight-semi">
                                        <i className="fas fa-times"></i> CANCELAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalAlert title="TITULO" description="Descripción" />
            </div>
        );
    }
}

export default Consumer(Suscription);
/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Headers como componentes
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../img/waalatext.png';
import Isotipo from '../../img/logopink.png';
import LogoW from '../../img/logotransparente.png';
import { withRouter } from 'react-router-dom';
import ImageLoader from 'react-loading-image';
import LoaderImage from '../../img/loader.gif';
import ButtonAdd from '../header/header-button-add';
import ButtonLogin from '../header/header-button-login';
import HeaderButtonRegister from '../header/header-button-register';
import { Consumer } from '../../context';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleSearch(event) {
        event.preventDefault();
        this.props.history.push(`/buscar/?search=${event.target.search.value}`);
    }

    handleToSearch() {
        this.props.history.push(`/buscar/?search=`);
    }

    render() {
        let showBack = '';
        let showSettings = '';
        let prevLocation = '/inicio';
        if(window.localStorage.getItem('waala_location_chat')) {
            prevLocation = window.localStorage.getItem('waala_location_chat');
        } else if (this.props.context.prevLocation) { 
            prevLocation = this.props.context.prevLocation;
        }
        if (this.props.showBack) { showBack = this.props.showBack; }
        // if (this.props.tipo) { console.log(this.props.tipo); }
        if (this.props.showSettings) { showSettings = this.props.showSettings; }

        return (
            <header className="header-single justify-center">
                <div className="container">
                    <div className="header-search align-center">
                        {
                            showBack ?
                            (
                                <div className="back auto justify-center align-center">
                                    <Link to = { prevLocation } >
                                        <i className="fas fa-angle-left color-white font-regular"></i>
                                    </Link>
                                </div>
                            ) : null
                        }
                        <div className="align-center justify-center search-icon-center">
                            <button type="button" onClick = { this.handleToSearch.bind(this) }>
                                <i className={`color-purple fas fa-search icon-search-single ${this.props.tipo === "1" ? 'i-influencers' : 'i-marcas' }`}></i>
                                {
                                    this.props.tipo === "1" ?
                                        <p className="color-purple p-marcas">
                                            { 'Busca marcas' }
                                        </p> :
                                        <p className="color-purple p-influencers">
                                            { 'Busca influencers' }
                                        </p>
                                }
                            </button>
                        </div>
                        {
                            showSettings ?
                            (
                            <div className="icon-favorites auto justify-center align-center">
                                <Link to="/ajustes" className="icon-favorites">
                                    <i className="fas fa-cog font-medium color-white icon-favorites"></i>
                                </Link>
                            </div>
                            ) : null
                        }
                    </div>       
                </div>
            </header>
        );
    }
}

const HeaderSimple = () => (

    <header className="header-single justify-center">
        <div className="container">
            <div className="align-center">
                <div className="logo responsive-img justify-center align-center">
                    <img src={Logo} alt="Logo Waala" title="Logo Waala" />
                </div>
            </div>
        </div>
    </header>    

);

const HeaderSingle = () => (
    <header className="header-single justify-center">
        <div className="container">
            <div className="align-center">                
                    <Link to="/" className="back auto">
                        <i className="fas fa-angle-left color-white font-regular"></i>
                    </Link>                
                <div className="logo responsive-img justify-center align-center">
                    <img src={ Logo } alt="Logo Waala" title="Logo Waala" />
                </div>
            </div>
        </div>
    </header>
);

const HeaderBack = (props) => (
    <header className="header-single justify-center">
        <div className="container">
            <div className="header-search align-center">
                <div className="back auto justify-center align-center">
                    <Link to = { props.back } >
                        <i className="fas fa-angle-left color-white font-regular"></i>
                    </Link>
                </div>
                <form className="align-center justify-center">
                    <i className="icon-search fas fa-search"></i>
                    <input className="input-search" type="text" name="search" placeholder="Buscar por nombre" />
                </form>
            </div>
        </div>
    </header>
);

const HeaderPassword = () => (
    <header className="header-single justify-center">
        <div className="container">
            <div className="header-search align-center">
            <div className="row justify-center align-center">               
              <h4 className="color-white text-center">Contraseña</h4>
            </div>
            </div>
        </div>
    </header>
);

const HeaderCuentaBan = () => (
    <header className="header-single justify-center">
        <div className="container">
            <div className="header-search align-center">
            <div className="row justify-center align-center">               
              <h4 className="color-white text-center">Datos Bancarios</h4>
            </div>
            </div>
        </div>
    </header>
);

const HeaderBloqued = () => (
    <header className="header-single header-bloqued justify-center">
        <div className="container">
            <div className="header-search align-center">
                <div className="row justify-center align-center">   
                    <div className="back auto justify-start align-center">
                        <Link to = "/ajustes">
                            <i className="fas fa-angle-left color-white font-regular"></i>
                        </Link>
                    </div>            
                    <h4 className="color-white text-center">Cuentas Bloqueadas</h4>
                </div>
            </div>
        </div>
    </header>
);

const HeaderChat = (props) => {
    let prevLocation = '/chats';
    if(props.prevLocation) {
        prevLocation = props.prevLocation;
    }
    if(window.localStorage.getItem('waala_location_profile')) {
        prevLocation = JSON.parse(window.localStorage.getItem('waala_location_profile'));
    }
    return (
        <header className="header-chat justify-center align-center">
            <div className="container container-desktop">
                <div className="header-search align-center">
                    <div className="back auto justify-center align-center">
                        <Link to = { prevLocation }>
                            <i className="fas fa-angle-left color-white font-regular"></i>
                        </Link>
                    </div>

                    <Link className="header-profile" to = { `/${ props.userData.tipo.toString() === "1" ? 'marca':'influencer'}/${ props.recibe.usuario }` }
                        onClick={() => window.localStorage.setItem('waala_location_chat', `/chat/${ props.recibe.usuario}`)}>
                    <div >
                        <div className="image auto responsive-img justify-start align-center">
                            <ImageLoader src = { props.recibe.imagen } alt="Imagen de perfil" title="Imagen de perfil"
                                loading = { () => <img className="cover-img loader-img" alt="Imagen de perfil" title="Imagen de perfil" src = { LoaderImage } /> } />
                        </div>
                        <div className="description column auto">
                            <h5 className="text-header weight-semi">
                                { props.recibe.nombre }
                            </h5>
                            <p className="text-header">
                                @{ props.recibe.usuario }
                            </p>
                        </div>
                    </div>
                    </Link>
                    { 
                        props.recibe.tipo === 1 && props.publication > 0 ?
                        <div className="pay align-center justify-end">
                            <button className="btn btn-purple color-white weight-semi" onClick={props.pagar.bind(this)}>
                                <i className="fas fa-dollar-sign space-icon"></i> Pagar
                            </button>
                        </div>
                        : null
                    }
                    <div className="auto align-center search">
                        <div className="align-center auto">
                            <a className="search-chat-icon" onClick = { props.openBuscar.bind(this) }>
                                <i className="fas fa-search"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
      )
};

const HeaderChatSearch = (props) => (
    <div id="chat-search" className="hidden justify-center">
        <form className="container container-controls" onSubmit={props.buscar.bind(this)}>
            <div className="left">
                <input type="text" autoComplete="off" name="mensaje" className="input" placeholder="Escribe un mensaje" autoComplete="none"/>
            </div>
            <div className="right auto align-center">
                <button type="submit" className="btn">
                    <i className="fas fa-search"></i>
                </button>
            </div>
        </form>
    </div>
)

const HeaderEmpty = () => (
<header className="header-single justify-center">
    <div className="container">
        <div className="header-search align-center">
            <form className="align-center justify-center">
                <i className="icon-search fas fa-search"></i>
                <input className="input-search" type="text" name="search" placeholder="Buscar por nombre" />
            </form>
        </div>
    </div>
</header>
);

const HeaderWebLogin = () => (
    <header className="header-web-login justify-center" id="header">
        <div className="container">
            <div className="header-search align-center">
                <div className="row justify-center align-center">
                    <div className="column">   
                        <div className="row">   
                        <div className="logo auto justify-start align-center">
                            <img className="logo letter" src = { LogoW } alt="Logo Waala" title="Logo Waala" id="w-logo" />
                        </div>  
                        <div className="logo justify-start align-center">
                            <img className="logo" src = { Logo } alt="Logo Waala" title="Logo Waala" />
                        </div>  
                        </div>
                    </div>
                    <div className="column">
                        <div className="row justify-end">
                            <ButtonLogin />
                            <HeaderButtonRegister />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
);

const HeaderWebLoginHome = () => (
    <header className="header-web-login2 justify-center">
        <div className="container">
            <div className="header-search align-center">
            <div className="row justify-center align-center">  
                <div className="column">         
                    <div className="logo justify-start align-center">
                        <img className="logo" src = {Logo} alt="Logo Waala" title="Logo Waala" />
                    </div>  
                </div> 
                <div className="column">
                    <form className="align-center justify-center"> 
                        <i className="icon-search-web fas fa-search"></i>
                        <input className="input-search-web"  type="text" name="search" placeholder="Buscar por nombre" />
                    </form>
               </div> 
               <div className="column">
                    <div className="row justify-end">
                        <div className="btn-container auto btn-splash justify-center">
                            <Link to="/registro" className="btn-web btn-border-white  text-center color-white">
                             Iniciar Sesión
                            </Link>
                        </div>
                        <div className="btn-container auto btn-splash justify-center">
                            <Link to="/registro" className="btn-web btn-border-white  text-center color-white">
                             registro
                            </Link>
                        </div>
                    </div>
               </div>
              </div>
            </div>
        </div>
    </header>
);

const HeaderWebInterests = () => (
    <header className="header-web-interest justify-center">
        <div className="container">
            <div className="header-search align-center">
                <div className="row justify-center align-center">  
                    <div className="column auto">   
                        <Link to="/"> 
                            <i className="fas fa-angle-left fa-1x color-white back-icon font-regular"></i> 
                        </Link> 
                    </div> 
                    <div className="column">
                        <h2 className="color-white font-regular">
                            Seleciona tus intereses
                        </h2>
                    </div> 
                    <div className="column">
                        <div className="row justify-end">
                            <div className="btn-container auto btn-splash justify-center">
                                <Link to="/" className="btn-web btn-border-white font-small  text-center color-white weight-bold">
                                    FINALIZAR
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
);

const HeaderWebHomeLoggedIn = () => (
    <header className="header-logged justify-center">
        <div className="container main-container">
            <div className="header-search align-center">
                <div className="row justify-center align-center">  
                    <div className="column column-logo-header">
                        <div className="logo">
                            <Link to ="/inicio" className="justify-start align-center">
                                <img className="logo" src = { Isotipo } alt="Logo Waala" title="Logo Waala" />
                            </Link>
                        </div>
                    </div>
                    <div className="column-both justify-end">
                        <div className="column column-search-header">
                            <button className="btn-web text-center color-purple font-small weight-bold btn-search-purple" onClick = { handleToSearchWeb.bind(this) } >
                                <i className="fas fas fa-search icon-add"></i>Buscar
                            </button>
                        </div> 
                        <ButtonAdd />
                    </div>
                </div>
            </div>
        </div>
    </header>
);

const handleSearch = (event) => {
    event.preventDefault();
    window.location = `/buscar/?search=${event.target.search.value}`;
}

const handleToSearchWeb = (event) => {
    event.preventDefault();
    window.location = `/buscar/?search=`;
}

export default withRouter(Consumer(Header));
export { HeaderSingle, HeaderSimple, HeaderBack, HeaderChat, HeaderEmpty,HeaderPassword,HeaderWebLogin, HeaderWebLoginHome,HeaderWebInterests,HeaderWebHomeLoggedIn,HeaderBloqued, HeaderChatSearch, HeaderCuentaBan };


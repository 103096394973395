import React, { Component } from 'react';
import Request from '../../core/httpClient';
const request = new Request();

class ModalDeleteInfluencerPublication extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingDelete: false
        }
    }

    render() {
        return(
            <div className="modal column justify-center align-center hidden faster" id="modal-deleInfluencerPublication">
                <div className="container modal-content align-center column" >
                    <div className="column modal-head">
                        <div className="white-space-64"></div>
                        <h3 className="text-center">
                            ¿ELIMINAR IMAGEN?
                        </h3>
                        <div className="white-space-8"></div>
                        <p className="text-center">
                            Esta acción no puede deshacerse
                        </p>
                        <div className="white-space-32"></div>
                    </div>
                    {
                        this.state.loadingDelete ?
                        (
                            <div className="column">
                                <div className="justify-center">
                                    <i className="fas fa-spin fa-spinner color-pink"></i>
                                </div>
                                <div className="white-space-16"></div>
                            </div>
                        )
                        :
                        (
                            this.state.message ?
                            (
                                <div className="column">
                                    <p className="text-center weight-semi">
                                        { this.state.message }
                                    </p>
                                    <div className="white-space-16"></div>
                                </div>
                            )
                            :
                            (
                                <div className="modal-controlls">
                                    <button className="btn btn-square btn-pink color-white weight-semi" id="btn-deletePublication" onClick = { this.handleDelete.bind(this, this.props.idImage, this.props.idInfluencer ) }  >
                                        <i className="fas fa-trash-alt color-white"></i> SI
                                    </button>
                                    <button className="btn btn-square btn-purple color-white weight-semi" id="btn-deletePublicationCancel" onClick = { this.handleClose.bind(this) }>
                                        <i className="fas fa-times color-white"></i> NO
                                    </button>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        );
    }

    handleClose() {
        document.getElementById('modal-deleInfluencerPublication').classList.remove('fadeIn');
        document.getElementById('modal-deleInfluencerPublication').classList.add('fadeOut');
        setTimeout(() => {
            document.getElementById('modal-deleInfluencerPublication').classList.add('hidden');
        }, 500);
    }
    
    async handleDelete(idImage, idInfluencer) {
        this.setState({loadingDelete: true});
        console.log(`idImagen: ${idImage} idInfluencer: ${idInfluencer}`);        
        this.setState({ loadingDelete: true });        
        const data = {
            idImage: idImage,
            idInfluencer: idInfluencer
        };
        const deleteRequest = await request.post('/images/delete', data);
        if (deleteRequest && deleteRequest.deleted) {
            this.setState({ message: deleteRequest.message, loadingDelete: false });
            setTimeout(() => {
                window.location = '/perfil/influencer';
            }, 2000);
        } else {
            this.setState({ loadingDelete: false, message: deleteRequest.message });
        }
    }

}

export default ModalDeleteInfluencerPublication;
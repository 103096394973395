/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath, Sergio
 *  @description: Pagina de busqueda
*/

import React, { Component } from 'react';
import { Consumer } from "../context";
import HeaderSearch  from '../components/header/headerSearch';
import BrandCard from '../components/cards/brands';
import PublicationCard from '../components/cards/publications';
import InfluencerCard from '../components/cards/influencers';
import BottomBar from '../components/bottom-bar/bottombar';
import Tabs from '../components/tabs/tabs';
import Request from '../core/httpClient';
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import { Helmet } from 'react-helmet';

const req = new Request();

class search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: {id: 0, tipo: 0},
			tab: 'first',
			cards: [],
			cardsTmp: []
		}
	}
	

	componentWillUnmount() {
        this.props.context.setPrevLocation(this.props.location);
        this.mounted = false;
	}

	changeTab(tab) {
		window.localStorage.setItem('wstab', tab)
		this.setState({tab, cards: []});
		this.load();
    }

	async filterCards(filters) {
		this.setState({ loading: true });
		document.getElementById('menyAvPaa').checked = false;
		let cards = this.state.cardsTmp;
		const input = document.getElementById('search');
		let search = '';
		if(input) {
			search = input.value
		}
		const data = {
			ciudad: filters.ciudad || '',
			followers: filters.followers || '',
			genero: filters.genero || '',
			intereses: filters.intereses || '',
			maxprice: filters.maxprice || '',
			minprice: filters.minprice || '',
			search,
			tipo: this.state.userData.tipo,
			id: this.state.userData.id,
			filtros: true
		};
		const res = await req.post('/buscar/filtros', data);
		if(res.busqueda) {
			cards = res.busqueda;
		}
		let cardsFilter = cards.filter(card => {
			if(filters.followers) {
				const range = filters.followers.split(';');
				if(card.seguidores < parseFloat(range[0]) || card.seguidores > parseFloat(range[1])) {
					return false;
				}
			}
			if(filters.minprice && card.pago) {
				if(parseFloat(card.pago) < parseFloat(filters.minprice)) {
					return false;
				}
			}
			if(filters.maxprice && card.pago) {
				if(parseFloat(card.pago) > parseFloat(filters.maxprice)) {
					return false;
				}
			}
			if(filters.ciudad && card.ciudad) {
				if(card.ciudad.indexOf(filters.ciudad) === -1) {
					return false;
				}
			}
			if(filters.genero && card.genero) {
				if(card.genero.indexOf(filters.genero) === -1) {
					return false;
				}
			}
			if(filters.intereses && card.intereses) {
				if(card.intereses.indexOf(filters.intereses) === -1) {
					return false;
				}
			}
			if(filters.sector && card.sector) {
				if(card.sector.toString() !== filters.sector.toString()) {
					return false;
				}
			}
			/*if(filters.interest && card.intereses) {
				let exists = false;
				filters.interest.forEach(interes => {
					console.log(interes.categoria, card);
					if(card.intereses.indexOf(interes.categoria) !== -1) {
						exists = true;
					}
				})
				if(!exists) {
					return false;
				}
			}*/
			return true;
		});
		cardsFilter = cardsFilter.filter(card => {
			if (this.state.tab === 'first') {
				if (card.tipo_pub === 'publicacion' || card.tipo_pub === 'influencer') {
					return card;
				}
			} else {
				if (card.tipo_pub === 'marca') {
					return card;
				}
			}
		})
		this.setState({loading: false, cards: cardsFilter, empty: false});
		if(this.state.userData.tipo.toString() === '1') {
			if (this.state.tab === 'first') {
				const publicaciones = cardsFilter.filter(pub => pub.tipo_pub === 'publicacion');
				if(publicaciones.length <= 0) {
					this.setState({empty: true});
				}
			} else if(this.state.tab === 'second') {
				const marcas = cardsFilter.filter(pub => pub.tipo_pub === 'marca');
				if(marcas.length <= 0) {
					this.setState({empty: true});
				}
			}
		}
	}

	render() {
		const user = this.state.userData;
		let tipo = '0';
		if(user) {
			tipo = this.state.userData.tipo.toString();
		}
		const cardSearch = (props) => {
			return user.tipo.toString() === "2"? 
				<InfluencerCard {...props}/> : 
				this.state.tab === 'first'? <PublicationCard {...props}/> : <BrandCard {...props}/>;
		}
    	return (
    		<div className="search column">
				<Helmet>
					<title>WAALA - Buscador</title>
					<meta property="og:title" content="WAALA - Buscador" />
				</Helmet>
				<MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
						<HeaderSearch cards = { this.state.cardsTmp} filterCards={this.filterCards.bind(this) } />
                        <div className="row">
                            <LeftBar/>
                            <div className="column">
							<div className="white-space-16"></div>
							<div className="justify-center">
								<div className="container">
									{
										tipo === "1" ?
											<Tabs tab={this.state.tab} first={tipo === "1" ? "PUBLICACIONES" : ""}
												second="MARCAS" user={this.state.userData} changeTab={this.changeTab.bind(this)} />
											: null
									}
								</div>
							</div>
							<div className="justify-center">
							<div className={`container column ${this.state.empty ? '' : 'cards-container' }`}>
								{
									this.state.loading ?
									(
										<div className="column loading-center">
											<div className="white-space-64"></div>
											<div className="justify-center">
												<i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
											</div>
										</div>
									)
									:
									(
										this.state.empty ?
										(
											<div className="column">
												<div className="white-space-16"></div>
												<div className="justify-center">
													<h3 className="text-center">
														NO SE ENCONTRARON RESULTADOS
													</h3>
												</div>
												<div className="white-space-16"></div>
												<p className="text-center">
													Intenta buscar con palabras clave
												</p>
												<div className="white-space-16"></div>
												<i className="text-center fa-2x far fa-frown color-pink"></i>
											</div>
										)
										:
										(
											this.state.cards.map((card, index) => cardSearch({
												key: index,
												id: card.id,
												usuario: card.usuario,
												tarifa: card.pago,
												ciudad: card.ciudad,
												seguidores: card.seguidores,
												imagen: card.imagen,
												favorito: card.favorito,
												userId: this.state.userData.id,
												tipo: this.state.userData.tipo,
												tipo_pub: card.tipo_pub,
												nombre: card.nombre,
												titulo: card.titulo
												})
											)
										)
									)
								}
								<div className="white-space-64"></div>
							</div>
						</div>
				
							</div>
						</div>
					</div>
				</MediaQuery>
				<MediaQuery maxWidth = { 768 }>
					<div className="column sticky-header">
						<HeaderSearch cards={this.state.cardsTmp} filterCards={this.filterCards.bind(this)} />
						{
							tipo === "1" ?
								<Tabs tab={this.state.tab} first={tipo === "1" ? "PUBLICACIONES" : ""}
									second="MARCAS" user={this.state.userData} changeTab={this.changeTab.bind(this)} />
								: null
						}
					</div>
				<div className="justify-center">
					<div className="cards-container container column">
						{
							this.state.loading ?
							(
								<div className="column">
									<div className="white-space-16"></div>
									<div className="justify-center">
										<i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
									</div>
								</div>
							)
							:
							(
								this.state.empty ?
								(
									<div className="column">
										<div className="white-space-16"></div>
										<div className="justify-center">
											<h3 className="text-center">
												NO SE ENCONTRARON RESULTADOS
											</h3>
										</div>
										<div className="white-space-16"></div>
										<p className="text-center">
											Intenta buscar con palabras clave
										</p>
										<div className="white-space-16"></div>
										<i className="text-center fa-2x far fa-frown color-pink"></i>
									</div>
								)
								:
								(
									this.state.cards.map((card, index) => cardSearch({
										key: index,
										id: card.id,
										usuario: card.usuario,
										tarifa: card.pago,
										ciudad: card.ciudad,
										seguidores: card.seguidores,
										imagen: card.imagen,
										favorito: card.favorito,
										userId: this.state.userData.id,
										tipo: this.state.userData.tipo,
										tipo_pub: card.tipo_pub,
										nombre: card.nombre,
										titulo: card.titulo
										})
									)
								)
							)
						}
						<div className="white-space-64"></div>
					</div>
				</div>
				<BottomBar />
				</MediaQuery>
          	</div>
       	);
	}

	componentDidMount() {
		this.load();
		if (document.getElementsByClassName('input-search-search')[0]) {
			document.getElementsByClassName('input-search-search')[0].focus();
		}
		if (document.getElementsByClassName('input-search-web')[0]) {
			document.getElementsByClassName('input-search-web')[0].focus();
		}
	}

	async load() {
		const user = await this.props.context.loadUser();
		if(user) {
			this.setState({userData: user.id})
		}
		const tab = window.localStorage.getItem('wstab');
        if(tab) {
            this.setState({tab});
        }
		this.setState({ loading: true });
		const params = new URLSearchParams(this.props.location.search);
		let search = '';
		if (params) {
			search = params.get('search');
			const input = document.getElementById('search');
			if (input) {
				input.value = search;
			}
		}
		const data = {
			search,
			tipo: user.id.tipo,
			id: user.id.id
		}
		const res = await req.post('/buscar', data);
		if (res.busqueda) {
			const cards = res.busqueda.filter(card => {
				if (this.state.tab === 'first') {
					if (card.tipo_pub === 'publicacion' || card.tipo_pub === 'influencer') {
						return card;
					}
				} else {
					if (card.tipo_pub === 'marca') {
						return card;
					}
				}
			})
			this.setState({ cards, cardsTmp: cards, loading: false, empty: false });
			// Analisis de tabs
			if(this.state.userData.tipo.toString() === '1') {
				const publicaciones = cards.filter(pub => pub.tipo_pub === 'publicacion');
				const marcas = cards.filter(pub => pub.tipo_pub === 'marca');
				if (this.state.tab === 'first') {
					if(publicaciones.length <= 0 ) {
						this.setState({empty: true});
					}
				} else if(this.state.tab === 'second') {
					if(marcas.length <= 0) {
						this.setState({empty: true});
					}
				}
			}
		} else {
			this.setState({ loading: false, empty: true });
		}
	}

};
  
export default Consumer(search);
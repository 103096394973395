/**
 *  favorites.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Componente para Header Tabs
*/

import React, { Component, Fragment } from 'react';
import { Consumer } from '../../context';

class Tabs extends Component {
    
    render() {
        const user = this.props.user;
        const tipo = user.tipo ? user.tipo.toString() : '0';
        return(
            <div className="search-tap">
                {
                    tipo === "1" ?
                    <Fragment>
                        <div className={"column taps-column " + (this.props.tab === 'first' ? ' tap-active': '')}>
                            <button className="tap color-white" onClick={this.props.changeTab.bind(this, 'first')}>
                                { this.props.first }
                            </button>
                        </div>
                        <div className={"column taps-column " + (this.props.tab === 'second' ? ' tap-active': '')}>
                            <button className="tap color-white" onClick={this.props.changeTab.bind(this, 'second')}>
                                { this.props.second }
                            </button>
                        </div>
                    </Fragment>
                    :
                    <div className={"column taps-column " + (this.props.tab === 'first' ? ' tap-active': '')}>
                        <button className="tap color-white" onClick={this.props.changeTab.bind(this, 'first')}>
                            { this.props.first }
                        </button>
                    </div>
                }
            </div>
        );
    }

}

export default Consumer(Tabs);
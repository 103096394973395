/**
 *  interests.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio - Kath, Mariano
 *  @description: Página de selección de interés
*/

import React, { Component } from 'react';
import { HeaderBack ,HeaderWebInterests } from '../components/header/header';
import { Consumer } from '../context';
import { Link, withRouter } from 'react-router-dom';
import Request from '../core/httpClient';
import MediaQuery from 'react-responsive';
import BottomBar from '../components/bottom-bar/bottombar';
import WOW from 'wowjs';
import { Helmet } from 'react-helmet';

const req = new Request();

class Interests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categorias: [], intereses: [], user: {id: 0, tipo: 0}, back: ''
        };
    }

    render() {
        let back = '', tipo = '';
        if (this.state.back) { this.state.back === 1  ? back = '/ajustes/perfil' : back ='/ajustes/perfil/marca'; }
        if (this.state.user) { tipo = this.state.user.tipo.toString(); }
        return (
            <div className="interests column">
                <Helmet>
                    <title>WAALA - Intereses</title>
                    <meta property="og:title" content="WAALA - Intereses" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                    <header className="header-web-interest justify-center">
                        <div className="container">
                            <div className="header-search align-center">
                                <div className="row justify-center align-center">  
                                    <div className="column auto">
                                        <Link to = { tipo === "1" ? '/ajustes/perfil' : '/ajustes/perfil/marca' } > 
                                            <i className="fas fa-angle-left fa-1x color-white back-icon font-regular" id="back-interest"></i> 
                                        </Link>
                                    </div> 
                                    <div className="column">
                                        <h2 className="color-white font-regular">
                                            Actualiza tus intereses
                                        </h2>
                                    </div> 
                                    <div className="column">
                                        <div className="row justify-end">
                                            <div className="btn-container auto btn-splash justify-center">
                                                <button className="btn-web btn-border-white font-small text-center color-white weight-bold" id="btn-update" onClick = { this.updateInterest.bind(this) }>
                                                    {
                                                        this.state.loading ?
                                                        ( <i className="fas fa-spin fa-spinner color-white"></i> )
                                                        :
                                                        ( "ACTUALIZAR" )
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                        <div className="column justify-center align-center">
                            <div className="white-space-32"></div>
                            <div className="container">
                                <div className="interests-options">
                                    <div className="container-grid wrap">
                                    {
                                        this.state.categorias.map((cat, index) => 
                                            (
                                                <div className="photo responsive-img" key={index}>
                                                    <div className = { `tittle align-center justify-center ${ this.state.intereses.find(inter => inter.cat === cat.categoria) ? 'active-option': null }`} onClick = { this.handleClick.bind(this, cat.categoria) }>
                                                        <h4 className="color-white font-regular text-center">
                                                            { cat.categoria }
                                                        </h4>
                                                    </div>
                                                    <img  className="img-gallery" src = { cat.image } alt="Imagen de interes" title="Imagen de interes" />
                                                </div>
                                            )
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="white-space-32"></div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                    <HeaderBack back = { back } />
                    <div className="white-space-32"></div>
                    <div className="justify-center">
                        <div className="container column">
                            <div className="interests-container wrap">
                                {
                                    this.state.categorias.map((cat, index) => 
                                        (
                                            <div className="interests-element column auto justify-center align-center wow animated fadeIn" key={index}>
                                                <div className = { `circle column justify-center align-center ${ this.state.intereses.find(inter => inter.cat === cat.categoria) ? 'active': null }` }
                                                    onClick = { this.handleClick.bind(this, cat.categoria) }>
                                                    <div className="icon justify-center">
                                                        <i className = { cat.icon }></i>
                                                    </div>
                                                </div>
                                                <div className="white-space-8"></div>
                                                <p className="text-center">
                                                    { cat.categoria }
                                                </p>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                            <div className="white-space-64"></div>
                        </div>
                    </div>
                    <div className="white-space-64"></div>
                    <div className="btn-container justify-center" id="btn-top">
                        <button className="btn btn-interests btn-purple btn-square color-white font-regular text-center" onClick={this.updateInterest.bind(this)}>
                        {
                                this.state.loading ?
                                (
                                    <div className="justify-center">
                                        <i className="fas fa-spin fa-spinner color-white"></i>
                                    </div>
                                )
                                :
                                (
                                    this.state.message ? (
                                        <p className="text-center color-white font-regular">
                                            { this.state.message }
                                        </p>
                                    ) 
                                    :
                                    ("ACTUALIZAR" )
                                )
                            }
                        </button>
                    </div>
                    <BottomBar active="bottomHome" />
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        new WOW.WOW({ live: false }).init();
        this.load();
    }

    async load() {
		const user = await this.props.context.loadUser();
		if (user) { this.setState({user: user.id, back: user.id.tipo}); }
        const res = await req.get(`/intereses/${user.id.tipo.toString() === '1'? 'influencer': 'marca'}/${user.id.id}`);
        if (res.categorias) {
            this.setState({ categorias: res.categorias });
            this.state.intereses.find(inter => inter.cat === 'animales' ? 'active' : null);
        }
        if (res.selected) { this.setState({ intereses: res.selected }); }
    }

    handleClick(categoria) {
        let intereses = this.state.intereses;
        const idCat = intereses.findIndex(interes => interes.cat === categoria);
        if(idCat >= 0) {
            intereses = intereses.filter((interes, index) => index !== idCat);
        } else {
            intereses.push({cat: categoria});
        }
        this.setState({intereses});
    }

    async updateInterest() {
        this.setState({loading: true});
        if(this.state.intereses.length <= 0) {
            this.setState({ message: 'SELECCIONA POR LO MENOS 1 INTERÉS', loading: false });
            return;
        } else {
            const data = { intereses: JSON.stringify(this.state.intereses), id: this.state.user.id, tipo: this.state.user.tipo };
            const res = await req.post(`/intereses/update/${data.tipo.toString() === '1'? 'influencer': 'marca'}`, data);
            this.setState({ message: res.message });
            this.props.context.interestUpdated(true);
            if (res.updated) {
                setTimeout(()=>{
                    this.props.history.push(`/ajustes/perfil/${data.tipo.toString() === '1'? '': 'marca'}`)
                }, 1500)
            }
        }
        this.setState({ loading: false });
    }

}

export default withRouter(Consumer(Interests));

/**
 *  bloqued.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Mariano, Sergio
 *  @description: Card de bloqueo de usuarios
*/

import React, { Component, Fragment } from 'react';
import Request from '../../core/httpClient';

const req = new Request();

class BloquedCard extends Component {
    constructor(props) {
        super(props);
        this.state = { }
    }

    async quitBloq() {
        this.setState({loading: true});
        const data = { id: this.props.id, user: this.props.userData }
        const res = await req.post('/bloqueados/remove', data);
        if(res.removed) {
            this.props.load();
        } else {

        }
        this.setState({loading: true});
    }

    render() {
        let viewMode = '';
        if(this.props.view){
            viewMode = this.props.view;
        }
        return (
            <Fragment>
                 { viewMode === "desktop" ? null : <div className="white-space-8"></div> }
                <div className= { viewMode === "desktop" ? "column blocked-list" : "row blocked-list"}>
                    <div className="column blocked-column justify-start align-start">
                        <div className="image column align-center justify-center">
                            <div className="responsive-img justify-start align-center">
                                <img src = { this.props.imagen } alt="Imagen de perfil" title="Imagen de perfil" className=  "image-web image-cover" />
                            </div>
                        </div>
                    </div>
                    <div className="column justify-center align-start blocked-data">
                        <div className="row data">
                            <h4>
                                { this.props.descripcion }
                            </h4>
                            <button className="remove-bloqued" onClick = { this.quitBloq.bind(this) }>
                            {
                                this.state.loading? <i className="fas fa-spinner fa-spin weight-semi font-small auto"></i>
                                : <i className="fas fa-times weight-semi font-small auto"></i>
                            }
                            </button>
                        </div>
                        <div className="row justify-end">
                            <div className="column">
                                <h5 className =" color-purple">
                                    @{ this.props.usuario }
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                { viewMode === "desktop" ? null :  <hr/> }
               
            </Fragment>
        );
    }

}

export default BloquedCard;
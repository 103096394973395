/**
 *  chats.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath, Sergio
 *  @description: Página listado de chats
*/

import React, { Component } from 'react';
import Header from '../components/header/header';
import {HeaderWebHomeLoggedIn} from '../components/header/header';
import BottomBar from '../components/bottom-bar/bottombar';
import LeftBar from '../components/left-bar/left-bar';
import MediaQuery from 'react-responsive';
import WOW from 'wowjs';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { Consumer } from '../context';
import socket from '../core/socket';
import moment from 'moment-timezone';
import DeleteModal from '../components/modals/deletechat';


class Chats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {id: 0, tipo: 0},
            chats: [],
            empty: ''
        }
    }

    componentWillUnmount() {
        this.props.context.setPrevLocation(this.props.location);
        this.mounted = false;
    }

    async load() {
        this.setState({loading: true});
        const user = await this.props.context.loadUser();
        if(user && this.mounted) {
            this.setState({userData: user.id});
            socket.emit('loadChats', {
                user: user.id.id,
                tipo: user.id.tipo,
                loading: true
            });
        }
    }

    componentDidMount() {
        // console.log(this.props.match.params.usuario);
        this.mounted = true;
        new WOW.WOW({
            live: false
        }).init();
        this.load();
        socket.on('loadChats', (data) => {
            this.setState({loading: false});
            //console.log(data)
            if(data.chats) {
                //console.log(data.chats.length)
                if(data.chats.length <= 0) {
                    this.setState({ empty: true });
                    return;
                }
                const chats = data.chats.sort((a,b)=> {
                    if(a.date === b.date) {
                        return 0;
                    }
                    if(a.date < b.date) {
                        return 1;
                    }
                    if(a.date > b.date) {
                        return -1;
                    }
                    return 0;
                });
                let newMessages = false;
                data.chats.forEach(element => {
                    if(element.visto === 0) {
                        newMessages = true;
                    };
                });
                if(!newMessages) {
                    window.localStorage.setItem('wnm', '0;0');
                };
                this.setState({chats});
                //console.log(chats);
            } else {
                this.setState({ empty: true });
                window.localStorage.setItem('wnm', '0;0');
            }
        });
    
        socket.on('delChat', (data) => {
            // console.log(data);
            this.load();
        });

        socket.on('newMessage', (data) => {
            let chats = this.state.chats;
            const exists = chats.find(chat => chat.id === data.who.id);
            if(exists) {
                chats.forEach((chat, index) => {
                    if(chat.id === data.who.id) {
                        chats[index].visto = 0;
                        chats[index].date = data.who.date;
                    }
                });
                this.setState({chats});
            } else {
                window.location.reload();
            }
        });
    }

    deleteModal(usuario) {
        this.setState({delUser: usuario});
        let modal = document.getElementById('modal-delete-chat');
        if (modal.classList.contains('hidden')) {
            modal.classList.remove('fadeOut');
            modal.classList.add('animated', 'fadeIn');
            modal.classList.remove('hidden');
        }
    }

    handleWebRedirection(user) {
        window.localStorage.removeItem('waalapub');
        window.location = `/chat/${user}`;
    }

    deleteChat() {
        const data = {
            idDel: this.state.delUser,
            currentUser: this.state.userData
        }
        socket.emit('delChat', data)
        let modal = document.getElementById('modal-delete-chat');
        if(modal) {
            modal.classList.add('fadeOut','faster');
            setTimeout(() => {
                modal.classList.add('hidden');
            }, 500);
        }
    }

    filterChats(event) {
        // console.log(event.target.value);
        const person = event.target.value.toLowerCase();
        if(this.state.chats2) {
            let chats = this.state.chats2;
            chats = chats.filter(card => card.nombre.toLowerCase().indexOf(person) !== -1)
            this.setState({chats: chats})
        } else {
            let chats = this.state.chats;
            chats = chats.filter(card => card.nombre.toLowerCase().indexOf(person) !== -1)
            this.setState({chats: chats, chats2: this.state.chats})
        }
    }

    render() {
        let empty = '', tipo = '';
        if (this.state.empty) { empty = this.state.empty; };
        let origen = 'chats';
        if (this.props.origen) { origen = this.props.origen; }
        if (this.state.userData) { tipo = this.state.userData.tipo.toString(); }
        return (
            <div className="chats column justify-center align-center">
                <Helmet>
                    <title>WAALA - Chats</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Chats" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                <div className="view-web column">
               
                 { origen === 'chats' ?
                    <div className="column">
                    <HeaderWebHomeLoggedIn/>
                    <div className="row justify-center">
                        <LeftBar active = "menuChats"/>
                        <div className="column align-center">
                        <div className="white-space-32"></div>
                        <div className="row container">
                         <div className={`column chats-container chats-desk-list ${ empty ? 'full' : null }`}>
                            {
                                this.state.loading ?
                                (
                                    <div className="column loading-center">
                                        <div className="white-space-16"></div>
                                        <div className="justify-center">
                                            <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    empty ? 
                                    (
                                        <div className={`column ${ empty ? 'center-chat' : null }`}>
                                            <div className="white-space-32"></div>
                                            <div className="justify-center">
                                                <h3>NO HAY MENSAJES</h3>
                                            </div>
                                            <div className="white-space-16"></div>
                                            <p className="text-center">
                                                Pulsa <i className="far fa-comments color-pink"></i> para iniciar una conversación { tipo === "1" ? 'con la marca' : 'con influencers' }
                                            </p>
                                        </div>
                                    )
                                    :
                                    (
                                        this.state.chats.map((chat, index) => {
                                            const oDate = new Date(moment.tz(chat.date, 'America/Cancun').format());
                                            const fecha = moment(oDate).format('DD/MM hh:mm a');
                                            return(
                                                <React.Fragment key={index}>
                                                    <div className="row chat-list">
                                                        <i className="fas fa-times icon-delete" onClick={this.deleteModal.bind(this, chat.id)}></i>
                                                        <div className="column chat-column justify-start align-start" onClick={this.handleWebRedirection.bind(this,chat.usuario)}>
                                                            <div className="image column align-center justify-center">
                                                                <div className="responsive-img justify-start align-center">
                                                                    <img src={chat.imagen} alt="Imagen de perfil" title="Imagen de perfil" className="image-cover" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column justify-center align-start" onClick={this.handleWebRedirection.bind(this,chat.usuario)}>
                                                            <div className="row">
                                                                <h4>
                                                                    {chat.nombre}
                                                                </h4>
                                                            </div>
                                                            <div className="row justify-end">
                                                                <div className="column">
                                                                    <h5>
                                                                        {/*Ver mensajes*/}
                                                                    </h5>
                                                                </div>
                                                                <div className="column justify-center align-end">
                                                                    {
                                                                        chat.visto === 0? <i className="fas fa-circle color-purple"></i>: null
                                                                    }
                                                                    <h5 className="color-purple">
                                                                        { fecha }
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </React.Fragment>
                                            )
                                        })
                                    
                                    )
                            )}
                         </div>
                         <div className={`column pre-chat loading-center justify-center ${ empty ? 'hidden' : null }`}>
                            <div className="white-space-32"></div>
                              {empty ? 
                            (
                               null
                            ) : 
                            <div className="column">
                                <div className="justify-center">
                                    <h3>NO HAY MENSAJES</h3>
                                </div>
                                <div className="white-space-16"></div>
                                <p className="text-center">
                                Pulsa <i className="far fa-comments color-pink"></i> para iniciar una conversación { tipo === "1" ? 'con la marca' : 'con influencers' }
                                </p>
                            </div>
                              }
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    :
                    <div className="column chats-container">
                        <div className="white-space-16"></div>
                        <div className="chat-search align-center">
                            <i className="fas fa-search"></i>
                            <input className="input input-chats-desk" placeholder="Buscar" onChange={this.filterChats.bind(this)}></input>
                        </div>
                    {
                        this.state.loading ?
                        (
                            <div className="column loading-center">
                                <div className="white-space-16"></div>
                                <div className="justify-center">
                                    <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                </div>
                            </div>
                        )
                        :
                        (
                            empty ? 
                            (
                                <div className="column">
                                    <div className="white-space-32"></div>
                                    <div className="justify-center">
                                        <h3>NO HAY MENSAJES</h3>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <p className="text-center">
                                        Pulsa <i className="far fa-comments color-pink"></i> para iniciar una conversación { tipo === "1" ? 'con la marca' : 'con influencers' }
                                    </p>
                                </div>
                            )
                            :
                            (
                                this.state.chats.map((chat, index) => {
                                    const oDate = new Date(moment.tz(chat.date, 'America/Cancun').format());
                                    const fecha = moment(oDate).format('DD/MM hh:mm a');
                                    return(
                                        <React.Fragment key={index}>
                                            <div className={`row chat-list ${this.props.match.params.usuario === chat.usuario ? 'selected-chat' : '' }`}>
                                                <i className="fas fa-times icon-delete" onClick={this.deleteModal.bind(this, chat.id)}></i>
                                                <div className="column chat-column chat-image justify-start align-start" onClick={this.handleWebRedirection.bind(this,chat.usuario)}>
                                                    <div className="image column align-center justify-center">
                                                        <div className="responsive-img justify-start align-center">
                                                            <img src={chat.imagen} alt="Imagen de perfil" title="Imagen de perfil" className="image-cover" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column chat-details justify-center align-start" onClick={this.handleWebRedirection.bind(this,chat.usuario)}>
                                                    <div className="row">
                                                        <h4>
                                                            {chat.nombre}
                                                        </h4>
                                                    </div>
                                                    <div className="row justify-end">
                                                        <div className="column">
                                                            <h5>
                                                                {/*Ver mensajes*/}
                                                            </h5>
                                                        </div>
                                                        <div className="column justify-center align-end">
                                                            {
                                                                chat.visto === 0? <i className="fas fa-circle color-purple"></i>: null
                                                            }
                                                            <h5 className="color-purple">
                                                                { fecha }
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </React.Fragment>
                                    )
                                })
                            
                            )
                    )}
                 </div>  
               
                 }
                 </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                <Header tipo = {tipo} />
                <div className="container justify-center">
                    <div className="column chats-container">
                    <div className="white-space-16"></div>
                    <div className="chat-search align-center">
                        <i className="fas fa-search"></i>
                        <input className="input input-chats-movile" placeholder="Buscar" onChange={this.filterChats.bind(this)}></input>
                    </div>
                    <div className="white-space-8"></div>
                        {
                            this.state.loading ?
                            (
                                <div className="column loading-center">
                                    <div className="white-space-16"></div>
                                    <div className="justify-center">
                                        <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                    </div>
                                </div>
                            )
                            :
                            (
                                empty ? 
                                (
                                    <div className="column">
                                        <div className="white-space-32"></div>
                                        <div className="justify-center">
                                            <h3>NO HAY MENSAJES</h3>
                                        </div>
                                        <div className="white-space-16"></div>
                                        <p className="text-center">
                                            Pulsa <i className="far fa-comments color-pink"></i> para iniciar una conversación { tipo === "1" ? 'con la marca' : 'con influencers' }
                                        </p>
                                    </div>
                                )
                                :
                                (
                                    this.state.chats.map((chat, index) => {
                                        const oDate = new Date(moment.tz(chat.date, 'America/Cancun').format());
                                        const fecha = moment(oDate).format('DD/MM hh:mm a');
                                        return(
                                            <React.Fragment key={index}>
                                                <div className="row chat-list">
                                                    <i className="fas fa-times icon-delete" onClick={this.deleteModal.bind(this, chat.id)}></i>
                                                    <div className="column chat-column justify-start align-start" onClick={() => this.props.history.push(`/chat/${chat.usuario}`)}>
                                                        <div className="image column align-center justify-center">
                                                            <div className="responsive-img justify-start align-center">
                                                                <img src={chat.imagen} alt="Imagen de perfil" title="Imagen de perfil" className="image-cover" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="column justify-center align-start" onClick={() => this.props.history.push(`/chat/${chat.usuario}`)}>
                                                        <div className="row">
                                                            <h4>
                                                                { chat.nombre }
                                                            </h4>
                                                        </div>
                                                        <div className="row justify-end">
                                                            <div className="column">
                                                                <h5>
                                                                    {/*Ver mensajes*/}
                                                                </h5>
                                                            </div>
                                                            <div className="column justify-center align-end">
                                                                {
                                                                    chat.visto === 0? <i className="fas fa-circle color-purple"></i>: null
                                                                }
                                                                <h5 className="color-purple">
                                                                    { fecha }
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </React.Fragment>
                                        )
                                    })
                                )
                        )}
                        <div className="white-space-16"></div>
                    </div>
                </div>
                <BottomBar active = "bottomChats" />
                </MediaQuery>
                <DeleteModal deleteChat = { this.deleteChat.bind(this) }/>
            </div>
        );
    }

}

export default withRouter(Consumer(Chats));
/**
 *  Contact.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio, Mariano
 *  @description: Modal de contacto
*/

import React, { Component, Fragment } from 'react';
import socket from '../../core/socket';

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: ''
        }
    }

    hanldeSubmit(event) {
        event.preventDefault();
        const form = event.target;
        console.log(form);
        if(form) {
            const data = {
                contacto: form.email? form.email.value:'',
                user: this.props.user? this.props.user:null,
                asunto: form.subject.value,
                mensaje: form.message.value
            }
            socket.emit('contact_send', data);
            this.setState({loading: true});
        }
    }

    render() {
        return (
            <div className="modal justify-center hidden" id="modal-contact">
                <div className="container modal-content align-center column" >
                    <div className="white-space-24"></div>
                    <form className="column align-center justify-center" onSubmit={this.hanldeSubmit.bind(this)}> 
                        <div className="column justify-center align-center">
                            {
                                this.props.single ? 
                                (
                                    <Fragment>
                                        <p className="weight-semi">
                                            Correo: 
                                        </p>
                                        <div className="white-space-8"></div>
                                        <div className="input-container align-center justify-center">
                                            <input type="email" name="email" className="input input-login" placeholder="Correo" minLength="6" maxLength="64" required />
                                        </div>
                                        <div className="white-space-8"></div>
                                    </Fragment>
                                ) : null
                            }
                            <p className="weight-semi">
                                Asunto: 
                            </p>
                            <div className="white-space-8"></div>
                            <div className="input-container align-center justify-center">
                                <input type="text" name="subject" id="subject" className="input input-login" placeholder="Asunto" minLength="6" maxLength="64" required />
                            </div>
                            <div className="white-space-8"></div>
                            <p className="weight-semi">
                                Mensaje: 
                            </p>
                            <div className="white-space-8"></div>
                            <div className="input-container align-center justify-center">                     
                                <textarea type="text" name="message" id="message" cols="10" rows="10" placeholder="¿En qué podemos ayudarte?" className="input input-login" minLength="8" required></textarea>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="btn-container justify-center">
                                <button type="submit" className="btn-modal-login btn-pink font-small text-center color-white weight-bold">
                                {
                                    this.state.loading ?
                                    (<i className="fas fa-spin fa-spinner color-white"></i>) :
                                    ("ENVIAR")
                                }
                                </button>
                            </div>
                            <div className="white-space-8"></div>
                            <div className="btn-container justify-center">
                                <button type="button" className="btn-modal-login btn-purple font-small text-center color-white weight-bold" onClick = { this.handleClose.bind(this) }>
                                    CANCELAR
                                </button>
                            </div>
                            <div className="white-space-8"></div>
                            <p className="text-center font-mini">
                                {
                                    this.state.message ? 
                                    <strong>{ this.state.message }</strong> :
                                    <strong className="color-white">{ "" }</strong>
                                }
                            </p>
                        </div>
                    </form>
                    <div className="white-space-16"></div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        socket.on('contact_sended', (data) => {
            console.log(data);
            this.setState({message: data.message, loading: false});
            if(data.sended) {
                document.getElementById('subject').value = '';
                document.getElementById('message').value = '';
            }
        })
    }

    handleClose() {
        const modal = document.getElementById('modal-contact');
        if (modal) {
            modal.classList.remove('fadeIn');
            modal.classList.add('animated', 'fadeOut');
            setTimeout(() => {
                modal.classList.add('hidden');
            }, 600);
        }
    }

}

export default Contact;
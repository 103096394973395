/**
 *  publications.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Componente favoritos publicaciones
*/

import React, { Component } from 'react';
import HolderImage from '../../img/user.png';
import { Link, withRouter } from 'react-router-dom';
import Request from '../../core/httpClient';
import WOW from 'wowjs';

const req = new Request();

class FavPublications extends Component {
    constructor(props) {
        super(props);
        this.state={
            favorito: this.props.favorito || false
        }
    }

    async addFav() {
        this.setState({loadingFav: true});
        const data = {
            id_influencer: this.props.id_influencer,
            id_marca: this.props.id,
            tipo: this.props.tipo,
			favorito: !this.state.favorito,
			tipo_pub: this.props.tipo_pub
        }
        const res = await req.post('/favoritos/put', data);
        if (res.error) {
            console.log(res.message);
        } else {
            this.setState({favorito: !this.state.favorito});
        }
        this.setState({loadingFav: false});
    }

    handleRedirect(idPublication) {
		this.props.history.push(`/publicacion/${idPublication}`);
	}

    render() {
        let imagen = '';		
        let idPublication = '';
        if(this.props.imagen) {
            imagen = this.props.imagen;
            
        } else {
            imagen = HolderImage;
        }
        if (this.props.id) { idPublication = this.props.id; }

        return (
            <div className="brand-cards publication-card column wow animated fadeInUp faster">
                <div className="image" onClick = {this.handleRedirect.bind(this, idPublication)}>
					<img src = { imagen } alt="Imagen del influencer" title="Imagen del influencer" className="cover-img"/>
					<div className="title column justify-center align-center">
						<div className="white-space-64"></div>
						<div className="data justify-center">
							<div className="column justify-end">
								<h4 className="name color-white text-center">
									{ this.props.nombre }
								</h4>
								<p className="color-white text-center">
									{ this.props.ciudad } &nbsp;
								</p>
							</div>
							<div className="column justify-end">
								<h4 className="name color-white text-center">
									{ this.props.pago } € &nbsp; 
								</h4>
								<p className="color-white text-center">
									Pago
								</p>
							</div>
						</div>
						<div className="white-space-32"></div>
					</div>
				</div>
                <div className="btn-container">
                    <button className="btn btn-square color-white text-center font-regular" onClick = { this.addFav.bind(this) }>
                        {
                            this.state.loadingFav ? 
                            (
                                (<i className="fas fa-spin fa-spinner color-pink"></i>)
                            ) :
                            (
                                this.state.favorito ? (<i className="fas fa-heart wow animated fadeIn color-pink"></i>) :
                                (<i className="far fa-heart wow animated fadeIn color-pink"></i>)
                            )                            
						}
                    </button>
                    <div className="divider auto"></div>
                    <a onClick={this.selectPublication.bind(this)} className="btn btn-square btn-pink color-white text-center font-regular">
                        <i className="far fa-comments color-purple"></i>
                    </a>
                </div>
            </div>
        )
    }

    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();
    }

    selectPublication() {
		window.localStorage.setItem('waalapub', `${this.props.id};${this.props.titulo}`);
		this.props.history.push(`/chat/${this.props.usuario}`);
	}
};

export default withRouter(FavPublications);
/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath, Sergio
 *  @description: Editar Contraseña
*/

import React, { Component } from 'react';
import BottomBar from '../components/bottom-bar/bottombar';
import { Link } from 'react-router-dom';
import { HeaderPassword, HeaderWebHomeLoggedIn } from '../components/header/header';
import { Consumer } from "../context";
import Request from "../core/httpClient";
import LeftBar from '../components/left-bar/left-bar';
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';
import ModalAlert from '../components/modals/profileAlert';
import LeftBarSetting from '../components/left-bar/left-settings-bar';

class Password extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: { id: 0, tipo: 0}, userId: 0,  
            newPassword: '',  oldPassword: '',  tipo: ''
        };
    }

    render() {
        return(
            <div className="settings column">
                <Helmet>
                    <title>WAALA - Cambiar contraseña</title>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                <div className="view-web column">
                <HeaderWebHomeLoggedIn/>
                <div className="row">
                    <LeftBar active = "menuSettings"/>
                    <div className="column align-center">
                    <div className="white-space-32"></div>
                       <div className="row-responsive container">
                            <LeftBarSetting link="/ajustes/perfil" option = "2"/>
                            <div className="column">
                                <form className="column" id="desktop-form"> 
                                    <div className="row">
                                        <div className="column right-main content-forms align-center">
                                            <h4>
                                                Cambiar contraseña
                                            </h4>
                                            <div className="white-space-8"></div>
                                            <input type="password" name="password" placeholder="Contraseña actual" className="input input-password" minLength="8" required />
                                            <div className="white-space-8"></div>
                                            <input type="password" name="passwordNew" placeholder="Contraseña nueva" className="input input-password" minLength="8" required />
                                            <div className="white-space-8"></div>
                                            <input type="password" name="passwordConfirm" placeholder="Repetir contraseña nueva" minLength="8" className="input input-password" required />
                                            <div className="white-space-8"></div>
                                        </div>
                                    </div>
                                <div className="white-space-16"></div>
                                {
                                    this.state.message ?
                                    (
                                        <div className="column">
                                            {
                                                this.state.loading ? 
                                                (
                                                    <div className="justify-center">
                                                        <i className="fas fa-spin fa-spinner color-pink"></i>
                                                    </div>
                                                ) : ( null )
                                            }
                                            <p className="text-center">
                                                { this.state.message }
                                            </p>
                                        </div>
                                    ) :
                                    ( null )
                                }
                                    <div className="btn-container auto btn-splash justify-center">
                                        <button type="button" className="btn-web-forms btn-purple font-small  text-center color-white weight-bold" onClick = { this.updatePassword.bind(this, true) }>
                                            {
                                            this.state.loading ?
                                                (<i className="fas fa-spin fa-spinner font-small color-white i-right"></i>) :
                                                ("ACTUALIZAR CONTRASEÑA")
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                       </div>
                    </div>
                </div>
                </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                <HeaderPassword/>
                <Link to="/ajustes">
                    <i className="fas fa-times font-small color-white i-left i-left-center"></i> 
                </Link>
                <form className="password justify-center" id="mobile-form">
                    <div className="container column">  
                        <div className="white-space-16"></div>
                        <div className="column">
                            <div className="white-space-8"></div>
                            <p className="weight-bold">
                                Cambiar contraseña
                            </p>
                            <div className="white-space-16"></div>
                            <div className="column">
                                <input type="password" name="password" placeholder="Contraseña actual" className="input input-password" minLength="8" required />
                                <div className="white-space-8"></div>
                                <input type="password" name="passwordNew" placeholder="Contraseña nueva" className="input input-password" minLength="8" required />
                                <div className="white-space-8"></div>
                                <input type="password" name="passwordConfirm" placeholder="Repetir contraseña nueva" minLength="8" className="input input-password" required />  
                            </div>
                            {
                                this.state.message ?
                                (
                                    <div className="column">
                                        <div className="white-space-16"></div>
                                        {
                                            this.state.loading ? 
                                            (
                                                <div className="justify-center">
                                                    <i className="fas fa-spin fa-spinner color-pink"></i>
                                                </div>
                                            ) : ( null )
                                        }
                                        <p className="text-center">
                                            { this.state.message }
                                        </p>
                                    </div>
                                ) :
                                ( null )
                            }
                            <div className="white-space-16"></div>
                            <button type="button" onClick = { this.updatePassword.bind(this, false) }>
                                {
                                    this.state.loading ?
                                        (<i className="fas fa-spin fa-spinner font-small color-white i-right i-right-center"></i>) :
                                        (<i className="fas fa-check font-small color-white i-right i-right-center"></i>)
                                }
                            </button>
                        </div>
                    </div>
                </form>               
                <BottomBar />
                </MediaQuery>
                <ModalAlert title="CONTRASEÑA ACTUALIZADA" description="" />
            </div>
        );
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if(user) {
            this.setState({userData: user.id})
        }
    }

    async updatePassword(desktop) {
        let form = '';
        if (desktop) {
            form = document.getElementById('desktop-form');
        } else {
            form = document.getElementById('mobile-form');
        }
        this.setState({ loading: true });
        const userId = this.state.userData;
        if(form.passwordNew.value === form.passwordConfirm.value) {
            const data = {
                userId: userId.id, newPassword: form.passwordNew.value, oldPassword: form.password.value, tipo: userId.tipo
            };
            const request = new Request();
            const userDataUpdate = await request.post("/users/password", data);
            if (userDataUpdate && userDataUpdate.update && !userDataUpdate.error) {
                this.setState({ message: userDataUpdate.message, update: userDataUpdate.udpate });
                this.props.context.logOut();
                let modal = document.getElementById('modal-profileAlert');
                if (modal.classList.contains('hidden')) {
                    modal.classList.remove('fadeOut');
                    modal.classList.add('animated', 'fadeIn');
                    modal.classList.remove('hidden');
                }
                if (desktop) {
                    setTimeout(() => {
                        window.location = '/';
                    }, 2000);
                } else {
                    setTimeout(() => {
                        window.location = '/login';
                    }, 2000);
                }
            } else {
                this.setState({ message: userDataUpdate.message, loading: false });
            }
            this.setState({ loading: false });
        } else {
            this.setState({ message: "LAS CONTRASEÑAS NO COINCIDEN", loading: false });
        }
    }

}

export default Consumer(Password);
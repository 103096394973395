/**
 *  publications.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejador principal del estado global las publicaciones
 */

export function setDeleteBrandPublication(data) {
    this.setState({ publicationBrandToDelete: data });
}

export function setDeleteInfluencerPublication(data) {
    this.setState({ publicationInfluencerToDelete: data });
}

export function setShowImageInfluencerPublication(data) {
    this.setState({ imageInfluencerPublicationToShow: data });
}

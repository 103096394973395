/**
 *  publication-edit.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio, Mariano
 *  @description: Página de Añadir publicación
*/

import React, { Component } from 'react';
import Header from '../components/header/header';
import BottomBar from '../components/bottom-bar/bottombar';
import HolderImage from '../img/upload.png';
import { Consumer } from '../context';
import Request from "../core/httpClient";
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ModalAlert from '../components/modals/profileAlert';
const req = new Request();

class PublicationAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {id: 0, tipo: 0},
            disabled: false
        }
    }

    changeImage() { document.getElementById("img-src").click(); }

    handleChange(event) {
        let resizedImage = "";
        const src = event.target;
        let fr = new FileReader();
        fr.onload = function(e) {
            const image = new Image();
            image.onload = function() {
                const canvas = document.createElement("canvas");
                const max_size = 600;
                let width = image.width;
                let height = image.height;
                if (width > height) {
                    if (width > max_size) {
                        height *= max_size / width;
                        width = max_size;
                    }
                } else {
                    if (height > max_size) {
                        width *= max_size / height;
                        height = max_size;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext("2d").drawImage(image, 0, 0, width, height);
                resizedImage = canvas.toDataURL("image/png");
                document.getElementById("img-preview").src = resizedImage;
                this.setState({ imagen: resizedImage, message: '' });
            }.bind(this);
            image.src = e.target.result;
        }.bind(this);
        if (src.files.length > 0) {
            fr.readAsDataURL(src.files[0]);
        }
    }

    imageError(event) {
        event.target.src =
        "https://via.placeholder.com/600?text=Click+para.actualizar+imagen";
    }

    async handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        if(form.image.src.includes('data:image')) {
            this.setState({loading: true, disabled: true, message: ''});
            const storageRef = window.firebase.storage().ref();
            let dateTime = new Date();
            let fileName = dateTime.getFullYear() + "-" + (dateTime.getMonth() + 1) + "-" + dateTime.getDate() + "-" + dateTime.getHours() + "/" + dateTime.getMinutes() + ":" + dateTime.getSeconds() + ":" + dateTime.getMilliseconds();
            const ref = storageRef.child(`images/${fileName}`);
            const imageURL = await ref.putString(form.image.src, 'data_url').then(async snapshot => {
                const url = await snapshot.ref.getDownloadURL().then(downloadURL => {
                       return downloadURL;
                    }
                );
                return url
            });
            const data = {
                imageURL,
                id_user: this.state.userData.id,
                tipo: this.state.userData.tipo
            }
            if(this.state.userData.tipo.toString() === "2") {
                if(!form.price.value) {
                    this.setState({loading: false, disabled: false, message: 'Indica una cantidad válida.'});
                    return
                }
                if(!form.description.value) {
                    this.setState({loading: false, disabled: false, message: 'Proporciona una descripción.'});
                    return
                }
                Object.assign(data, {
                    precio: form.price.value, envio: form.shipping.value, descripcion: form.description.value, 
                    condiciones: form.conditions.value, titulo: form.title.value
                });
            }
            const publication = await req.post("/publications/add", data);
            this.setState({message: publication.message});
            if(publication.upload) {
                let modal = document.getElementById('modal-profileAlert');
                if (modal.classList.contains('hidden')) {
                    modal.classList.remove('fadeOut');
                    modal.classList.add('animated', 'fadeIn');
                    modal.classList.remove('hidden');
                }
                setTimeout(() => {
                    if(this.state.userData.tipo.toString() === "2")
                    this.props.history.push('/perfil/marca');
                else
                    this.props.history.push('/perfil/influencer');
                }, 2000);
            }
        } else {
            this.setState({message: 'Por favor selecciona una imagen valida'});
            return;
        }
        this.setState({loading: false, disabled: false});
    }
    
    render() {
        let tipo = "0";
        if(this.state.userData.tipo) {
            tipo = this.state.userData.tipo.toString();
        }
        return(
            <div className="publication publication-add column">
                <Helmet>
                    <title>WAALA - Añadir publicación</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Añadir publicación" />
                </Helmet>
                <Header context = { this.props.context } showBack = { true } tipo = { tipo }/>
                <div className="justify-center publication-grow">
                    <form className="container-publication container column" id="form-add" onSubmit = { this.handleSubmit.bind(this) }>
                        <div className="image">
                            <img src = { HolderImage } name="image" alt="Imagen de publicación" title="Imagen de publicación" className="cover-img" id="img-preview" onClick = { this.changeImage.bind(this) }  />
                            <input type="file" name="img-src" id="img-src" className="hidden" onChange = { this.handleChange.bind(this) } />
                        </div>
                        <div className="details column">
                            <div className="white-space-8"></div>
                            {
                            tipo === "2" ?
                            <div className="column">
                                <div className="title column">
                                    <div className="row">
                                        <div className="icon auto">
                                            <i className="fas fa-pencil-alt color-pink"></i>
                                        </div>
                                        <p className="weight-semi">
                                            Título
                                        </p>
                                    </div>
                                    <input type="text" name="title" className="input input-edit" maxLength = "60" minLength = "8" required placeholder="Título de la publicación" />
                                </div>
                                <div className="white-space-8"></div>
                                <div className="row">
                                <div className="price price-edit column">
                                    <div className="row">
                                        <div className="icon auto">
                                            <i className="fas fa-dollar-sign color-pink"></i>
                                        </div>
                                        <div className="info column">
                                            <p className="weight-semi">
                                                Pago
                                            </p>
                                        </div>
                                    </div>                                    
                                    <input type="number" max="999.90" name="price" className="input input-edit" id="price-edit" placeholder="€" required />
                                </div>
                                <div className="shipping column">
                                    <div className="row">
                                        <div className="icon auto">
                                            <i className="fab fa-telegram-plane color-pink"></i>
                                        </div>
                                        <p className="weight-semi">
                                            ¿Envías el producto?
                                        </p>
                                    </div>
                                    <div className="info auto column">
                                        <select name="shipping" className="input input-edit" id="select">
                                            <option value="1">
                                                Con envío
                                            </option>
                                            <option value="0">
                                                Sin envío
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div> 
                            </div>
                            : 
                            (
                                <div className="column">
                                    <div className="white-space-24"></div>
                                    <div className="row justify-center align-center">
                                    <i className="fas fa-info-circle font-regular icon-info"></i>
                                    <h4 className="text-center">
                                        Cargar nueva imagen
                                    </h4>
                                    </div>                                    
                                    <div className="white-space-8"></div>
                                    <div className="justify-center">
                                        <div className="container">
                                            <p className="text-justify">                                    
                                                Las imágenes publicadas aparecerán en tu perfil de usuario. Añade buenas imágenes para causar una mejor impresión a las marcas. Podrás eliminarlas en cualquier momento.
                                            </p>
                                        </div>
                                    </div>                                  
                                </div>
                            )
                            }
                                <div className="white-space-16"></div>
                            {
                            tipo === "2"?
                            <div className="column">
                                <div className="row">
                                    <div className="description column">
                                        <div className="row">
                                            <div className="icon auto">
                                                <i className="fas fa-info color-pink"></i>
                                            </div>
                                            <p className="weight-semi">
                                                Descripción
                                            </p>
                                        </div>
                                        <textarea name="description" id="textarea" minLength="24" maxLength="300" cols="30" rows="10" className="input description-detail" placeholder="Descripción detalla (300 caracteres máximo)" required></textarea>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="row">
                                    <div className="description column">
                                        <div className="row">
                                            <div className="icon auto">
                                                <i className="fas fa-exclamation color-pink"></i>
                                            </div>
                                            <p className="weight-semi">
                                                Condiciones
                                            </p>
                                        </div>
                                        <textarea name="conditions" id="textarea" maxLength="200" cols="30" rows="6" className="input description-detail" placeholder="Condiciones detalladas, opcional (200 caracteres máximo)"></textarea>
                                    </div>
                                </div>
                            </div>
                            : null
                            }
                            <div className="white-space-16"></div>
                            <p className="text-center">
                                { this.state.message }
                            </p>
                            <div className="white-space-16"></div>
                            <div className="btn-container btn-add align-end" id="btn-save to-bottom">
                                <button type="submit" disabled = { this.state.disabled } className="btn btn-purple btn-square weight-semi color-white">
                                    {
                                        this.state.loading ? 
                                            (<i className="fas fa-spin fa-spinner color-white"></i>) : 
                                            ( "PUBLICAR" )
                                    }
                                </button>
                            </div>
                        </div>                        
                    </form>                    
                </div>
                <div className="white-space-64"></div>
                <BottomBar active = "bottomProfile" />
                <ModalAlert title="" description="PUBLICACIÓN AÑADIDA A TU PERFIL" />
            </div>
        );
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({userData: user.id})
        }
    }

}

export default withRouter(Consumer(PublicationAdd));
/**
 *  profile-influencer.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio, kath
 *  @description: Perfil publico del influencer
*/

import React, { Component } from 'react';
import Header, { HeaderWebHomeLoggedIn } from '../components/header/header';
import BottomBar from '../components/bottom-bar/bottombar';
import { Consumer } from "../context";
import { Link } from 'react-router-dom';
import Request from "../core/httpClient";
import HolderImage from '../img/user.png';
import LoaderImage from '../img/loader.gif';
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import WOW from 'wowjs';
import ImageLoader from 'react-loading-image';
import { Helmet } from 'react-helmet';
import ModalPublicationAdd from '../components/modals/publicationsAdd'; 
import ModalDeleteInfluencerPublication from '../components/modals/deleteInfluencerPublication';
import ModalShowImage from '../components/modals/showImage';

const request = new Request();

class ProfileInfluencer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {id: 0, tipo: 0}, influencer: ''
        }
    }    

    componentWillUnmount() {
        this.props.context.setPrevLocation(this.props.location);
    }

    handleWebAdd() {
        let modalAdd = document.getElementById('modal-Add');
        modalAdd.classList.remove('fadeOut');
        modalAdd.classList.add('animated', 'fadeIn','faster');
        modalAdd.classList.remove('hidden');
    }

    handleWebDelete() {
        let modalNewsLetter = document.getElementById('modal-delate');  
        modalNewsLetter.classList.remove('fadeOut');
        modalNewsLetter.classList.add('animated', 'fadeIn','faster');
        modalNewsLetter.classList.remove('hidden');  
    }    

    render() {
        let influencer = '', imagen = '', images = '', empty = '', interests = [];
        const user = this.state.userData;
        const tipo = user.tipo ? user.tipo.toString() : '0';

        if (this.state.influencer) {
            influencer = this.state.influencer;
            if (influencer.foto_perfil_url) { imagen = influencer.foto_perfil_url; } else { imagen = HolderImage; }
            if (influencer.intereses) { interests = JSON.parse(influencer.intereses); }
        }
        if (this.state.images) { images = this.state.images; }
        if (this.state.empty) { empty = this.state.empty; }

        return(
            <div className="profile-influencer column">
                <Helmet>
                    <title>WAALA - Perfil</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Perfil" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <HeaderWebHomeLoggedIn />
                        <div className="row">
                            <LeftBar active = "menuProfile" />
                            <div className="column">
                                <div className="row justify-center">
                                    <div className="container column align-center justify-center">
                                        <div className="white-space-32"></div>
                                        <div className="row header-profile">
                                            <div className="column profile-column justify-center align-center">
                                                <div className="responsive-img justify-center align-center">
                                                    <ImageLoader src = { imagen } alt="Imagen del influencer" title="Imagen del influencer" className="profile-image"
                                                    loading = { () => <img className="profile-image loader-img" src = { LoaderImage } /> } />
                                                </div>
                                            </div>
                                            <div className="column justify-start align-center">
                                                <div className="white-space-24"></div>
                                                <div className="row">
                                                    <div className="column">
                                                        <h2 className="weight-semi">
                                                            { influencer.nombre }
                                                        </h2>
														<div className="row">
															<p className="text-start text-space">
																@{ influencer.usuario }
															</p>
														</div> 
                                                    </div>
                                                    <div className="auto align-center">
                                                        <Link to="/ajustes/perfil" className="setting-icon">
                                                            <i className="fas fa-cog icon-config" ></i>
                                                        </Link>            
                                                    </div>
                                                </div>
                                                <div className="white-space-24"></div>
                                                <div className="row">
                                                    <div className="column column-located">
                                                        <h4 className="font-small">Ciudad</h4>
                                                        <p className="text-start">
                                                            { influencer.ciudad }
                                                        </p>
                                                    </div>
                                                    <div className="column column-followers">
                                                        <h4 className="font-small text-center">Seguidores</h4>
                                                        <p className="text-center">
                                                            { influencer.seguidores }
                                                        </p>
                                                    </div>
                                                    <div className="column column-price">
                                                        <h4 className="font-small text-center">Tarifa de publicación</h4>
                                                        <p className="text-center">
                                                            € { influencer.tarifa }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="skills row wrap">
                                        {
                                            interests ?
                                            (
                                                interests.map((interest, key) => (
                                                    <div className="interes" key = { key } >
                                                        <p className="weight-semi text-center color-white font-mini wow animated fadeIn">
                                                            { interest.cat }
                                                        </p>
                                                    </div>
                                                    )
                                                )
                                            )
                                            :
                                            (
                                                <div className="column loading-center">
                                                    <div className="white-space-64"></div>
                                                    <div className="justify-center">
                                                        <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="gallery-profile">
                                            <div className = { `wrap ${empty ? null : 'grid-container'}` }>
                                            {
                                                empty ?
                                                (
                                                    <div className="column message-center">
                                                        <div className="white-space-32"></div>
                                                        <i className="far fa-images text-center color-pink"></i>
                                                        <div className="white-space-8"></div>
                                                        <div className="empty justify-center align-center">
                                                            <p className="text-center weight-semi font-regular">
                                                                Añade imágenes tuyas para causar una buena impresión a las marcas y aumentar tus posibilidades de colaboración
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) :
                                                (
                                                    images ? (
                                                        images.map((image, key) => (
                                                            <div className="wow animated fadeIn photo responsive-img" key = { key }>
                                                                <div className="img-gallery" onClick = { this.handleShowImage.bind(this, image.url) }>
                                                                    <ImageLoader src = { image.url } alt="Imagen del influencer" title="Imagen del influencer" className="img-gallery" loading = { () => <img className="cover-img loader-img" src={LoaderImage} /> }/>
                                                                </div>
                                                                <button id="delete" onClick={this.handleDelete.bind(this, image.id_foto, this.state.userData.id ) }>
                                                                    <i className="fas fa-times"></i>
                                                                </button>
                                                            </div>
                                                            ))
                                                    ) :
                                                    (
                                                        <div className="column loading-center">
                                                            <div className="white-space-64"></div>
                                                            <div className="justify-center">
                                                                <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            }
                                            </div>
                                        </div>
                                        <div className="white-space-32"></div>
                                        <ModalDeleteInfluencerPublication idImage = { this.props.context.publicationInfluencerToDelete.idImage }  idInfluencer = { this.props.context.publicationInfluencerToDelete.idInfluencer } />
                                        <ModalPublicationAdd/>
                                        <ModalShowImage imageUrl = {this.props.context.imageInfluencerPublicationToShow.imageUrl}></ModalShowImage>  
                                    </div>
                                </div>                    
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                    <Header back = '/inicio' tipo = { tipo } showBack = { false }  showSettings = { true } />
                    <div className="influencer-cards column">
                        <div className="image">
                            <ImageLoader src = { imagen } alt="Imagen del influencer" title="Imagen del influencer" className="cover-img"
                            loading={() => <img className="cover-img loader-img" src={LoaderImage} />} />
                            <div className="title column justify-center align-center">
                                <div className="data justify-center">
                                    <div className="container">
                                        <div className="column">
                                            
                                            <h4 className="color-white text-center">
                                                { influencer.nombre }
                                            </h4>
                                            <div className="white-space-16"></div>
                                            <div className="row justify-center">
                                                <p className="color-white text-center text-space">
                                                    @{ influencer.usuario  }
                                                </p>
                                                <p className="color-white">
                                                    { influencer.ciudad }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom justify-center">
                                <div className="justify-between">
                                    <div className="column ">
                                        <h3 className="color-white text-center">
                                            € { influencer.tarifa }
                                        </h3>
                                        <p className="color-white font-tiny text-center">
                                            Tarifa 
                                        </p>
                                    </div>
                                    <div className="column ">
                                        <h3 className="color-white text-center">
                                            { influencer.seguidores }
                                        </h3>
                                        <p className="color-white text-center font-tiny">
                                            Followers
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tabs-container">
                            <button className="btn btn-square btn-pink text-center font-regular" onClick = { this.handleGallery.bind(this) } >
                                <i className="far fa-images color-pink" id="gallery-tab"></i>
                            </button>
                            <button className="btn btn-square btn-pink text-center font-regular" onClick = { this.handleInterest.bind(this) }>
                                <i className="fas fa-hashtag color-gray" id="interest-tab"></i>
                            </button>
                        </div>
                    </div>
                    <div className="gallery" id="gallery">
                        <div className="justify-start wrap">
                            {
                                empty ? 
                                (
                                    <div className="empty justify-center align-center">
                                        <p className="text-center">
                                            Añade imágenes tuyas para causar una buena impresión a las marcas y aumentar tus posibilidades de colaboración
                                        </p>
                                    </div>
                                ) :
                                (
                                    images ? (
                                        images.map((image, key) => (
                                            <div className="photo owner-photo wow animated fadeIn responsive-img" key = { key } >
                                                <div onClick={this.handleShowImage.bind(this, image.url) }>
                                                    <ImageLoader src={image.url} alt="Imagen del influencer" title="Imagen del influencer" className="cover-img"
                                                    loading={() => <img className="cover-img loader-img" src={LoaderImage} />} />
                                                </div>
                                                <button id="delete-photo" onClick={this.handleDelete.bind(this, image.id_foto, this.state.userData.id ) }>
                                                    <i className="fas fa-times fa-2x color-red"></i>
                                                </button>
                                            </div>
                                            ))
                                        ) :
                                        (
                                            <div className="empty justify-center align-center">
                                                <i className="fa-spin fas fa-spinner fa-2x color-purple"></i>
                                            </div>
                                        )
                                )
                            }
                        </div>
                    </div>
                    <div className="intereses-container wrap hidden" id="intereses">
                    {
                        interests ?
                        (
                            interests.map((interest, key) => (
                                <div className="interes" key = { key } >
                                     <div className="column justify-center movile-labels">
                                    <p className="weight-semi text-center color-white wow animated fadeIn">
                                        { interest.cat }
                                    </p>
                                    </div>
                                </div>
                                )
                            )
                        )
                        :
                        (
                            <div className="empty justify-center align-center">
                                <i className="fa-spin fas fa-spinner fa-2x color-purple"></i>
                            </div>
                        )
                    }
                    </div>
                    <div className="white-space-32"></div>
                    <ModalDeleteInfluencerPublication idImage = { this.props.context.publicationInfluencerToDelete.idImage }  idInfluencer = { this.props.context.publicationInfluencerToDelete.idInfluencer } />
                    <BottomBar active = "bottomProfile" />
                    <ModalShowImage imageUrl = {this.props.context.imageInfluencerPublicationToShow.imageUrl}></ModalShowImage>         
                </MediaQuery>              
            </div>
        );
    }

    componentDidMount() {
        this.load();
        new WOW.WOW({
            live: false
        }).init();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if(user) {
            this.setState({userData: user.id});
            this.getInfluencer();
        }
    }

    async getInfluencer() {
        const request = new Request();
        const data = { idInfluencer: this.state.userData.id };
        const influencer = await request.post("/influencers/getProfile", data);
        if (influencer) {
            this.setState({ message: influencer.message, influencer: influencer.influencer, loadingInterest: true });
            this.loadImages();
        }
    }

    async loadImages() {
        this.setState({ loading: true });
        const data = { id: this.state.userData.id };
        const userData = await request.post("/users/images/", data);
        if (userData) {
            this.setState({ message: userData.message });
            if (userData.empty) {
                this.setState({ empty: userData.empty, loadingInterest: false });
            } else {
                if (userData.images) {
                    this.setState({ images: userData.images, loadingInterest: false });
                }
            }
        }
        this.setState({ loading: false, loadingInterest: false });
    }

    async handleDelete(idImage, idInfluencer) {
        this.props.context.setDeleteInfluencerPublication({ idImage, idInfluencer });
        let modal = document.getElementById('modal-deleInfluencerPublication');
        if (modal.classList.contains('hidden')) {
            modal.classList.remove('fadeOut');
            modal.classList.add('animated', 'fadeIn');
            modal.classList.remove('hidden');
        }
    }

    async handleShowImage(imageUrl) {
        this.props.context.setShowImageInfluencerPublication({imageUrl});
        let modalNewsLetter = document.getElementById('modal-show');  
        modalNewsLetter.classList.remove('fadeOut');
        modalNewsLetter.classList.add('animated', 'fadeIn','faster');
        modalNewsLetter.classList.remove('hidden');  
    }

    handleGallery() {
        let gallery = document.getElementById('gallery');
        let intereses = document.getElementById('intereses');
        let galleryTab = document.getElementById('gallery-tab');
        let interestTab = document.getElementById('interest-tab');
        if (interestTab.classList.contains('color-pink')) {
            interestTab.classList.remove('color-pink');
            interestTab.classList.add('color-gray');
            galleryTab.classList.remove('color-gray');
            galleryTab.classList.add('color-pink');
        }
        intereses.classList.add('hidden');
        gallery.classList.remove('hidden');
    }

    handleInterest() {
        let gallery = document.getElementById('gallery');
        let intereses = document.getElementById('intereses');
        let galleryTab = document.getElementById('gallery-tab');
        let interestTab = document.getElementById('interest-tab');
        if (galleryTab.classList.contains('color-pink')) {
            galleryTab.classList.remove('color-pink');
            galleryTab.classList.add('color-gray');
            interestTab.classList.remove('color-gray');
            interestTab.classList.add('color-pink');
        }
        gallery.classList.add('hidden');
        intereses.classList.remove('hidden');
    }

}

export default Consumer(ProfileInfluencer);
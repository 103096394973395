/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
 */

import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Conexión con el Context API
import { GlobalContext } from "../context";

// Páginas Web
import Splash from "../pages/splash";
import Register from "../pages/register";
import Login from "../pages/login";
import Search from "../pages/search";
import RegisterBrands from "../pages/register-brands";
import Interests from "../pages/interests";
import Home from "../pages/home";
import Publication from "../pages/publication";
import PublicationEdit from "../pages/publication-edit";
import PublicationAdd from "../pages/publication-add";
import Chats from "../pages/chats";
import Chat from "../pages/chat";
import Profile from "../pages/profile";
import ProfileBrands from "../pages/profile-brands";
import Favorites from "../pages/favorites";
import Settings from '../pages/settings';
import SettingsSecurity from '../pages/settings-security';
import SettingsLegality from '../pages/settings-legality';
import Password from '../pages/password';
import ProfileInfluencer from "../pages/profile-influencer";
import ProfileBrand from "../pages/profile-brand";
import Activate from '../pages/activate';
import PublicInfluencer from '../pages/public-influencer';
import PublicBrand from '../pages/public-brand';
import RecoverPassword from '../pages/recover-password';
import Suscription from '../pages/suscription';
import InterestsProfile from '../pages/interests-edit';
import Bloqued from '../pages/blocked-accounts';
import Payments from '../pages/payments';
import CuentaBan from '../pages/cuentaban';

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
    	{ ...rest }
		render = {
			props => {
				const usuario = window.localStorage.getItem("waalausr");
				if (usuario) {
					return <Component { ...props } />;
				} else {
					return (
						<Redirect to = {{ pathname: "/", state: { from: props.location } }} />
					);
				}
			}
		}
  	/>
);

class Routes extends Component {
	render() {
    	return (
			<GlobalContext>
				<BrowserRouter>
					<div className="flex main">
						<div className="column">
							<Switch>
								<Route path="/" exact component={Splash} />
								<Route path="/login" exact component={Login} />
								<Route path="/registro" exact component={Register} />
								<Route path="/activar/:id" exact component={Activate} /> 
								<Route path="/recoverpassword/" exact component={RecoverPassword} />
								<Route path="/recoverpassword/:id" exact component={RecoverPassword} />             
								<Route path="/buscar" exact component={Search} />
								<Route path="/intereses" exact component={Interests} />                                
								<Route path="/registro/marca" exact component={RegisterBrands}  /> 
								<PrivateRoute path="/chat/:usuario" exact component={Chat} />
								{/* Respuestas Pago */}
								<PrivateRoute path="/chat/:usuario/:status/:code" exact component={Chat} />
								{/*<PrivateRoute path="/chat/:usuario/error" exact component={Chat} />*/}
								<PrivateRoute path="/chats" exact component={Chats} />
								<PrivateRoute path="/inicio" exact component={Home} />
								<PrivateRoute path="/favoritos" exact component={Favorites} />
								<PrivateRoute path="/nuevo" exact component={PublicationAdd} /> 
								<PrivateRoute path="/ajustes" exact component = { Settings } />
								<PrivateRoute path="/ajustes/pagos" exact component = { Payments } />
								<PrivateRoute path="/ajustes/bancario" exact component = { CuentaBan } />
								<PrivateRoute path="/ajustes/seguridad" exact component = { SettingsSecurity } />
								<PrivateRoute path="/ajustes/legalidad" exact component = {SettingsLegality} />
								<PrivateRoute path="/publicacion/:id" exact component = { Publication } />
								{/* Influencer */}
								<PrivateRoute path="/ajustes/perfil" exact component={Profile} />
								<PrivateRoute path="/ajustes/bloqueos" exact component={Bloqued} />
								<PrivateRoute path="/ajustes/perfil/password" exact component = { Password } />
								<PrivateRoute path="/perfil/influencer" exact component={ProfileInfluencer} />
								{/* Marca */}
								<PrivateRoute path="/ajustes/perfil/marca" exact component={ProfileBrands} />
								<PrivateRoute path="/perfil/marca" exact component={ProfileBrand} />
								<PrivateRoute path="/favoritos/publicaciones" exact component={Favorites} />
								<PrivateRoute path="/publicacion/:id/editar" exact component={PublicationEdit} />
								<PrivateRoute path="/ajustes/suscripcion" exact component={Suscription} />
								{/* Editar Intereses */}
								<PrivateRoute path="/ajustes/intereses" exact component={InterestsProfile} />
								{/* Perfil Publico Influencer */}
								<PrivateRoute path="/influencer/:id" exact component = { PublicInfluencer } />
								{/* Perfil Publico Marca */}
								<PrivateRoute path="/marca/:id" exact component = { PublicBrand } />
							</Switch>
						</div>
					</div>
				</BrowserRouter>
			</GlobalContext>
    	);
  	}
}

export default Routes;

/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio , kath
 *  @description: Headers como componentes
*/

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import Isotipo from '../../img/logopink.png';
import MediaQuery from 'react-responsive';
import Request from '../../core/httpClient';
import Select from 'react-select';

const req = new Request();

class HeaderSearch extends Component {
    constructor(props) {
        super(props);
        this.state={
            userData: {id: 0, tipo: 0},
            filters: {},
            cities: [],
            intereses: [],
            sectores: [],
            interest:[]
        }
    }

    addFilter(filter) {
        const filtersState = this.state.filters;
        Object.assign(filtersState, filter);
        this.setState({filters: filtersState});
    }

    interesesMultiSelect(event) {
        const filtersState = this.state.filters;
        Object.assign(filtersState, {interest: event});
        this.setState({filters: filtersState});
    }

    render() {
        let prevLocation = '/inicio';
        if (this.props.context.prevLocation) { prevLocation = this.props.context.prevLocation; }
        const { selectedOption } = this.state;
        return (
            <div className="justify-center search-sticky">
            <MediaQuery minDeviceWidth = { 768 }>
                <header className="header-logged justify-center view-web">
                    <div className="container main-container">
                        <div className="header-search align-center">
                            <div className="row justify-center align-center">  
                                <div className="column">         
                                    <div className="logo">
                                        <Link to ="/inicio" className="justify-start align-center">
                                            <img className="logo" src = { Isotipo } alt="Logo Waala" title="Logo Waala" />
                                        </Link>
                                    </div>  
                                </div> 
                                <div className="column">
                                    <form className="form-search align-center justify-end">
                                        <i className="icon-search-web fas fa-search"></i>
                                        <input className="input-search-web"  type="text" id="search" name="search" placeholder="Buscar por nombre"/>
                                    </form>
                                </div> 
                                <div className="justify-end align-center">           
                                    <input type="checkbox" id="menyAvPaa" checked = { this.state.checked }/>
                                    <label className="btn-web btn-purple auto row align-center" id="filter" htmlFor="menyAvPaa">
                                        <i className=" fas fa-filter font-tiny color-white"></i>
                                        <p className="color-white text-center" id="filter-text">
                                            &nbsp;Filtrar búsqueda
                                        </p>
                                    </label>
                                    <div className="column align-center" id="menu-filter" >
                                        <div className="row header-filter">
                                            <div className="column align-start justify-center">
                                                <h3 className="color-white">FILTROS</h3>
                                            </div>
                                            <div className="column align-end justify-center">
                                                <label className="fas fa-times color-white" id="closemain" htmlFor="menyAvPaa"/>
                                            </div>
                                        </div>
                                        <div className="container column">
                                            <div className="white-space-16"></div>
                                            <div className="row">
                                                <div className="inputfilter-container align-center">
                                                    <i className="fas fa-euro-sign color-pink"></i>
                                                    <input type="text" name="minprice" placeholder="Precio Mín" className="input input-filter" onChange={(event) => this.addFilter({minprice: event.target.value})}/>
                                                </div>
                                                <div className="inputfilter-container align-center">
                                                    <i className="fas fa-euro-sign color-pink"></i>
                                                    <input type="text" name="maxprice" placeholder="Precio Max" className="input input-filter" onChange={(event) => this.addFilter({maxprice: event.target.value})}/>
                                                </div>
                                            </div>
                                            <div className="white-space-16"></div>
                                            <hr/>
                                            <div className="input-container align-center">
                                                <div className="column"> 
                                                    <h4>Ciudad: </h4>
                                                </div>      
                                                <div className="column">
                                                    <select name="city" className="input input-filter-opt" value={this.state.filters.ciudad}
                                                        onChange={(event) => this.addFilter({ciudad: event.target.value})}>
                                                        <option value="">Todas</option>
                                                        {
                                                            this.state.cities.map((ciudad, index) => 
                                                            <option key={index} value={ciudad.value}> { ciudad.city }</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                this.state.userData.tipo.toString() === "2" ? <hr/> : null }
                                            {
                                                this.state.userData.tipo.toString() === "2"?
                                                    <Fragment>
                                                        <div className="input-container align-center">
                                                            <div className="column">
                                                                <h4>Seguidores :</h4>
                                                            </div>
                                                            <div className="column">
                                                                <select name="followers" className="input input-filter-opt" onChange = { (event) => this.addFilter({followers: event.target.value}) }>
                                                                    <option value="">Todos</option>
                                                                    <option value="0;1000">0/1000</option>
                                                                    <option value="1000;5000">1000/5000</option>
                                                                    <option value="5000;10000">5000/10000</option>
                                                                    <option value="10000;50000">10000/50000</option>
                                                                    <option value="50000;inf">+50.000</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                    : null
                                            }
                                            <hr/>
                                            <div className="input-container align-center">
                                                <div className="column">
                                                    <h4>Intereses :</h4>
                                                </div>
                                                <div className="column">
                                                    <select name="interest" className="input input-filter-opt" value={this.state.filters.intereses}
                                                        onChange={(event) => this.addFilter({intereses: event.target.value})}>
                                                        <option value="">Todos</option>
                                                        {this.state.intereses.map((intereses, index) => 
                                                            <option key={index} value={intereses.categoria}>{intereses.categoria}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <hr />
                                            {
                                                this.state.userData.tipo.toString() === "2"?
                                                    <Fragment>
                                                        <div className="input-container align-center">
                                                            <div className="column">
                                                                <h4>Género :</h4>
                                                            </div>
                                                            <div className="column">
                                                                <select name="gender" className="input input-filter-opt" value={this.state.filters.genero}
                                                                    onChange={(event) => this.addFilter({genero: event.target.value})}>
                                                                    <option value="">Todos</option>
                                                                    <option value="H">Hombre</option>
                                                                    <option value="M">Mujer</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </Fragment>
                                                : null
                                            }
                                            {
                                                this.state.userData.tipo.toString() === "1"?
                                                <Fragment>
                                                    <div className="input-container align-center">
                                                        <div className="column">
                                                            <h4>Sector :</h4>
                                                        </div>
                                                        <div className="column">
                                                            <select name="gender" className="input input-filter-opt" value={this.state.filters.sector}
                                                                onChange={(event) => this.addFilter({sector: event.target.value})}>
                                                                <option value="">Todos</option>
                                                                {this.state.sectores.map((sector,index)=>
                                                                    <option index={index} value={sector.id_sector}>{sector.sector}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </Fragment>
                                                : null
                                            }
                                            <div className="white-space-16"></div>
                                            <div className="btn-container justify-center">
                                                <button className="btn btn-purple color-white text-center" id="btn-marcas" onClick={this.props.filterCards.bind(this, this.state.filters)}>
                                                    APLICAR FILTROS
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </MediaQuery>
            <MediaQuery maxWidth = { 768 }>
            <header className="header-single justify-center">
                <div className="container">
                    <div className="header-search align-center">
                        <div className="back auto justify-center align-center">
                            <Link to = { prevLocation }>
                                <i className="fas fa-angle-left color-white font-regular"></i>
                            </Link>
                        </div>
                        <form className="form-search align-center justify-end">
                            <i className="icon-search icon-search-search fas fa-search"></i>
                            <input className="input-search input-search-search"  type="text" id="search" name="search" placeholder="Buscar por nombre"/>
                        </form>
                        <div className="filter-icon auto justify-center align-center">                            
                            <input type="checkbox" id="menyAvPaa" checked = { this.state.checked }/>
                            <label className="" id="filter" htmlFor="menyAvPaa">
                                <i className="text-center fas fa-filter font-tiny color-white"></i>
                                <p className="color-white text-center" id="filter-text">
                                    Filtrar
                                </p>
                            </label>
                            
                            <div className="column align-center" id="menu-filter" >
                                <div className="row header-filter">
                                    <div className="column align-start justify-center">
                                        <h3 className="color-white">FILTROS</h3>
                                    </div>
                                    <div className="column align-end justify-center">
                                        <label className="fas fa-times color-white" id="closemain" htmlFor="menyAvPaa"/>
                                    </div>
                                </div>
                                <div className="container column">
                                    <div className="white-space-16"></div>
                                    <div className="row">
                                        <div className="inputfilter-container align-center">
                                            <i className="fas fa-euro-sign color-pink"></i>
                                            <input type="text" name="minprice" placeholder="Precio Mín" className="input input-filter" onChange={(event) => this.addFilter({minprice: event.target.value})}/>
                                        </div>
                                        <div className="inputfilter-container align-center">
                                            <i className="fas fa-euro-sign color-pink"></i>
                                            <input type="text" name="maxprice" placeholder="Precio Max" className="input input-filter" onChange={(event) => this.addFilter({maxprice: event.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <hr/>
                                    <div className="input-container align-center">
                                        <div className="column"> 
                                            <h4>Ciudad: </h4>
                                        </div>      
                                        <div className="column">
                                            <select name="city" className="input input-filter-opt" value={this.state.filters.ciudad}
                                                onChange={(event) => this.addFilter({ciudad: event.target.value})}>
                                                <option value="">Todas</option>
                                                {this.state.cities.map((ciudad, index) => 
                                                    <option key={index} value={ciudad.value}>{ciudad.city}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.userData.tipo.toString() === "2"? <hr/> : null }
                                    {this.state.userData.tipo.toString() === "2"?
                                        <React.Fragment>
                                            <div className="input-container align-center">
                                                <div className="column">
                                                    <h4>Seguidores :</h4>
                                                </div>
                                                <div className="column">
                                                    <select name="followers" className="input input-filter-opt" onChange = { (event) => this.addFilter({followers: event.target.value}) }>
                                                        <option value="">Todos</option>
                                                        <option value="0;1000">0/1000</option>
                                                        <option value="1000;5000">1000/5000</option>
                                                        <option value="5000;10000">5000/10000</option>
                                                        <option value="10000;50000">10000/50000</option>
                                                        <option value="50000;inf">+50.000</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        : null
                                    }
                                    <hr/>
                                    <div className="input-container align-center">
                                        <div className="column">
                                            <h4>Intereses :</h4>
                                        </div>
                                        <div className="column">
                                            <select name="interest" className="input input-filter-opt" value={this.state.filters.intereses}
                                                onChange={(event) => this.addFilter({intereses: event.target.value})}>
                                                <option value="">Todos</option>
                                                {this.state.intereses.map((intereses, index) => 
                                                    <option key={index} value={intereses.categoria}>{intereses.categoria}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    {/*<div className="input-container align-center">
                                        <div className="column no-flex">
                                            <Select
                                                value={selectedOption}
                                                onChange={this.handleChange}
                                                options={this.state.intereses}
                                                isMulti={true}
                                                placeholder = { 'Intereses' }
                                                onChange={this.interesesMultiSelect.bind(this)}
                                            />
                                        </div>
                                    </div>*/}
                                    <hr />
                                    {
                                        this.state.userData.tipo.toString() === "2"?
                                        <Fragment>
                                            <div className="input-container align-center">
                                                <div className="column">
                                                    <h4>Género :</h4>
                                                </div>
                                                <div className="column">
                                                    <select name="gender" className="input input-filter-opt" value={this.state.filters.genero}
                                                        onChange={(event) => this.addFilter({genero: event.target.value})}>
                                                        <option value="">Todos</option>
                                                        <option value="H">Hombre</option>
                                                        <option value="M">Mujer</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <hr />
                                        </Fragment>
                                        : null
                                    }
                                    {
                                        this.state.userData.tipo.toString() === "1"?
                                        <Fragment>
                                            <div className="input-container align-center">
                                                <div className="column">
                                                    <h4>Sector :</h4>
                                                </div>
                                                <div className="column">
                                                    <select name="gender" className="input input-filter-opt" value={this.state.filters.sector}
                                                        onChange={(event) => this.addFilter({sector: event.target.value})}>
                                                        <option value="">Todos</option>
                                                        {this.state.sectores.map((sector,index)=>
                                                            <option index={index} value={sector.id_sector}>{sector.sector}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <hr />
                                        </Fragment>
                                        : null
                                    }
                                    <div className="white-space-16"></div>
                                    <div className="btn-container justify-center">
                                        <button className="btn btn-purple color-white text-center btn-purple-aply" id="btn-marcas" onClick={this.props.filterCards.bind(this, this.state.filters)}>
                                            APLICAR FILTROS
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </header>
                </MediaQuery>
            </div>
        )
    }

    componentDidMount() {
        this.load();
        const params = new URLSearchParams(this.props.location.search);
        let search = '';
        if(params) {
            search = params.get('search');
            const input = document.getElementById('search');
            if(input) {
                input.value = search;
            }
        }
    }

    async load() {
        const user = await this.props.context.loadUser();
        if(user) {
            this.setState({userData: user.id})
        }
        let cities = [];
        let intereses = [];
        let sectores = [];
        const resC = await req.post('/ciudades');
        const resI = await req.get('/intereses');
        const resS = await req.get('/sectores');
        if(resC.cities) {
            cities = resC.cities;
        }
        if(resI.categorias) {
            intereses = resI.categorias.map(interes => {
                return {...interes, label: interes.categoria, value: interes.categoria}
            });
        }
        if(resS.sectores) {
            sectores = resS.sectores
        }
        this.setState({cities, intereses, sectores});
    }
};

export default withRouter(Consumer(HeaderSearch));
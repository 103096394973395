/**
 *  brands.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Tarjetas de empresas
*/

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Request from '../../core/httpClient';
import HolderImage from '../../img/user.png';
import WOW from 'wowjs';
import { Consumer } from '../../context';

const req = new Request();

class PublicationCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			favorito: this.props.favorito || false,
			idPublication: this.props.id, idMarca: this.props.idMarca
		}
	}

	async addFav() {
		this.setState({loadingFav: true});
        const data = {
            id_influencer: this.props.userId,
            id_marca: this.props.id,
            tipo: this.props.tipo,
			favorito: !this.state.favorito,
			tipo_pub: this.props.tipo_pub
        }
        const res = await req.post('/favoritos/put', data);
        if(res.error) {
            console.log(res.message);
        } else {
            this.setState({favorito: !this.state.favorito});
        }
        this.setState({loadingFav: false});
	}
	
	handleRedirect(idPublication) {
		this.props.history.push(`/publicacion/${idPublication}`);
	}

	render() {
		let imagen = '';
		let idPublication = '';
		if(this.props.imagen) {
			imagen = this.props.imagen;
		} else {
			imagen = HolderImage;
		}
		// console.log(this.props)
		if (this.props.id) { idPublication = this.props.id; }
		return (
			<div className="brand-cards publication-card column wow animated fadeInUp faster">
				<div className="image" onClick = {this.handleRedirect.bind(this, idPublication)}>
					<img src = { imagen } alt="Imagen del influencer" title="Imagen del influencer" className="cover-img"/>
					<div className="title column justify-center align-center">
						<div className="white-space-64"></div>
						<div className="data justify-center">
							<div className="column justify-end">
								<h4 className="name color-white text-center">
									{ this.props.nombre }
								</h4>
								<p className="color-white text-center">
									{ this.props.ciudad } &nbsp;
								</p>
							</div>
							<div className="column justify-end">
								<h4 className="name color-white text-center">
									{ this.props.tarifa } € &nbsp; 
								</h4>
								<p className="color-white text-center">
									Pago
								</p>
							</div>
						</div>
						<div className="white-space-32"></div>
					</div>
				</div>
				{
					this.props.role === "owner" ?
						(
							<div className="btn-container">
								<Link to={`/publicacion/${idPublication}/editar`} className="btn btn-square btn-pink color-white text-center font-regular btn-owner" id="editar-publication">
									<i className="fas fa-pencil-alt color-gray"></i>
								</Link>
								<div className="divider auto"></div>
								<button onClick={this.handleDelete.bind(this, this.props.id, this.props.idMarca)} className="btn btn-square btn-pink color-white text-center font-regular btn-owner" id="delete-publication">
									<i className="fas fa-trash-alt color-red"></i>
								</button>
							</div>
						) :
						(
							<div className="btn-container publication-btn-container">
								<button className="btn btn-square color-white text-center font-regular first" onClick={this.addFav.bind(this)}>
									{
										this.state.loadingFav ?
											(
												(<i className="fas fa-spin fa-spinner color-pink"></i>)
											) :
											(
												this.state.favorito ?
													<i className="fas fa-heart wow animated fadeIn color-pink"></i> :
													<i className="far fa-heart wow animated fadeIn color-pink"></i>
											)
									}
								</button>
								<div className="divider auto"></div>
								<a onClick={this.selectPublication.bind(this)} className="btn btn-square btn-pink color-white text-center font-regular last">
									<i className="far fa-comments color-purple"></i>
								</a>
							</div>
						)
				}
			</div>
		)
	}

	componentDidMount() {
		new WOW.WOW({
			live: false
		}).init();
	}

	handleDelete(idPublication, idMarca) {
		this.props.context.setDeleteBrandPublication({idPublication, idMarca});
		let modal = document.getElementById('modal-delePublication');
		if (modal.classList.contains('hidden')) {
			modal.classList.remove('fadeOut');
			modal.classList.add('animated','fadeIn');
			modal.classList.remove('hidden');
		}
	}	

	selectPublication() {
		window.localStorage.setItem('waalapub', `${this.props.id};${this.props.titulo}`);
		this.props.history.push(`/chat/${this.props.usuario}`);
	}
}

export default withRouter(Consumer(PublicationCard));
/**
 *  brands.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Tarjetas de empresas
*/

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Request from '../../core/httpClient';
import HolderImage from '../../img/user.png';
import WOW from 'wowjs';

const req = new Request();

class BrandCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			favorito: this.props.favorito || false
		}
	}

	async addFav() {
		this.setState({loadingFav: true});
        const data = {
            id_influencer: this.props.userId,
            id_marca: this.props.id,
            tipo: this.props.tipo,
			favorito: !this.state.favorito,
			tipo_pub: this.props.tipo_pub
        }
        const res = await req.post('/favoritos/put', data);
        if(res.error) {
            console.log(res.message);
        } else {
            this.setState({favorito: !this.state.favorito});
        }
        this.setState({loadingFav: false});
	}
	
	handleRedirect(usuario) {
		this.props.history.push(`/marca/${usuario}`);
	}
	
	render() {
		let imagen = '';
		let usuario = '';
		if(this.props.imagen) {
			imagen = this.props.imagen;
		} else {
			imagen = HolderImage;
		}
		if (this.props.usuario) { usuario = this.props.usuario; }
		return (
			<div className="brand-cards column wow animated fadeInUp faster">
				<div className="image" onClick = {this.handleRedirect.bind(this, usuario)}>
					<img src = { imagen } alt="Imagen del influencer" title="Imagen del influencer" className="cover-img"/>
					<div className="title column justify-center align-center">
						<div className="white-space-64"></div>
						<div className="data justify-center">
							<div className="column">
								<h4 className="text-center color-white name">
									{ this.props.usuario }
								</h4>
								<p className="color-white text-center">
									&nbsp;
                        		</p>
							</div>
							<div className="column">
								<h4 className="text-center color-white name">
									{ this.props.ciudad }
								</h4>
								<p className="color-white text-center">
                            		&nbsp;
                        		</p>
							</div>
						</div>
						<div className="white-space-32"></div>
					</div>
				</div>
				<div className="btn-container">
					<button className="btn btn-square color-white text-center font-regular" onClick={this.addFav.bind(this)}>
						{
							this.state.loadingFav ?
								(
									(<i className="fas fa-spin fa-spinner color-pink"></i>)
								) :
								(
									this.state.favorito ?
										<i className="fas fa-heart wow animated fadeIn color-pink"></i> :
										<i className="far fa-heart wow animated fadeIn color-pink"></i>
								)
						}
					</button>
					<div className="divider auto"></div>
					<a onClick={this.selectPublication.bind(this)} className="btn btn-square btn-pink color-white text-center font-regular">
						<i className="far fa-comments color-purple"></i>
					</a>
				</div>
			</div>
		)
	}

	componentDidMount() {
		new WOW.WOW({
			live: false
		}).init();
	}

	selectPublication() {
		window.localStorage.removeItem('waalapub');
		this.props.history.push(`/chat/${this.props.usuario}`);
	}
}

export default withRouter(BrandCard);
/**
 *  app.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Punto de Entrada de la aplicación
 * 	@process: 2
*/

import React from 'react';
import Views from './core/views';
import socket from './core/socket';

process.env.AUTH = '908u90u09u099u09u0';
let user = window.localStorage.getItem("waalausr");
if ( user ) {
	user = JSON.parse(user);
	socket.emit('infoUser', user.id);
}

const App = () => (
	<div className="App">
		<Views></Views>
	</div>
);

export default App;


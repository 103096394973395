/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Mariano
 *  @description: Página para activar usuario
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Request from '../core/httpClient';
import InfluencersImage from '../img/splash.jpg';
import Logo from '../img/logotransparente.png';
import WaalaText from '../img/waalatext.png';
import { Helmet } from 'react-helmet';

const req = new Request();

class Activate extends Component {
	constructor(props) {
		super(props);
		this.state={
			message: '',
			href: '#'
		}
	}

	componentDidMount() {
		this.props.match.params.id? this.checkToken() : this.setState({message: 'token no valido'});
	}

	async checkToken() {
		this.setState({message: 'CONSULTANDO ACTIVACIÓN'});
		const res = await req.post('/users/activate', {id:this.props.match.params.id});
		this.setState({message: res.message});
		if(res.activate) {
			this.setState({href: '/'});
		}
	}

    render() {
        return(
            <div className="splash activate justify-center" style={{ backgroundImage: `url(${InfluencersImage })`}}>
				<Helmet>
					<title>WAALA - Activar Cuenta</title>
					<meta name="robots" content="noindex" />
					<meta property="og:title" content="WAALA - Activar Cuenta" />
				</Helmet>
				<div className="overlay justify-center">
					<div className="container column">
						<div className="white-space-64"></div>
						<div className="logo justify-center align-center">
							<img src = { Logo } alt="Logo Waala" title="Logo Waala" />
						</div>
						<div className="white-space-16"></div>
                        <div className="text responsive-img justify-center align-center wow fadeIn">
                            <img src = { WaalaText } alt="Waala texto" title="Waala texto" />
                        </div>
						<div className="white-space-64"></div>
						<p className="color-white text-center weight-semi">
							{ this.state.message }
						</p>
						<div className="white-space-64"></div>
						<div className="btn-container justify-center">
							<Link to={this.state.href} className="btn btn-pink btn-full text-center color-white weight-semi">
								ACEPTAR
							</Link>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

export default Activate;
/**
 *  register.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de registro
 */

import React, { Component } from "react";
import { HeaderSingle } from "../components/header/header";
import { Link } from "react-router-dom";
import { Consumer } from '../context';
import { withRouter } from 'react-router-dom';
import WOW from 'wowjs';
import { Helmet } from 'react-helmet';

class Register extends Component {

	constructor(props) {
		super(props);
		this.state = {
			user: {id: 0, tipo: 0}
		};
	}	

	async hanldeSubmit(event) {
		event.preventDefault();
		this.setState({ loading: true, message: "" });
		const form = event.target;
		const data = {
			Nombre: form.name.value, 
			Email: form.email.value, 
			Telefono: '',
			Genero: form.gender.value, 
			Ciudad: form.city.value, 
			Password: form.password.value,
			Tipo: 1,
			IdSector:'',
			Sector:''
		};
		this.props.context.saveRegister(data);
		this.props.history.push('/intereses');
	}

	render() {
		return (
			<div className="register column">
				<Helmet>
					<title>WAALA - Registro</title>
					<meta property="og:title" content="WAALA - Registro" />
				</Helmet>
				<HeaderSingle />
				<div className="white-space-32" />
				<div className="justify-center">
					<div className="container column forms">
						<h2 className="text-center wow fadeIn">
							Crea una cuenta
						</h2>
						<div className="white-space-24" />
						<form onSubmit={this.hanldeSubmit.bind(this)} className="column">
							<div className="input-container align-center wow fadeInUp faster">
								<i className="fas fa-user-edit font-small text-center"/>
								<input type="text" name="name" placeholder="Nombre completo" className="input" minLength="8" maxLength="60" required />
							</div>
							<div className="white-space-8" />
							<div className="input-container align-center wow fadeInUp faster">
								<i className="fas fa-at font-small text-center"/>
								<input type="email" name="email" placeholder="Correo electrónico" className="input" minLength="8" maxLength="80" required />
							</div>
							<div className="white-space-8" />
							<div className="input-container align-center wow fadeInUp faster">
								<i className="fas fa-user-friends font-small text-center"/>
								<select name="gender" className="input input-select" required>
									<option value="">Género</option>
									<option value="Hombre">Hombre</option>
									<option value="Mujer">Mujer</option>
								</select>
							</div>
							<div className="white-space-8"/>
							<div className="input-container align-center wow fadeInUp faster">
								<i className="fas fa-map-marked-alt font-small text-center"/>
								<select name="city" className="input input-select" required>
									<option value="A Coruña">A Coruña</option>
									<option value="Álava/Araba">Álava/Araba</option>
									<option value="Albacete">Albacete</option>
									<option value="Alicante/Alacant">Alicante/Alacant</option>
									<option value="Almería">Almería</option>
									<option value="Asturias">Asturias</option>
									<option value="Ávila">Ávila</option>
									<option value="Badajoz">Badajoz</option>
									<option value="Barcelona">Barcelona</option>
									<option value="Burgos">Burgos</option>
									<option value="Cáceres">Cáceres</option>
									<option value="Cádiz">Cádiz</option>
									<option value="Cantabria">Cantabria</option>
									<option value="Castellón/Castelló">Castellón/Castelló</option>
									<option value="Ceuta">Ceuta</option>
									<option value="Ciudad Real">Ciudad Real</option>
									<option value="Córdoba">Córdoba</option>
									<option value="Cuenca">Cuenca</option>
									<option value="Girona">Girona</option>
									<option value="Granada">Granada</option>
									<option value="Guadalajara">Guadalajara</option>
									<option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</option>
									<option value="Huelva">Huelva</option>
									<option value="Huesca">Huesca</option>
									<option value="Illes Balears">Illes Balears</option>
									<option value="Jaén">Jaén</option>
									<option value="La Rioja">La Rioja</option>
									<option value="Las Palmas">Las Palmas</option>
									<option value="León">León</option>
									<option value="Lleida">Lleida</option>
									<option value="Lugo">Lugo</option>
									<option value="Madrid">Madrid</option>
									<option value="Málaga">Málaga</option>
									<option value="Melilla">Melilla</option>
									<option value="Murcia">Murcia</option>
									<option value="Navarra">Navarra</option>
									<option value="Ourense">Ourense</option>
									<option value="Palencia">Palencia</option>
									<option value="Pontevedra">Pontevedra</option>
									<option value="Salamanca">Salamanca</option>
									<option value="Santa Cruz de Tenerife">Santa Cruz de Tenerife</option>
									<option value="Segovia">Segovia</option>
									<option value="Sevilla">Sevilla</option>
									<option value="Soria">Soria</option>
									<option value="Tarragona">Tarragona</option>
									<option value="Teruel">Teruel</option>
									<option value="Toledo">Toledo</option>
									<option value="Valencia/València">Valencia/València</option>
									<option value="Valladolid">Valladolid</option>
									<option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</option>
									<option value="Zamora">Zamora</option>
									<option value="Zaragoza">Zaragoza</option>
								</select>
							</div>
							<div className="white-space-8" />
							<div className="input-container align-center wow fadeInUp faster">
								<i className="fas fa-key font-small text-center"/>
								<input type="password" name="password" placeholder="Contraseña" className="input" minLength="8" maxLength="16" required />
							</div>
							<div className="white-space-16"/>
							<div className="btn-container justify-center wow fadeInUp faster">
								<button  className="btn btn-pink weight-semi font-regular color-white">
									CREAR CUENTA
								</button>
							</div>
							<div className="white-space-8" />
							<Link to="/files/terminos-y-condiciones.pdf" target="_blank" className="text-center font-mini wow fadeInUp faster">
								Al registrarte, aceptas los Términos y Condiciones de Uso de Waala.
							</Link>
						</form>
						<div className="white-space-8" />
						<div className="btn-container justify-center wow fadeInUp faster">
							<Link to="/registro/marca" className="btn btn-purple color-white text-center" id="btn-marcas">
								REGISTRO PARA MARCAS
							</Link>
						</div>
					</div>
				</div>
				<div className="white-space-32" />
			</div>
		);
	}

	componentDidMount() {
        new WOW.WOW({ live: false }).init();
        this.load();
    }    

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
			this.setState({user: user.id});
        	this.validateUser();
		}
    }

    validateUser() {
        if (this.state.user && this.state.user.id) { window.location = '/inicio'; }
    }
}

export default withRouter(Consumer(Register));

import io from 'socket.io-client';

let socket;
if (process.env.NODE_ENV === "production") {
	//socket = io("https://waala.herokuapp.com");
	socket = io("https://waalainfluencers.herokuapp.com/");
} else {
	socket = io('http://localhost:8081');
}

export default socket;
/**
 *  recovery.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Mariano, Sergio
 *  @description: Página de recuperar contraseña
*/

import React, { Component } from "react";
import { HeaderSingle } from "../components/header/header";
import socket from '../core/socket';
import Request from "../core/httpClient";
import { withRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MediaQuery from 'react-responsive';
import InfluencersImage from '../img/splash.jpg';
import { HeaderWebLogin } from '../components/header/header';

const req = new Request();

class RecoverPassword extends Component {

  	constructor(props) {
    	super(props);   
    	this.state = {
      		message: "", loading: false, validMail: false, validCode: false
    	};
	}

	componentDidMount() {
		socket.io.on('connected', () => {
			this.setState({message: ''});
		});
		socket.io.on('connect_error', (err) => {
			console.log(err.message);
		});
	}

	async sendEmail(event) {
		event.preventDefault();
		this.setState({loading: true, message: ''});
		const form = event.target;
		const data = { email: form.email.value }
		const res = await req.post('/users/recover/sendmail', data);
		if (res) {
			this.setState({message: res.message});
			if (res.valid) {
				this.setState({validMail: true, email: form.email.value});
			}
		}
		this.setState({loading: false});
	}

	async sendCode(event) {
    	event.preventDefault();
    	this.setState({loading: true, message: ''});
    	const form = event.target;
    	const data = { email: this.state.email, codigo: form.code_number.value }
    	const res = await req.post('/users/recover/sendcode', data);
    	if(res) {
      		this.setState({message: res.message});
      		if(res.valid) {
        		this.setState({validCode: true});
      		}
    	}
    	this.setState({loading: false});
  	}

  	async updatePassword(event) {
    	event.preventDefault();
    	this.setState({ loading: true });
    	const form = event.target;
    	if (form.passwordNew.value !== form.passwordConfirm.value) {
      		this.setState({loading: false, message: "LAS CONTRASEÑAS NO COINCIDEN"});
      		return;
		}
	
		// Envia solicitud de cambio de contraseña
		socket.emit('changePassword', {
			email: this.state.email,
			passwordNew: form.passwordNew.value
		});
	
		// Recibe respuesta de la peticion
		socket.on('restorePassword', (data) => {
			this.setState({loading: false, message: data.message});
			if (data.updated) {
				this.props.history.push('/');
			}
		})
  	}

	renderSendMail() {
    	let mensaje = "";
    	if (this.state.message) { mensaje = this.state.message; }
		return (
			<form className="column" onSubmit={this.sendEmail.bind(this)} >
				<div className="white-space-16" />
				<div className="input-container align-center">
					<i className="fas fa-at font-small" />
					<input type="email" name="email" placeholder="Correo electrónico" className="input" required />
				</div>
				<div className="white-space-16"></div>
				<div className="justify-center">
					<p className="text-center">{ mensaje }</p>
				</div>
				<div className="btn-container justify-center">
					<button className="btn btn-pink weight-semi font-regular color-white">
						SOLICITAR &nbsp;
						{
							this.state.loading?
								<i className="fas fa-spinner fa-spin"></i>
								: <i className="fas fa-envelope"></i> 
						}
					</button>
				</div>
			</form>
		);
  	}

	renderCode() {
		let mensaje = "";
		if (this.state.message) { mensaje = this.state.message; }
		return (
			<div className="column align-center">
				<div className="white-space-32"></div>
				<form className="column" onSubmit = { this.sendCode.bind(this) } >
					<div className="input-container align-center">
						<i className="fas fa-lock font-small" />
						<input type="text" id="code_number" name="code_number" className="input" maxLength="6" placeholder="000000"></input>
					</div>
					<div className="white-space-32" />
					<div className="justify-center">
						<h4 className="text-center"> { mensaje } </h4>
					</div>
					<div className="white-space-32"></div>
					<div className="btn-container justify-center">
						<button className="btn btn-pink weight-semi font-regular color-white">
							ENVIAR &nbsp;
							{
								this.state.loading?
									<i className="fas fa-spinner fa-spin"></i>
									: <i className="fas fa-paper-plane"></i> 
							}
						</button>
					</div>
				</form>
				<div className="white-space-32"></div>
			</div>
		);
	}

	renderPassword() {
		return (
			<form className="password justify-center" onSubmit = { this.updatePassword.bind(this) }>
				<div className="container column">  
					<div className="column">
						<div className="white-space-16"></div>
						<div className="justify-center">
							<p className="color-darkgray weight-bold text-center">
								Cambiar contraseña
							</p>
						</div>
						<div className="white-space-16"></div>
						<div className="column">
							<input type="password" name="passwordNew" placeholder="Contraseña nueva" className="input input-password" minLength="8" required />
							<div className="white-space-8"></div>
							<input type="password" name="passwordConfirm" placeholder="Repetir contraseña nueva" minLength="8" className="input input-password" required />  
						</div>
						{
							this.state.message ?
							(
								<div className="column">
									<div className="white-space-16"></div>
									{
										this.state.loading ? 
										(
											<div className="justify-center">
												<i className="fas fa-spin fa-spinner color-pink"></i>
											</div>
										) : ( null )
									}
									<p className="text-center">
										{ this.state.message }
									</p>
								</div>
							) :
							( null )
						}
						<div className="white-space-16"></div>
						<button type="submit" className="btn btn-pink weight-semi font-regular color-white">
							{
								this.state.loading ?
									(<i className="fas fa-spin fa-spinner font-small color-white i-right"></i>) :
									(<i className="fas fa-check font-small color-white i-right"></i>)
							}
						</button>
					</div>
				</div>
			</form>
		);
	}
   
	render() {

		return (
			<div className="login recovery column">
				<Helmet>
					<title>WAALA - Recuperar Contraseña</title>
					<meta name="robots" content="noindex" />
					<meta property="og:title" content="WAALA - Recuperar contraseña" />
				</Helmet>
					<MediaQuery minDeviceWidth = { 768 }>
						<div className="column view-web">
							<div className="splash column " style={{ backgroundImage: `url(${InfluencersImage })`}}>
								<HeaderWebLogin />
								<div className="modal justify-center modal-register-brand" id="modal-register">
									<div className="container modal-content align-center column" >
										<div className="white-space-48"></div>
										<h2 className="text-center">
											Recuperar contraseña
										</h2>
										<Link to="/" className="btn auto" id="close-modal-register">
											<i className="fas fa-times"></i>
										</Link>
										<div className="white-space-16"></div>
										{
											this.state.validMail ? 
											( 
												this.state.validCode ? ( this.renderPassword() ) : ( this.renderCode() ) 
											)
											: ( this.renderSendMail() )
										}
										<div className="white-space-16"></div>
									</div>
								</div>
							</div>
						</div>
					</MediaQuery>
					<MediaQuery maxWidth = { 768 }>
						<HeaderSingle />
						<div className="justify-center">
							<div className="container column">
								<div className="white-space-32" />
								<h3 className="text-center">RECUPERAR CONTRASEÑA</h3>
									{
										this.state.validMail ? 
										( 
											this.state.validCode ? ( this.renderPassword() ) : ( this.renderCode() ) 
										)
										: ( this.renderSendMail() )
									}
							</div>
						</div>
					</MediaQuery>
			</div>
		);
  	}
}

export default withRouter(RecoverPassword);

/**
 *  index.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejador principal del estado global de la aplicación
 */

import React, { Component, createContext } from "react";
import { saveRegister, logIn, logOut, loadUser, interestUpdated } from './users';
import { setDeleteBrandPublication, setDeleteInfluencerPublication, setShowImageInfluencerPublication } from './publications';
import { prevLocation, loadLocation, prevLocationProfile, loadLocationProfile } from './location';

// Instancia del Context, métodos: Provider y Consumer

const Context = createContext();

class GlobalContext extends Component {

	constructor(props) {
    	super(props);
    	this.state = {
			publicationBrandToDelete: {},
			publicationInfluencerToDelete: {},
			imageInfluencerPublicationToShow: {},
			logIn: logIn.bind(this),
			logOut: logOut.bind(this),
			saveRegister: saveRegister.bind(this),
			loadUser: loadUser.bind(this),
			setDeleteBrandPublication: setDeleteBrandPublication.bind(this),
			setDeleteInfluencerPublication: setDeleteInfluencerPublication.bind(this),
			setShowImageInfluencerPublication: setShowImageInfluencerPublication.bind(this),
			interestUpdated: interestUpdated.bind(this),
			setPrevLocation: prevLocation.bind(this),
			loadLocation: loadLocation.bind(this),
			setPrevLocationProfile: prevLocationProfile.bind(this),
			loadLocationProfile: loadLocationProfile.bind(this)
    	};
  	}
	componentDidMount() {
		this.state.loadUser();
		this.state.loadLocation();
		this.state.loadLocationProfile();
		this.setState({publication: 1})
	}

	render() {
    	return (
      		<Context.Provider value={this.state}>
        		{ this.props.children }
      		</Context.Provider>
    	);
  	}
}


/**
 * @function: Consumer
 * @description: HOC conector entre el estado global y un componente consumidor.
 * @param: Component => Componente Web
 *
 */

const Consumer = Component => {
	return props => {
    	return (
      		<Context.Consumer>
        		{ context => <Component {...props} context={context} /> }
      		</Context.Consumer>
    	);
  	};
};

export { Consumer, GlobalContext };

/**
 *  chat.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Mariano estuvo aqui ):D
 *  @description: Página del Chat activo
*/

import React, { Component } from 'react';
import { HeaderChat, HeaderWebHomeLoggedIn, HeaderChatSearch } from '../components/header/header';
import BottomControls from '../components/chat/bottomcontrols';
import Messages from '../components/chat/messages';
import socket from '../core/socket';
import { Consumer } from '../context';
import { Helmet } from 'react-helmet';
import PayChat from '../components/chat/pay';
import moment from 'moment-timezone';
import Request from '../core/httpClient';
import LeftBar from '../components/left-bar/left-bar';
import MediaQuery from 'react-responsive';
import Chats from '../pages/chats';

const req = new Request();

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state= {
            messages: [],
            userData: { id: 0, tipo: 0},
            recibe: {},
            loading: false,
            room: '',
            publications: []
        }
    }

    componentWillUnmount() {
        /*if(this.props.location.pathname.includes('aprovado') || this.props.location.pathname.includes('error')) {
            this.props.context.setPrevLocation({pathname: `/chat/${this.props.match.params.usuario}`});
        } else {
            this.props.context.setPrevLocation(this.props.location);
        }*/
        window.localStorage.removeItem('waalapub');
    }

    async load() {
        const user = await this.props.context.loadUser();
        if(user) {
            this.setState({userData: user.id});
            socket.emit('loadInfo', {
                user: user.id.id,
                header: this.props.match.params.usuario,
                tipo: user.id.tipo
            });

            const res = await req.post('/publications/getByBrandId', {user: user.id});
            if(res.publications) {
                this.setState({publications: res.publications});
            }
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        if(form.mensaje.value.length > 0 && form.mensaje.value !== '' && !(/^\s+$/.test(form.mensaje.value))) {
            socket.emit('chat', { 
                id: this.state.userData.id, 
                mensaje: form.mensaje.value,
                room: this.state.room,
                tipo: this.state.userData.tipo,
                visto: 0
            });
            form.mensaje.value = '';
        }
    }

    sendImage() {
        const upload = document.createElement('input');
        upload.type = 'file';
        upload.click();
        upload.onchange = this.uploadImage.bind(this, upload);
    }

    uploadImage(event) {
        let resizedImage = "";
        const src = event;
        let fr = new FileReader();
        fr.onload = function(e) {
            const image = new Image();
            image.onload = function() {
                const canvas = document.createElement("canvas");
                const max_size = 600;
                let width = image.width;
                let height = image.height;
                if (width > height) {
                    if (width > max_size) {
                        height *= max_size / width;
                        width = max_size;
                    }
                } else {
                    if (height > max_size) {
                        width *= max_size / height;
                        height = max_size;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext("2d").drawImage(image, 0, 0, width, height);
                resizedImage = canvas.toDataURL("image/png");
                this.uploadImageFirebase(resizedImage);
            }.bind(this)
            image.src = e.target.result;
        }.bind(this)
        if (src.files.length > 0) {
            fr.readAsDataURL(src.files[0]);
        }
    }

    async uploadImageFirebase(image) {
        const storageRef = window.firebase.storage().ref();
        let fileName = `${this.state.room}/${moment().format('YYYYMMDD_hhmmss')}`;
        const ref = storageRef.child(`chat/${fileName}`);
        const imageURL = await ref.putString(image, 'data_url').then(async snapshot => {
            const url = await snapshot.ref.getDownloadURL().then(downloadURL => {
                   return downloadURL;
                }
            );
            return url
        });
        socket.emit('chat', {
            id:this.state.userData.id,
            tipo: this.state.userData.tipo,
            mensaje: imageURL,
            imagen: 1,
            visto: 0,
            room: this.state.room
        });
    }

    pagar() {
        const modal = document.getElementById('modal-paychat');
        if(modal) {
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
        }
    }

    openBuscar() {
        const head = document.getElementById('chat-search');
        if(head){
            head.classList.toggle("hidden");
        }
    }

    buscar(event) {
        event.preventDefault();
        var name = event.target.mensaje.value;
        var pattern = name.toLowerCase();
        var targetId = "";
      
        var divs = document.getElementsByClassName("message");
        for (var i = 0; i < divs.length; i++) {
           var para = divs[i].getElementsByTagName("p");
           var index = para[0].innerText.toLowerCase().indexOf(pattern);
           if (index != -1) {
              targetId = divs[i].id;
              console.log(divs[i]);
              document.getElementById(targetId).scrollIntoView();
              break;
           }
        }
        const head = document.getElementById('chat-search');
        if(head){
            head.classList.toggle("hidden");
        }
     }

    render() {
        var objDiv = document.getElementById("chat_messages");
        if(objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
        }


        return(
            <div className="chat column ">
                <Helmet>
                    <title>WAALA - Conversación</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Conversación" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                <div className="view-web column">
                <HeaderWebHomeLoggedIn/>
                    <div className="row justify-center">
                        <LeftBar active = "menuChats"/>
                        <div className="column align-center main-chat-column">
                        <div className="white-space-32"></div>
                        <div className="row container">
                        <div className="content-chat-list">
                        <Chats origen="chat"></Chats>
                        </div>
                        <div className="column chat-column chat-content">
                        <HeaderChat location={this.props.location} {...this.state} {...this.props.context} pagar={this.pagar.bind(this)} openBuscar={this.openBuscar.bind(this)}/>
                        <HeaderChatSearch buscar={this.buscar.bind(this)}/>
                        <div className="justify-center">
                            <div id="chat_messages_content" className="container content-chat" style={{overflowY: 'auto'}}>
                                {
                                    this.state.loading ?
                                    (
                                        <div className="column">
                                            <div className="white-space-16"></div>
                                            <div className="justify-center">
                                                <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        <Messages 
                                            messages={this.state.messages}
                                            user={this.state.userData} />
                                    )
                                }
                            </div>
                        </div>
                        <BottomControls submit={this.handleSubmit.bind(this)} sendImage={this.sendImage.bind(this)}/>
                        </div>
                      
                        </div>
                        <div className="white-space-32"></div>
                        </div>
                    </div>
                </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                <HeaderChat location={this.props.location} {...this.state} {...this.props.context} pagar={this.pagar.bind(this)} openBuscar={this.openBuscar.bind(this)}/>
                <HeaderChatSearch buscar={this.buscar.bind(this)}/>
                <div className="justify-center">
                    <div id="chat_messages_content" className="container content-chat" style={{overflowY: 'auto'}}>
                        {
                            this.state.loading ?
                            (
                                <div className="column">
                                    <div className="white-space-16"></div>
                                    <div className="justify-center">
                                        <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                    </div>
                                </div>
                            )
                            :
                            (
                                <Messages 
                                    messages={this.state.messages}
                                    user={this.state.userData} />
                            )
                        }
                    </div>
                </div>
                <BottomControls submit={this.handleSubmit.bind(this)} sendImage={this.sendImage.bind(this)}/>
                </MediaQuery>
                <PayChat room={this.state.room} publications={this.state.publications} recibe={this.state.recibe} idPubSel={this.state.idPubSel} location={this.props.location}/>
            </div>
        );
    }

    componentDidMount() {
        window.localStorage.removeItem('waala_location_chat')
        this.load();
        let aviso = true;
        socket.on('loadInfo', (res) => {
            // console.log(res);
            const match = this.props.match;
            if(match) {
                if(match.params.status) {
                    if(match.params.status === 'aprovado') {
                        socket.emit('chat', {
                            tipo: 0,
                            mensaje: `pago ${match.params.status}: ${match.params.code}`,
                            visto: 1,
                            room: res.room
                        });
                    } else {
                        res.chat.push({tipo: 0, mensaje: `pago ${match.params.status}: ${match.params.code}`});
                    }
                }
            }
            let PubSelect = window.localStorage.getItem('waalapub');
            if(PubSelect && aviso) {
                PubSelect = PubSelect.split(';');
                const pub = PubSelect[1];
                res.chat.push({tipo:0, mensaje: `Publicacion: ${pub}`});
                aviso = false;
            }
            // console.log(res);
            this.setState({recibe: res.data, room: res.room, messages: res.chat});
        });
        socket.on('chat', message => {
            const messages = this.state.messages;
            // console.log(messages);
            messages.push(message)
            this.setState({messages});
            //avisar si selecciono una publicacion
            let PubSelect = window.localStorage.getItem('waalapub');
            if(PubSelect) {
                socket.emit('avisoChat', {PubSelect, room: this.state.room, tipo: 2})
            }
        });

        socket.on('avisoChat', data => {
            // console.log(data);
            this.setState({idPubSel: data.id_pub})
            if(aviso) {
                const messages = this.state.messages;
                messages.push({mensaje: `publicacion: ${data.publicacion}`, tipo: 0});
                this.setState({messages});
            }
            aviso = false;
        });
    }
}

export default Consumer(Chat);
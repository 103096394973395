/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath
 *  @description: Boton modal Login HeaderWebLogin
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class HeaderButtonRegister extends Component {

    
    handleWebLogin() {
        let modalLogin = document.getElementById('modal-register');
        modalLogin.classList.remove('fadeOut');
        modalLogin.classList.add('animated', 'fadeIn','faster');
        modalLogin.classList.remove('hidden');    
    }

    render() {
        return (
            <div className="btn-container auto btn-splash justify-center">
                <a href="#" className="btn-web btn-border-white weight-bold text-center color-white" onClick = { this.handleWebLogin.bind(this) }>
                    Crear cuenta
                </a>
            </div>
        );
    }
}

export default withRouter(HeaderButtonRegister);
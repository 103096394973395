/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath
 *  @description: Boton agregar una imagen HeaderWebHomeLoggedIn
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class HeaderButtonAdd extends Component {

    handleWebAdd() {
        let modalAdd = document.getElementById('modal-Add');
        modalAdd.classList.remove('fadeOut');
        modalAdd.classList.add('animated', 'fadeIn','faster');
        modalAdd.classList.remove('hidden');    
    }

    render() {
        return (
            <div className="column column-button-header">
                <div className="row justify-end">
                    <div className="btn-container auto btn-splash justify-center">
                        <a className="btn-web btn-purple font-small text-center color-white weight-bold" onClick = { this.handleWebAdd.bind(this) } >
                            <i className="fas fa-plus-circle icon-add"></i>Añadir una imagen
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(HeaderButtonAdd);
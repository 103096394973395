/**
 *  suscription.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: vista de suscripcion
*/

import React, { Component } from 'react';
import { Consumer } from "../context";
import { Helmet } from 'react-helmet';
import { HeaderBack, HeaderWebHomeLoggedIn } from '../components/header/header';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import Request from '../core/httpClient';
import moment from 'moment-timezone';
import ModalConfirm from '../components/modals/finishpay';
import ModalNoPublished from '../components/modals/nopublished';
import LeftBar from '../components/left-bar/left-bar';
import LeftBarSetting from '../components/left-bar/left-settings-bar';

const req = new Request();

class Payments extends Component {
    constructor(props) {
        super(props);
        this.state = { pagos: [], pagos2: [] }
    }

    componentWillUnmount() {
        this.props.context.setPrevLocation(this.props.location);
        this.mounted = false;
    }

    endPay(pago) {
        this.setState({ pago });
        const modal = document.getElementById('modal-pay');
        if (modal) {
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
        }
    }

    noPublicado(pago){
        this.setState({pago});
        const modal = document.getElementById('modal-no-publish');
        if (modal) {
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
        }
    }

    async confirm() {
        const data = { pago: this.state.pago, tipo: this.state.userData.tipo };
        const res = await req.post('/publications/confirm', data);
        if (res.confirm) {
            let modal = document.getElementById('modal-pay');
            modal.classList.add('fadeOut','faster');
            setTimeout(() => {
                modal.classList.add('hidden');
                this.load();
            }, 500);
        }
    }

    filterPayments(event) {
        let pagos = this.state.pagos2;
        if(pagos) {
            pagos = pagos.filter(pago => pago.estado.indexOf(event.target.value) !== -1);
            this.setState({pagos});
        }
    }

    render() {
        let tipo = 0;
        if (this.state.userData) { tipo = this.state.userData.tipo; }
        return (
            <div className="suscription settings column">
                <Helmet>
                    <title>WAALA - Gestión de pagos</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Suscripción" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <HeaderWebHomeLoggedIn/>
                        <div className="row">
                            <LeftBar active = "menuSettings"/>
                            <div className="column align-center">
                                <div className="white-space-32"></div>
                                <div className="row-responsive container">
                                    <LeftBarSetting link="/ajustes/perfil" option = "4"/>
                                        <div className="column">
                                            <div className="column"> 
                                                <div className="row">
                                                    <div className="column right-main content-forms align-center">
                                                        <h4>
                                                            Gestión de pagos
                                                        </h4>
                                                        <div className="white-space-8"></div>
                                                        {
                                                            tipo === 1 ?
                                                            (
                                                                <div className="column">
                                                                    <p className="text-justify font-mini">                                                            
                                                                        Confirma que has realizado la publicación para poder liberar tu pago.
                                                                    </p>
                                                                    <p className="text-justify font-mini">
                                                                        Realizaremos una transferencia a tu cuenta el día 10 de cada mes, por valor de las colaboraciones realizadas durante el mes anterior.
                                                                    </p>
                                                                </div>
                                                            ) :
                                                            (
                                                                <div className="column">
                                                                    <p className="font-mini text-justify">
                                                                        <i className="fas fa-circle font-mini color-darkgray"></i> Confirma que el influencer ha realizado la publicación para liberar el pago.
                                                                    </p>
                                                                    <p className="font-mini text-justify">
                                                                        <i className="fas fa-circle font-mini color-darkgray"></i> En caso de que no haya cumplido con las condiciones, selecciona la opción “Incompleta”, y te devolveremos tu dinero.
                                                                    </p>
                                                                    <p className="font-mini text-justify">
                                                                        <i className="fas fa-circle font-mini color-darkgray"></i> Dispones de un plazo de 12 horas para seleccionar alguna de las opciones. Pasado ese tiempo, la publicación se considerará como realizada, y efectuaremos el pago al influencer.
                                                                    </p>
                                                                </div>
                                                            )

                                                        }
                                                        <div className="white-space-16"></div>
                                                        <div className="column align-center">
                                                            <div>
                                                                <div className="row">
                                                                    <div className="align-center">
                                                                        <h3>
                                                                            Estado: &nbsp;
                                                                        </h3>
                                                                    </div>
                                                                    <div className="justify-end">
                                                                        <select className="input" id="filtro_status" onChange={this.filterPayments.bind(this)}>
                                                                            <option value="">TODOS</option>
                                                                            <option value="CONFIRMADO">CONFIRMADO</option>
                                                                            <option value="PAGADO">PAGADO</option>
                                                                            <option value="PUBLICADO">PUBLICADO</option>
                                                                            <option value="INCOMPLETO">INCOMPLETO</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="column">
                                                        {
                                                            this.state.pagos.map((pago, index) => {
                                                                const oDate = new Date(moment.tz(pago.fecha, 'America/Cancun').format());
                                                                const fecha = moment(oDate).format('DD/MM/YYYY');
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <div className="row payment-list">
                                                                            <div className="column image-column justify-start align-start">
                                                                                <div className="image column align-center justify-center">
                                                                                    <div className="responsive-img justify-center align-center">
                                                                                        <img src = { pago.imagen } alt="Imagen de publicacion" title="Imagen de publicacion" className="cover-img" width="100" height="100" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="details-list column justify-center align-start">
                                                                                <div className="row">
                                                                                    <h5>
                                                                                        {/*Folio: {pago.folio}*/}
                                                                                    </h5>
                                                                                </div>
                                                                                <div className="row justify-end">
                                                                                    <div className="column">
                                                                                        <h4>
                                                                                            { pago.estado }
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row justify-end">
                                                                                    <div className="column">
                                                                                        <h4>
                                                                                            €{ pago.monto }
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="column justify-center align-start">
                                                                                    <div className="white-space-8"></div>
                                                                                    <h5 className="color-purple">
                                                                                        { fecha }
                                                                                    </h5>
                                                                                    <div className="white-space-8"></div>
                                                                                </div>
                                                                                <div>
                                                                                    {(tipo === 1 && pago.estado === 'PAGADO') || (tipo ===2 && pago.estado === 'PUBLICADO')?
                                                                                        <button onClick={this.endPay.bind(this, pago)} className="btn btn-square btn-purple color-white weight-semi btn-payments">
                                                                                            CONFIRMAR
                                                                                        </button>
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    {(tipo ===2 && pago.estado === 'PUBLICADO')?
                                                                                        <button onClick={this.noPublicado.bind(this, pago)} className="btn btn-square btn-pink color-white weight-semi btn-payments">
                                                                                            INCOMPLETA
                                                                                        </button>
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        )}
                                                    </div>
                                                    </div>
                                                </div>
                                            <div className="white-space-16"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                    <HeaderBack back = "/ajustes" />
                    <div className="justify-center">
                        <div className="container column">
                            <div className="white-space-32"></div>
                            <h2 className="text-center wow fadeIn">
                                Gestión de pagos
                            </h2>
                            <div className="white-space-8"></div>
                            {
                                tipo === 1 ?
                                (
                                    <div className="column">
                                        <p className="text-justify font-mini">                                                            
                                            Confirma que has realizado la publicación para poder liberar tu pago.
                                        </p>
                                        <p className="text-justify font-mini">
                                            Realizaremos una transferencia a tu cuenta el día 10 de cada mes, por valor de las colaboraciones realizadas durante el mes anterior.
                                        </p>
                                    </div>
                                ) :
                                (
                                    <div className="column">
                                        <p className="font-mini text-justify">
                                            <i className="fas fa-circle font-mini color-darkgray"></i>&nbsp;Confirma que el influencer ha realizado la publicación para liberar el pago.
                                        </p>
                                        <p className="font-mini text-justify">
                                            <i className="fas fa-circle font-mini color-darkgray"></i>&nbsp;En caso de que no haya cumplido con las condiciones, selecciona la opción “Incompleta”, y te devolveremos tu dinero.
                                        </p>
                                        <p className="font-mini text-justify">
                                            <i className="fas fa-circle font-mini color-darkgray"></i>&nbsp;  Dispones de un plazo de 12 horas para seleccionar alguna de las opciones. Pasado ese tiempo, la publicación se considerará como realizada, y efectuaremos el pago al influencer.
                                        </p>
                                    </div>
                                )

                            }
                            <div className="white-space-16"></div>
                            <div className="column align-center">
                                <div>
                                    <div className="row">
                                        <div className="align-center">
                                            <h3>
                                                Estado: &nbsp;
                                            </h3>
                                        </div>
                                        <div className="justify-end">
                                            <select className="input" id="filtro_status" onChange={this.filterPayments.bind(this)}>
                                                <option value="">TODOS</option>
                                                <option value="CONFIRMADO">CONFIRMADO</option>
                                                <option value="PAGADO">PAGADO</option>
                                                <option value="PUBLICADO">PUBLICADO</option>
                                                <option value="INCOMPLETO">INCOMPLETO</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="column">
                                {
                                    this.state.pagos.map((pago, index) => {
                                        const oDate = new Date(moment.tz(pago.fecha, 'America/Cancun').format());
                                        const fecha = moment(oDate).format('DD/MM/YYYY');
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="row payment-list">
                                                    <div className="column image-column justify-start align-start">
                                                        <div className="image column align-center justify-center">
                                                            <div className="responsive-img justify-center align-center">
                                                                <img src={pago.imagen} alt="Imagen de publicacion" title="Imagen de publicacion" className="cover-img" width="100" height="100" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="details-list column justify-center align-start">
                                                        <div className="row">
                                                            <h5>
                                                                {/*Folio: {pago.folio}*/}
                                                            </h5>
                                                        </div>
                                                        <div className="row justify-end">
                                                            <div className="column">
                                                                <h4>
                                                                    { pago.estado }
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-end">
                                                            <div className="column">
                                                                <h4>
                                                                    €{ pago.monto }
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div className="column justify-center align-start">
                                                            <div className="white-space-8"></div>
                                                            <h5 className="color-purple">
                                                                { fecha }
                                                            </h5>
                                                            <div className="white-space-8"></div>
                                                        </div>
                                                        <div>
                                                            {(tipo === 1 && pago.estado === 'PAGADO') || (tipo ===2 && pago.estado === 'PUBLICADO')?
                                                                <button onClick={this.endPay.bind(this, pago)} className="btn btn-square btn-purple color-white weight-semi btn-payments">
                                                                    CONFIRMAR
                                                                </button>
                                                                : null
                                                            }
                                                        </div>
                                                        <div>
                                                            {(tipo ===2 && pago.estado === 'PUBLICADO')?
                                                                <button onClick={this.noPublicado.bind(this, pago)} className="btn btn-square btn-pink color-white weight-semi btn-payments">
                                                                    INCOMPLETA
                                                                </button>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <ModalConfirm confirm = { this.confirm.bind(this) } />
                <ModalNoPublished nopublished = { this.nopublished.bind(this) }/>
            </div>
        );
    }

    componentDidMount() {
        this.load()
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({ userData: user.id });
            const data = { user: this.state.userData };
            const res = await req.post('/publications/payments', data);
            if (res.pagos) { this.setState({pagos: res.pagos, pagos2: res.pagos}); }
        }
    }

    async nopublished() {
        const data = {
            pago: this.state.pago,
            tipo: this.state.userData.tipo
        };
        const res = await req.post('/publications/nopublished', data);
        if (res.confirm) {
            let modal = document.getElementById('modal-no-publish');
            modal.classList.add('fadeOut','faster');
            setTimeout(() => {
                modal.classList.add('hidden');
                this.load();
            }, 500);
        }
    }
}

export default withRouter(Consumer(Payments));
/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath, Sergio
 *  @description: vista de ajustes
*/

import React, { Component } from 'react';
import BottomBar from '../components/bottom-bar/bottombar';
import { Link } from 'react-router-dom';
import Header from '../components/header/header';
import { Consumer } from "../context";
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';
import ModalDeleteAccount from '../components/modals/deleteAccount';
import Contact from '../components/modals/contact';

class Settings extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            userData: {id: 0, tipo: 0}
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if(user) {
            this.setState({userData: user.id});
        }
    }

    handleWebAdd() {
        let modalAdd = document.getElementById('modal-Add');
        modalAdd.classList.remove('fadeOut');
        modalAdd.classList.add('animated', 'fadeIn','faster');
        modalAdd.classList.remove('hidden');
    }

    render() {

        let tipo = "0", back = '';
        if (this.state.userData.tipo) {
            tipo = this.state.userData.tipo.toString();
        }
        if (this.state.userData.tipo) { this.state.userData.tipo === 1 ? back = '/perfil/influencer' : back = '/perfil/marca' }

        return(
            <div className="settings column">
                <Helmet>
                    <title>WAALA - Ajustes</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Ajustes" />
                </Helmet>
                <MediaQuery maxWidth = { 768 }>
                <Header back="/inicio" showBack = { true } tipo = { tipo } />
                    <div className="justify-center">
                        <div className="container column">  
                            <div className="white-space-16"></div>
                            <div className="row justify-center align-center">
                                <h5 className="weight-bold">
                                    Cuenta
                                </h5>
                            </div>
                            <div className="white-space-16"></div>
                            <form className="column">
                                <div className="basic-data column justify-center align-center">
                                <div className="input-container align-center justify-center">
                                        <i className="fas fa-user-circle font-small color-pink"></i>
                                        {
                                            tipo === "1" ? (<Link to="ajustes/perfil" className="input input-profile-noline">Editar Perfil</Link>) :
                                            (<Link to="ajustes/perfil/marca" className="input input-profile-noline">Editar Perfil</Link>)
                                        }
                                        
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="basic-data column justify-center align-center">
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-lock font-small color-pink"></i>
                                        <Link to="ajustes/perfil/password" className="input input-profile-noline">Contraseña</Link>
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                {this.state.userData.tipo.toString() === "1"?
                                <React.Fragment>
                                    <div className="basic-data column justify-center align-center">
                                        <div className="input-container align-center justify-center">
                                            <i className="fas fa-money-check font-small color-pink"></i>
                                            <Link to="ajustes/bancario" className="input input-profile-noline">Datos Bancarios</Link>
                                        </div>
                                    </div>
                                    <div className="white-space-8"></div>
                                </React.Fragment>
                                :null
                                }
                                <div className="basic-data column justify-center align-center">
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-receipt font-small color-pink"></i>
                                        <Link to="ajustes/pagos" className="input input-profile-noline">Gestión de pagos</Link>
                                    </div>
                                </div>

                                <div className="white-space-16"></div>
                                <div className="row justify-center align-center">
                                    <h5 className="weight-bold">
                                        Seguridad y Privacidad
                                    </h5>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="basic-data column justify-center align-center">                                
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-folder-open font-small color-pink"></i>
                                        <Link to="/ajustes/bloqueos" className="input input-profile">Cuentas bloqueadas</Link>
                                    </div>
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-shield-alt font-small color-pink"></i>
                                        <div type="button" className="input input-profile-noline" onClick = { this.handleContact.bind(this) }>
                                            Enviar comentarios o reportar
                                        </div>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="row justify-center align-center">
                                    <h5 className="weight-bold">Información</h5>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="basic-data column justify-center align-center">
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-file-alt font-small color-pink"></i>
                                        <Link to="/files/politica-de-privacidad.pdf" target="_blank" className="input input-profile">Política de privacidad</Link>
                                    </div>
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-file-alt font-small color-pink"></i>
                                        <Link to="/files/terminos-y-condiciones.pdf" target="_blank" className="input input-profile">Términos de uso</Link>
                                    </div>
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-file-alt font-small color-pink"></i>
                                        <Link to="/files/politica-de-cookies.pdf" target="_blank" className="input input-profile-noline">Política de Cookies</Link>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="basic-data column justify-center align-center">
                                    <div className="input-container align-center justify-center" onClick={this.deleteAccount.bind(this)}>
                                        <i className="fas fa-user-times font-small color-pink"></i>
                                        <a className="input input-profile-noline">Eliminar Cuenta</a>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="row justify-center align-center">
                                    <p className="font-tiny weight-bold color-pink cursor-pointer" onClick={ this.handleLogOut.bind(this.props, this) } >
                                        CERRAR SESIÓN
                                    </p>
                                </div>
                            </form>
                         </div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="white-space-64"></div>
                    <BottomBar active = "bottomProfile" />
                </MediaQuery>
                <Contact user={this.state.userData}/>
                <ModalDeleteAccount userData = { this.state.userData } context = { this.props.context } />
            </div>
        );
    }

    handleLogOut(contextValue) {
        contextValue.props.context.logOut();
        window.location = "/login";
    }

    deleteAccount() {
        const modal = document.getElementById('modal-delete-account');
        if (modal) {
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
        }
    }

    handleContact() {
        const modal = document.getElementById('modal-contact');
        if (modal) {
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
        }
    }

}

export default Consumer(Settings);
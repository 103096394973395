/**
 *  httpClient.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Cliente HTTP para todas las peticiones Web
 */

import request from "superagent";
import { URL_API } from './urlsApi';

class Request {
  get(url, data) {
    const result = request
      .get( URL_API + url )
      .query(data)
      .set({'waala_auth': '5916c7f4a8d8dcbc85abb7196a3627fa'})
      .then(res => {
        return res.body;
      })
      .catch(err => {
        console.log(err.message);
        return { error: true, message: err.message };
      });
    return result;
  }

  post(url, data) {
    const result = request
      .post( URL_API + url )
      .set({'waala_auth': '5916c7f4a8d8dcbc85abb7196a3627fa'})
      .send(data)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        console.log(err.message);
        return { error: true, message: err.message };
      });
    return result;
  }
}

export default Request;

export function prevLocation(location) {
	window.localStorage.removeItem("waala_location_profile");
	window.localStorage.setItem('waala_location', JSON.stringify(location));
	this.setState({prevLocation: location})
}

export function loadLocation() {
	let location = window.localStorage.getItem("waala_location");
	if(location) {
		location = JSON.parse(location);
		this.setState({prevLocation: location})
	}
}

export function prevLocationProfile(location) {
	window.localStorage.setItem('waala_location_profile', JSON.stringify(location));
	this.setState({prevLocationProfile: location})
}

export function loadLocationProfile() {
	let location = window.localStorage.getItem("waala_location_profile");
	if(location) {
		location = JSON.parse(location);
		this.setState({prevLocationProfile: location});
		return location;
	}
}
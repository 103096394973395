/**
 *  register-brands.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de registro de marcas
*/

import React, { Component } from 'react';
import { HeaderSingle, HeaderWebLogin } from '../components/header/header';
import { Link, withRouter } from 'react-router-dom';
import Request from '../core/httpClient';
import { Consumer } from '../context';
import WOW from 'wowjs';
import { Helmet } from 'react-helmet';
import MediaQuery from 'react-responsive';
import InfluencersImage from '../img/splash.jpg';

const req = new Request();

class RegisterBrands extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectores: [], sector: '', user: {id: 0, tipo: 0}
        };        
    }
    
    async hanldeSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true, message: "" });
        const form = event.target;
        const data = {
            Nombre: form.brand.value, Email: form.email.value, Telefono: '',
            Genero: '', Ciudad: form.city.value, Password: form.password.value, Tipo: 2,
            IdSector: form.sector.value, Sector: form.sector[form.sector.selectedIndex].innerText
        };
        this.props.context.saveRegister(data);
        this.props.history.push('/intereses');
      }

    render() {
        return (
            <div className="register column">
                <Helmet>
                    <title>WAALA - Registro</title>
                    <meta property="og:title" content="WAALA - Registro" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column" style={{ backgroundImage: `url(${InfluencersImage })`}}>
                        <div className="overlay justify-center column align-center">
                            <HeaderWebLogin />
                            <div className="container column align-center justify-center">
                                <div className="white-space-64"></div>
                                <div className="white-space-64"></div>
                                <div className="container-card auto">
                                    <div className="row justify-end align-center">
                                        <div className="content card-splash-influencer auto column justify-center align-center">
                                            <i className="fas fa-user fa-2x color-white"></i>
                                            <div className="white-space-8"></div>
                                            <h1 className="color-white text-tittle-card">
                                                SOY INFLUENCER
                                            </h1>
                                            <div className="overlay-card column">
                                                <h4 className="text-cards">Gana dinero promocionando fotos</h4>
                                                <h4 className="text-cards">de tus marcas favoritas.</h4>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="row justify-start align-center">
                                        <div className="content card-splash-marca auto column justify-center align-center">
                                            <i className="fas fa-tag fa-2x color-white"></i>
                                            <div className="white-space-8"></div>
                                            <h1 className="color-white text-tittle-card">
                                                SOY MARCA
                                            </h1>
                                            <div className="overlay-card2 column">
                                                <h4 className="text-cards">Busca personas que proporcionen tus</h4>
                                                <h4 className="text-cards">productos en sus redes sociales.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="more align-center">
                                    <div className="justify-center">
                                        <p className="color-white weight-semi text-center font-regular">
                                            Así funciona waala
                                        </p>
                                    </div>
                                    <div className="auto">
                                        <i className="fas fa-chevron-down color-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="modal justify-center modal-register-brand" id="modal-register">
                            <div className="container modal-content align-center column" >
                                <div className="white-space-48"></div>
                                <h2 className="text-center">
                                    Crea una cuenta como marca
                                </h2>
                                <Link to="/" className="btn auto" id="close-modal-register">
                                    <i className="fas fa-times"></i>
                                </Link>
                                <div className="white-space-16"></div>
                                <form className="column" id="desktop-form" onSubmit = { this.hanldeSubmit.bind(this) }>
                                    <div className="input-container align-center wow fadeInUp faster">
                                        <i className="fas fa-ad font-small text-center"></i>
                                        <input type="text" name="brand" placeholder="Marca" className="input" required/>
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center wow fadeInUp faster">
                                        <i className="fas fa-at font-small text-center"></i>
                                        <input type="email" name="email" placeholder="Correo electrónico" className="input" required/>
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center wow fadeInUp faster">
                                        <i className="fas fa-pencil-ruler font-small text-center"></i>
                                        <select name="sector" className="input input-select" required>
                                            <option value="">Sector</option>
                                            {
                                                this.state.sectores.map((arr, index) => <option key={index} value={arr.id_sector}>{arr.sector}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center wow fadeInUp faster">
                                        <i className="fas fa-map-marked-alt font-small text-center"></i>
                                        <select name="city" className="input input-select" required>
                                        <option value="A Coruña">A Coruña</option>
                                            <option value="Álava/Araba">Álava/Araba</option>
                                            <option value="Albacete">Albacete</option>
                                            <option value="Alicante/Alacant">Alicante/Alacant</option>
                                            <option value="Almería">Almería</option>
                                            <option value="Asturias">Asturias</option>
                                            <option value="Ávila">Ávila</option>
                                            <option value="Badajoz">Badajoz</option>
                                            <option value="Barcelona">Barcelona</option>
                                            <option value="Burgos">Burgos</option>
                                            <option value="Cáceres">Cáceres</option>
                                            <option value="Cádiz">Cádiz</option>
                                            <option value="Cantabria">Cantabria</option>
                                            <option value="Castellón/Castelló">Castellón/Castelló</option>
                                            <option value="Ceuta">Ceuta</option>
                                            <option value="Ciudad Real">Ciudad Real</option>
                                            <option value="Córdoba">Córdoba</option>
                                            <option value="Cuenca">Cuenca</option>
                                            <option value="Girona">Girona</option>
                                            <option value="Granada">Granada</option>
                                            <option value="Guadalajara">Guadalajara</option>
                                            <option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</option>
                                            <option value="Huelva">Huelva</option>
                                            <option value="Huesca">Huesca</option>
                                            <option value="Illes Balears">Illes Balears</option>
                                            <option value="Jaén">Jaén</option>
                                            <option value="La Rioja">La Rioja</option>
                                            <option value="Las Palmas">Las Palmas</option>
                                            <option value="León">León</option>
                                            <option value="Lleida">Lleida</option>
                                            <option value="Lugo">Lugo</option>
                                            <option value="Madrid">Madrid</option>
                                            <option value="Málaga">Málaga</option>
                                            <option value="Melilla">Melilla</option>
                                            <option value="Murcia">Murcia</option>
                                            <option value="Navarra">Navarra</option>
                                            <option value="Ourense">Ourense</option>
                                            <option value="Palencia">Palencia</option>
                                            <option value="Pontevedra">Pontevedra</option>
                                            <option value="Salamanca">Salamanca</option>
                                            <option value="Santa Cruz de Tenerife">Santa Cruz de Tenerife</option>
                                            <option value="Segovia">Segovia</option>
                                            <option value="Sevilla">Sevilla</option>
                                            <option value="Soria">Soria</option>
                                            <option value="Tarragona">Tarragona</option>
                                            <option value="Teruel">Teruel</option>
                                            <option value="Toledo">Toledo</option>
                                            <option value="Valencia/València">Valencia/València</option>
                                            <option value="Valladolid">Valladolid</option>
                                            <option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</option>
                                            <option value="Zamora">Zamora</option>
                                            <option value="Zaragoza">Zaragoza</option>
                                        </select>
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center wow fadeInUp faster">
                                        <i className="fas fa-key font-small text-center"></i>
                                        <input type="password" name="password" placeholder="Contraseña" className="input" minLength="8" maxLength="16" required />
                                    </div>
                                    <div className="white-space-24"></div>
                                    <div className="btn-container justify-center wow fadeInUp faster">
                                        <button type="submit" className="btn btn-pink weight-semi font-regular color-white">
                                            CREAR CUENTA
                                        </button>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="justify-center">
                                        <Link to = "/files/terminos-y-condiciones.pdf" target="_blank" className="text-center font-mini wow fadeInUp faster">
                                            Al registrarte, aceptas los Términos y Condiciones de Uso de Waala.
                                        </Link>
                                    </div>
                                </form>
                                <div className="white-space-48"></div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>

                <MediaQuery maxWidth = { 768 } >
                    <HeaderSingle />
                    <div className="white-space-32"></div>
                    <div className="justify-center">
                        <div className="container column forms">
                            <h2 className="text-center" id="h2-register-brand">
                                Crea una cuenta como marca
                            </h2>
                            <div className="white-space-32"></div>
                            <form className="column" id="mobile-form" onSubmit = { this.hanldeSubmit.bind(this) }>
                                <div className="input-container align-center wow fadeInUp faster">
                                    <i className="fas fa-ad font-small text-center"></i>
                                    <input type="text" name="brand" placeholder="Marca" className="input" required/>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="input-container align-center wow fadeInUp faster">
                                    <i className="fas fa-at font-small text-center"></i>
                                    <input type="email" name="email" placeholder="Correo electrónico" className="input" required/>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="input-container align-center wow fadeInUp faster">
                                    <i className="fas fa-pencil-ruler font-small text-center"></i>
                                    <select name="sector" className="input input-select" required>
                                        <option value="">Sector</option>
                                        {
                                            this.state.sectores.map((arr, index) => <option key={index} value={arr.id_sector}>{arr.sector}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="input-container align-center wow fadeInUp faster">
                                    <i className="fas fa-map-marked-alt font-small text-center"></i>
                                    <select name="city" className="input input-select" required>
                                    <option value="A Coruña">A Coruña</option>
                                        <option value="Álava/Araba">Álava/Araba</option>
                                        <option value="Albacete">Albacete</option>
                                        <option value="Alicante/Alacant">Alicante/Alacant</option>
                                        <option value="Almería">Almería</option>
                                        <option value="Asturias">Asturias</option>
                                        <option value="Ávila">Ávila</option>
                                        <option value="Badajoz">Badajoz</option>
                                        <option value="Barcelona">Barcelona</option>
                                        <option value="Burgos">Burgos</option>
                                        <option value="Cáceres">Cáceres</option>
                                        <option value="Cádiz">Cádiz</option>
                                        <option value="Cantabria">Cantabria</option>
                                        <option value="Castellón/Castelló">Castellón/Castelló</option>
                                        <option value="Ceuta">Ceuta</option>
                                        <option value="Ciudad Real">Ciudad Real</option>
                                        <option value="Córdoba">Córdoba</option>
                                        <option value="Cuenca">Cuenca</option>
                                        <option value="Girona">Girona</option>
                                        <option value="Granada">Granada</option>
                                        <option value="Guadalajara">Guadalajara</option>
                                        <option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</option>
                                        <option value="Huelva">Huelva</option>
                                        <option value="Huesca">Huesca</option>
                                        <option value="Illes Balears">Illes Balears</option>
                                        <option value="Jaén">Jaén</option>
                                        <option value="La Rioja">La Rioja</option>
                                        <option value="Las Palmas">Las Palmas</option>
                                        <option value="León">León</option>
                                        <option value="Lleida">Lleida</option>
                                        <option value="Lugo">Lugo</option>
                                        <option value="Madrid">Madrid</option>
                                        <option value="Málaga">Málaga</option>
                                        <option value="Melilla">Melilla</option>
                                        <option value="Murcia">Murcia</option>
                                        <option value="Navarra">Navarra</option>
                                        <option value="Ourense">Ourense</option>
                                        <option value="Palencia">Palencia</option>
                                        <option value="Pontevedra">Pontevedra</option>
                                        <option value="Salamanca">Salamanca</option>
                                        <option value="Santa Cruz de Tenerife">Santa Cruz de Tenerife</option>
                                        <option value="Segovia">Segovia</option>
                                        <option value="Sevilla">Sevilla</option>
                                        <option value="Soria">Soria</option>
                                        <option value="Tarragona">Tarragona</option>
                                        <option value="Teruel">Teruel</option>
                                        <option value="Toledo">Toledo</option>
                                        <option value="Valencia/València">Valencia/València</option>
                                        <option value="Valladolid">Valladolid</option>
                                        <option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</option>
                                        <option value="Zamora">Zamora</option>
                                        <option value="Zaragoza">Zaragoza</option>
                                    </select>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="input-container align-center wow fadeInUp faster">
                                    <i className="fas fa-key font-small text-center"></i>
                                    <input type="password" name="password" placeholder="Contraseña" className="input" minLength="8" maxLength="16" required />
                                </div>
                                <div className="white-space-24"></div>
                                <div className="btn-container justify-center wow fadeInUp faster">
                                    <button type="submit" className="btn btn-pink weight-semi font-regular color-white">
                                        CREAR CUENTA
                                    </button>
                                </div>
                                <div className="white-space-16"></div>
                                <Link to = "/files/terminos-y-condiciones.pdf" target="_blank" className="text-center font-mini wow fadeInUp faster">
                                    Al registrarte, aceptas los Términos y Condiciones de Uso de Waala.
                                </Link>
                                <div className="white-space-8" />
                                    <div className="btn-container justify-center wow fadeInUp faster">
                                        <Link to="/registro" className="btn btn-purple color-white text-center" id="btn-marcas">
                                            REGISTRO PARA INFLUENCER
                                        </Link>
                                    </div>
                            </form>
                        </div>
                    </div>
                    <div className="white-space-32"></div>
                </MediaQuery>
            </div>
        )
    }

    componentDidMount() {
        new WOW.WOW({ live: false }).init();
        this.load();
    }    

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
			this.setState({user: user.id});
        	this.validateUser();
		}
        const sectores = await req.get('/sectores');
        if (!sectores.error) {
            this.setState({ sectores: sectores.sectores });
        }
    }

    validateUser() {
        if (this.state.user && this.state.user.id) { window.location = '/inicio'; }
    }    
}

export default withRouter(Consumer(RegisterBrands));
import React, { Component } from 'react';
import Request from 'superagent';
import Req from '../../core/httpClient';

const req = new Req();

class PayChat extends Component {
	constructor(props) {
		super(props);
		this.state={
			Ds_MerchantParameters: '',
			Ds_Signature: '',
			redsysUrl: 'https://sis-t.redsys.es:25443/sis/realizarPago'
		}
	}

	componentDidMount() {
		let modalNewsLetter = document.getElementById('modal-paychat');
		document.getElementById('close-modal-paychat').onclick = () => {
			modalNewsLetter.classList.add('fadeOut');
			setTimeout(() => {
				modalNewsLetter.classList.add('hidden');
			}, 500);
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.idPubSel !== prevProps.idPubSel) {
			if(this.props.idPubSel) {
				const select = document.getElementById('publications');
				if(select) {
					select.value = this.props.idPubSel;
				}
			}
		}
	}

	async handleSubmit(event) {
		event.preventDefault();
		const form = event.target;
		this.setState({loading: true});
		const res = await req.post('/chat/getfolio', {room: this.props.room, id_pub: form.publications.value, monto: form.amount.value});
		const monto = ((parseFloat(form.amount.value) * 1.10) * 100).toFixed(0);
		//console.log(monto);
		//console.log(res);
		if(res.folio) {
			//console.log(res.folio);
			let chat_url = '/chat/';
			if( this.props.location ) {
				chat_url = this.props.location.pathname || '/chat/';
			}
			//console.log(chat_url);
			const data = {
				amount: monto,
				order: res.folio,
				url: 'www.waalainfluencers.com',
				urlOK: process.env.NODE_ENV === 'production'? `https://waala.herokuapp.com/chat/responsePay`:`http://localhost:8081/chat/responsePay`,
				urlKO: process.env.NODE_ENV === 'production'? `https://waala.herokuapp.com/chat/responsePay`:`http://localhost:8081/chat/responsePay`,
				parametros: `${this.props.room};${this.props.recibe.usuario};${document.getElementById('publications').value};${chat_url}`
			   }
			//console.log(data);
			this.sendPay(data);
			this.setState({loading: false});
		} else {
			this.setState({message: res.message, loading: false});
			return;
		}
	}

	async sendPay(data) {
	   // console.log(data)
	   const postURL = process.env.NODE_ENV === 'production'? 'https://waalainfluencers.com/pay.php':'http://localhost/pay.php'
	   await Request.post(postURL).send(data).then(res => {
		   if(res.body) {
			   // console.log(res.body)
			   this.setState({
				   Ds_MerchantParameters: res.body.parametros,
				   Ds_Signature: res.body.llave,
				   redsysUrl: 'https://sis.redsys.es/sis/realizarPago'
			   });
			   const form = document.getElementById('formSbdll');
			   if(form) {
				   form.submit();
			   }
		   }
		   this.setState({loading: false});
	   })
	   .catch(err => {
		   console.log(err.message);
		   this.setState({message: err.message, loading: false});
	   });
	}

	calculateCostes(event) {
		const input = event.target;
		const com = document.getElementById('comision');
		if(com) {
			com.value = ((input.value) * 0.10).toFixed(2);
		}
		const tot = document.getElementById('total');
		if(tot) {
			tot.value = ((input.value) * 1.10).toFixed(2);
		}
	}

	render() {
		return (
			<div className="column">       
				<div className="modal justify-center hidden" id="modal-paychat">
					<div className="modal-content align-center column" >
						<button className="btn auto" id="close-modal-paychat">
							<i className="fas fa-times"></i>
						</button>
						<div className="white-space-32"></div>
						<div className="container column">
							<h3 className="text-center">
								Pagar publicación
							</h3>
							<div className="justify-center publication-grow">					
								<form className="column" id="datos_pago" onSubmit = { this.handleSubmit.bind(this) }>
									<div className="white-space-24"></div>		
									<p className="weight-semi">
										Publicación:
									</p>
									<div className="input-container content-options align-center justify-center">
										<i className="fas fa-caret-down font-text color-pink icon-triangle"/>
										<select name="publications" id="publications" className="input input-pay none-input" required>
											{
												this.props.publications.map((publication) => 
													<option key = { publication.id_publicacion } value = { publication.id_publicacion }>
														{ publication.titulo }
													</option>
												)
											}
										</select>
									</div>
									<div className="white-space-16"/>
									<p className="weight-semi">
										Importe a pagar:
									</p>
									<div className="input-container align-center justify-center">   
                                        <input type="number" name="amount" id="amount" placeholder="€ Cantidad a pagar" className="input input-pay" required onChange={this.calculateCostes.bind(this)}/>
                                    </div>
									<div className="white-space-16"/>
									<p className="weight-semi">
										Coste de gestión(10%):
									</p>
									<div className="input-container align-center justify-center">  
										<input type="number" name="comision" id="comision" placeholder="€ (10%)" readOnly className="input input-pay" required />
									</div>
									<div className="white-space-16"/>
									<p className="weight-semi">
										Total:
									</p>
									<div className="input-container align-center justify-center">  
										<input type="number" name="total" id="total" placeholder="€ Importe Total" readOnly className="input input-pay" required />
									</div>
									<div className="white-space-8"/>
									<div className="input-container align-center justify-center">  
										<input type="checkbox" name="policies" id="policies" required />
										<p className="color-darkgray">Acepto los <a href="https://waalainfluencers.com/files/terminos-y-condiciones.pdf" target="_blank">terminos y condiciones.</a></p>
									</div>
									<div className="white-space-48"/>
									<button className="btn-modal-pay btn-pink font-small text-center color-white weight-bold" id="btn-pay-button">
										<i className="far fa-credit-card color-white"></i>
										{
											this.state.loading ? <i className="fas fa-spinner fa-spin"></i> : " PAGAR"
										}
									</button>
									<div className="white-space-8"></div>
									<div className="justify-center">
										{ this.state.message }
									</div>
									<div className="white-space-8"></div>
								</form>
							</div>
							<form className="column" id="formSbdll" method="post" action={this.state.redsysUrl}>
								<input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
								<input type="hidden" name="Ds_MerchantParameters" value={this.state.Ds_MerchantParameters} />
								<input type="hidden" name="Ds_Signature" value={this.state.Ds_Signature} />
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default PayChat;
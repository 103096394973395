import React, { Component } from "react";

class FinishPay extends Component {
 
	componentDidMount() {
    	let modalNewsLetter = document.getElementById('modal-pay');
    	document.getElementById('close-modal-no').onclick = () => {
			modalNewsLetter.classList.add('fadeOut','faster');
			setTimeout(() => {
				modalNewsLetter.classList.add('hidden');
			}, 500);
    	}
	}	

	render() {
		const styles = { width: '50%' };
		return (    
			<div className="column">
				<div className="modal justify-center hidden" id="modal-pay">
					<div className="modal-content align-center column" >
						<div className="white-space-16"></div> 
						<div className="column">
							<h4 className="text-center">
								¿Confirmar Publicación?
							</h4>
							<div className="white-space-16"></div>
							<div className="modal-controlls">
								<button className="btn btn-square btn-pink color-white weight-semi" id="btn-deleteChat" onClick = { this.props.confirm.bind(this)} >
									SI
								</button>
								<button className="btn btn-square btn-purple color-white weight-semi" id="close-modal-no" style={styles}>
									NO
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FinishPay;
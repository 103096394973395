/**
 *  profile-influencer.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio , kath
 *  @description: Perfil publico del influencer
*/

import React, { Component } from 'react';
import Header, { HeaderWebHomeLoggedIn } from '../components/header/header';
import BottomBar from '../components/bottom-bar/bottombar';
import { Consumer } from "../context";
import { Link } from 'react-router-dom';
import Request from "../core/httpClient";
import HolderImage from '../img/user.png';
import ImageLoader from 'react-loading-image';
import LoaderImage from '../img/loader.gif';
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import ModalPublicationAdd from '../components/modals/publicationsAdd'; 
import ModalShowImage from '../components/modals/showImage';
import { Helmet } from 'react-helmet';

const req = new Request();

class PublicInfluencer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            influencer: '', user: {id: 0, tipo: 0}, favorito: false
        }
    }

    componentWillUnmount() {
        if(!window.localStorage.getItem('waala_location_chat')) {
            this.props.context.setPrevLocationProfile(this.props.location);
        }
        this.mounted = false;
    }

    async addFav() {
        this.setState({loadingFav: true});
        const data = {
            id_influencer: this.state.influencer.id_influencer,
            id_marca: this.state.user.id,
            tipo: this.state.user.tipo,
			favorito: !this.state.influencer.favorito,
			tipo_pub: this.state.influencer.tipo_pub
        }
        const res = await req.post('/favoritos/put', data);
        if(res.error) {
            console.log(res.message);
        } else {
            this.setState({favorito: !this.state.favorito});
        }
        this.setState({loadingFav: false});
    }    

    render() {
        let influencer = '', imagen = '', images = '', empty = '', interests = [];
        const user = this.state.user;
        const tipo = user.tipo ? user.tipo.toString() : '0'; 
        if (this.state.influencer) { 
            influencer = this.state.influencer; 
            if (influencer.intereses) { interests = JSON.parse(influencer.intereses); }
            if (influencer.foto_perfil_url) {
                imagen = influencer.foto_perfil_url; 
            } else {
                imagen = HolderImage; 
            }
        }
        if (this.state.images) { images = this.state.images }
        if (this.state.empty) { empty = this.state.empty }
        return(
            <div className="profile-influencer column">
                <Helmet>
                    <title>WAALA - Influencer</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Influencer" />
                </Helmet>
              <MediaQuery minDeviceWidth = { 768 }>
              <div className="view-web column">
              <HeaderWebHomeLoggedIn />
              <div className="row">
                <LeftBar/>
                <div className="column">
                    <div className="row justify-center">
                        <div className="container column align-center justify-center">
                            <div className="white-space-32"></div>
                            <div className="row header-profile">
                                <div className="column profile-column profile-public justify-center align-center">
                                    <div className="responsive-img justify-center align-center">
                                        <ImageLoader src = { imagen } alt="Imagen del influencer" title="Imagen del influencer" className="profile-image"
                                        loading={ () => <img className="profile-image loader-img" alt="Imagen del influencer" title="Imagen del influencer" src = { LoaderImage } /> } />
                                    </div>
                                </div>
                                <div className="column data-head justify-start align-center">
                                    <div className="data-head-top column">
                                        <div className="row"> 
                                            <h2 className="weight-semi">
                                                { influencer.nombre }
                                            </h2>
                                        </div>
                                        <div className="row">
                                            <p className="text-start text-space">
                                                @{ influencer.usuario }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="white-space-24"></div>
                                    <div className="row data-head-bottom">
                                        <div className="column column-located">
                                            <h4 className="font-small">Ciudad</h4>
                                            <p className="text-start">
                                                { influencer.ciudad }
                                            </p>
                                        </div>
                                        <div className="column column-followers">
                                            <h4 className="font-small text-center">Seguidores</h4>
                                            <p className="text-center">
                                                { influencer.seguidores }
                                            </p>
                                        </div>
                                        <div className="column column-price">
                                            <h4 className="font-small text-center">Tarifa de publicación</h4>
                                            <p className="text-center">
                                                € { influencer.tarifa }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="column content-btns align-center justify-center">
                                    <div className="btn-container btn-splash justify-center">
                                        <button className="btn-public-fav btn-pink text-center color-white" onClick = { this.addFav.bind(this)} >
                                        {
                                            this.state.loadingFav ?
                                            (
                                                (<i className="fas fa-spin fa-spinner color-white font-tiny"></i>)
                                            ) :
                                            (
                                                this.state.favorito ? 
                                                (
                                                    <div className="column">
                                                        <i className="fas fa-heart icon-btn font-tiny"></i>
                                                        <p className="color-white weight-semi">
                                                            ELIMINAR FAVORITO
                                                        </p>
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div className="column">
                                                        <i className="far fa-heart icon-btn font-tiny"></i>
                                                        <p className="color-white weight-semi">
                                                            AÑADIR A FAVORITOS
                                                        </p>
                                                    </div>
                                                )
                                            )
                                        }
                                        </button>
                                    </div>
                                    <div className="white-space-8"></div>
                                    <Link to = {`/chat/${influencer.usuario}`} className="btn-public btn-purple btn-contact text-center color-white column">
                                        <i className="fas fa-comment-dots font-tiny"></i>
                                        <p className="color-white weight-semi">CHAT</p>
                                    </Link>
                                </div>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="skills row wrap" >
                                    {
                                        interests ?
                                        (
                                            interests.map((interest, key) => (
                                                <div className="interes" key = { key } >
                                                    <p className="weight-semi text-center color-white wow animated fadeIn">
                                                        { interest.cat }
                                                    </p>
                                                </div>
                                                )
                                            )
                                        )
                                        :
                                        (
                                            <div className="column loading-center">
                                                <div className="white-space-64"></div>
                                                <div className="justify-center">
                                                    <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                </div>
                                            </div>
                                        )
                                    }
                                    </div>
                                <div className="white-space-8"></div>
                                <div className="gallery-profile">
                                    <div className = { `wrap ${empty ? null : 'grid-container'}` }>
                                    {
                                        empty ? 
                                        (
                                            <div className="column message-center">
                                                <div className="white-space-32"></div>
                                                <i className="far fa-images text-center color-pink"></i>
                                                <div className="white-space-8"></div>
                                                <div className="empty justify-center align-center">
                                                    <p className="text-center weight-semi font-regular">
                                                        SIN PUBLICACIONES
                                                    </p>
                                                </div>
                                            </div>
                                        ) :
                                        (
                                            images ? (
                                                images.map((image, key) => (
                                                    <div className="wow animated fadeIn photo responsive-img" key = { key }>
                                                        <div className="img-gallery" onClick = { this.handleShowImage.bind(this, image.url) }>
                                                            <ImageLoader src = { image.url } alt="Imagen del influencer" title="Imagen del influencer" className="img-gallery" loading = { () => <img className="cover-img loader-img" alt="Imagen del influencer" title="Imagen del influencer" src={LoaderImage} /> }/>
                                                        </div>
                                                    </div>
                                                    ))
                                            ) :
                                            (
                                                <div className="column loading-center">
                                                    <div className="white-space-64"></div>
                                                    <div className="justify-center">
                                                        <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    }                               
                                    </div>
                                </div>
                            <div className="white-space-32"></div>
                        </div>
                    </div>
                    <ModalPublicationAdd/>
                    <ModalShowImage imageUrl = {this.props.context.imageInfluencerPublicationToShow.imageUrl}></ModalShowImage>
                </div>
              </div>
              </div>
              </MediaQuery>
              <MediaQuery maxWidth = { 768 }>
                <Header back = "/inicio" showBack = { true } tipo = {tipo}/>
                <div className="influencer-cards column">
                    <div className="image">
                        <ImageLoader src={imagen} alt="Imagen del influencer" title="Imagen del influencer" className="cover-img"
                        loading={() => <img className="cover-img loader-img" alt="Imagen del influencer" title="Imagen del influencer" src={LoaderImage} />} />
                        <div className="title column justify-center align-center">
                            <div className="data justify-center">
                                <div className="container">
                                    <div className="column">
                                        <h4 className="color-white text-center">
                                            { influencer.nombre }
									    </h4>
                                        <div className="white-space-16"></div>
                                        <div className="row justify-center">
                                            <p className="color-white text-center text-space">
                                                @{ influencer.usuario }
                                            </p>
                                            <p className="color-white">
                                                { influencer.ciudad }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottom justify-center">
                            <div className=" justify-between">
                                <div className="column">
                                    <h3 className="color-white text-center">
                                        € { influencer.tarifa }
                                    </h3>
                                    <p className="color-white font-tiny text-center">
                                        Tarifa 
                                    </p>
                                </div>
                                <div className="column">
                                    <h3 className="color-white text-center">
                                        { influencer.seguidores }
                                    </h3>
                                    <p className="color-white text-center font-tiny">
                                        Followers
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tabs-container">
                        <button className="btn btn-square btn-pink text-center font-regular" onClick = { this.handleGallery.bind(this) } >
                            <i className="far fa-images color-pink" id="gallery-tab"></i>
                        </button>
                        <button className="btn btn-square btn-pink text-center font-regular" onClick = { this.handleInterest.bind(this) }>
                            <i className="fas fa-hashtag color-gray" id="interest-tab"></i>
                        </button>
                    </div>
                </div>
                <div className="gallery" id="gallery">
                    <div className=" wrap">
                        {
                            empty ? 
                            (
                                <div className="empty justify-center align-center">
                                    <h4 className="text-center">
                                        SIN PUBLICACIONES
                                    </h4>
                                </div>
                            ) :
                            (
                                images ? (
                                    images.map((image, key) => (
                                        <div className="photo responsive-img" key={key}  onClick = { this.handleShowImage.bind(this, image.url) }>
                                            <ImageLoader src={image.url} alt="Imagen del influencer" title="Imagen del influencer" className="cover-img"
                                            loading={() => <img className="cover-img loader-img" alt="Imagen del influencer" title="Imagen del influencer"  src={LoaderImage} />} />
                                        </div>
                                        ))
                                    ) :
                                    (
                                    <div className="empty justify-center align-center">
                                        <i className="fa-spin fas fa-spinner fa-2x color-purple"></i>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
                <div className="intereses-container wrap hidden" id="intereses">
                    {
                        interests ?
                        (
                            interests.map((interest, key) => (
                                <div className="interes" key = { key } >
                                    <div className="column justify-center movile-labels">
                                    <p className="weight-semi text-center color-white wow animated fadeIn">
                                        { interest.cat }
                                    </p>
                                    </div>
                                </div>
                                )
                            )
                        )
                        :
                        (
                            <div className="empty justify-center align-center">
                                <i className="fa-spin fas fa-spinner fa-2x color-purple"></i>
                            </div>
                        )
                    }
                    </div>
                <div className="white-space-64"></div>
                <div className="fixed-chat">
                    <Link to = {`/chat/${influencer.usuario}`} className="btn btn-square btn-pink font-weight text-center color-white">
                        CHAT
                    </Link>
                    <button className="btn btn-square btn-purple font-weight color-white" onClick = { this.addFav.bind(this)} >
                        {
							this.state.loadingFav ?
								(
									(<i className="fas fa-spin fa-spinner color-white"></i>)
								) :
								(
									this.state.favorito ?
										"ELIMINAR FAVORITO" :
										"AÑADIR A FAVORITOS"
								)
						}
                    </button>
                </div>
                <BottomBar active = "" />
                <ModalShowImage imageUrl = {this.props.context.imageInfluencerPublicationToShow.imageUrl}></ModalShowImage>
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({user: user.id});
            this.getInfluencer();
        }
    }

    async getInfluencer() {
        let userName = String(this.props.location.pathname).split("/");
        const data = { 
            userName: userName[2],
            idMarca: this.state.user.id
        };
        const res = await req.post("/influencers/getbymarca", data);
        this.setState({message: res.message});
        if (res.influencer) {
            this.setState({
                influencer: res.influencer,
                favorito: res.influencer.favorito
            });
            this.loadImages();
        }
    }

    async loadImages() {
        let idInfluencer = '';
        if(this.state.influencer){
            idInfluencer = this.state.influencer;
        }
        this.setState({ loading: true });
        const data = { id: idInfluencer.id_influencer };
        const userData = await req.post("/users/images/", data);
        if (userData) {
            this.setState({ message: userData.message });
            if (userData.empty) {
                this.setState({ empty: userData.empty });
            } else {
                if (userData.images) {
                    this.setState({ images: userData.images });
                }
            }
        }
        this.setState({ loading: false });
    }

    async handleShowImage(imageUrl) {
        this.props.context.setShowImageInfluencerPublication({imageUrl});
        let modalNewsLetter = document.getElementById('modal-show');  
        modalNewsLetter.classList.remove('fadeOut');
        modalNewsLetter.classList.add('animated', 'fadeIn','faster');
        modalNewsLetter.classList.remove('hidden');  
    }

    handleGallery() {
        let gallery = document.getElementById('gallery');
        let intereses = document.getElementById('intereses');
        let galleryTab = document.getElementById('gallery-tab');
        let interestTab = document.getElementById('interest-tab');
        if (interestTab.classList.contains('color-pink')) {
            interestTab.classList.remove('color-pink');
            interestTab.classList.add('color-gray');
            galleryTab.classList.remove('color-gray');
            galleryTab.classList.add('color-pink');
        }
        intereses.classList.add('hidden');
        gallery.classList.remove('hidden');
    }

    handleInterest() {
        let gallery = document.getElementById('gallery');
        let intereses = document.getElementById('intereses');
        let galleryTab = document.getElementById('gallery-tab');
        let interestTab = document.getElementById('interest-tab');
        if (galleryTab.classList.contains('color-pink')) {
            galleryTab.classList.remove('color-pink');
            galleryTab.classList.add('color-gray');
            interestTab.classList.remove('color-gray');
            interestTab.classList.add('color-pink');
        }
        gallery.classList.add('hidden');
        intereses.classList.remove('hidden');
    }

}

export default Consumer(PublicInfluencer);
/**
 *  bottombar.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath
 *  @description: Menu Lateral 
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HolderImage from '../../img/user.png';
import LoaderImage from '../../img/loader.gif';
import ImageLoader from 'react-loading-image';
import { Consumer } from "../../context";
import Request from '../../core/httpClient';
import socket from '../../core/socket';

const request = new Request();

class LeftBar extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            user: { id: 0, tipo: 0 },
            imagen: '',
            newMessage: false
        }
    }

    render() {
        let imagen = '', tipo = 0;
        if ( this.state.imagen ) { imagen = this.state.imagen; } else { imagen = HolderImage; }
        if (this.state.user.tipo) { tipo = this.state.user.tipo.toString(); }
        let wnm = '';
        if(window.localStorage.getItem('wnm')) {
            wnm = window.localStorage.getItem('wnm');
        }
        return (
            <div className="left-bar justify-center">
                <div className="column main align-center ">
                    <div className="row item-lef justify-center align-center">
                        <Link to = {`${ tipo === "1" ? "/ajustes/perfil" : tipo === "2" ? "/ajustes/perfil/marca" : "/inicio" }`} className="responsive-img justify-center align-center" >
                            <ImageLoader src = { imagen } alt="Imagen del influencer" title="Imagen del influencer" className="image-profile"
                            loading={() => <img className="cover-img loader-img" src = { LoaderImage } alt="Imagen del influencer" title="Imagen del influencer" className="image-profile" />} />
                        </Link>
                    </div>
                    <div className="row item-lef justify-center align-center">
                        <Link to="/inicio">
                            <div className = {`column item-bar justify-center align-center ${this.props.active === "menuHome" ? 'active-menu' : ''}` }  id="menuHome">
                                <i className="fas fa-images icon-menu"></i>
                                <div className="white-space-8"></div>
                                <h5>Inicio</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="row item-lef justify-center">
                        <Link  to = { `${ tipo === "1" ? "/perfil/influencer" : tipo === "2" ? "/perfil/marca" : "/inicio" }` } >
                        <div className = {`column item-bar justify-center align-center ${this.props.active === "menuProfile" ? 'active-menu' : ''}` }  id="menuProfile">
                            <i className="fas fa-user icon-menu"></i>
                                <div className="white-space-8"></div>
                                <h5>Mi perfil</h5>
                            </div>
                        </Link>                        
                    </div>
                    <div className="row item-lef justify-center">
                        <Link to="/favoritos">
                        <div className = {`column item-bar justify-center align-center ${this.props.active === "menuFavorites" ? 'active-menu' : ''}` } id="menuFavorites">
                            <i className="fas fa-heart icon-menu"></i>
                                <div className="white-space-8"></div>
                                <h5>Favoritos</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="row item-lef justify-center">
                        <Link to="/chats" id="bottomChats_link">
                            <div className = {`column item-bar justify-center align-center ${this.props.active === "menuChats" ? 'active-menu' : ''}` } id="menuChats">
                                <i className="fas fa-comments icon-menu"></i>
                                <div className="white-space-8"></div>
                                <h5>Chats</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="row item-lef justify-center">
                        <Link to = {`${ tipo === "1" ? "/ajustes/perfil" : tipo === "2" ? "/ajustes/perfil/marca" : "/inicio" }`}>
                        <div className = {`column item-bar justify-center align-center ${this.props.active === "menuSettings" ? 'active-menu' : ''}` } id="menuSettings">
                            <i className="fas fa-sliders-h icon-menu"></i>
                                <div className="white-space-8"></div>
                                <h5>Ajustes</h5>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );   
    }

    componentDidMount() { 
        this.load();
        socket.on('newMessage', (data) => {
            const chatsMenu = document.getElementById('bottomChats_link');
            if(chatsMenu) {
                window.localStorage.setItem('wnm', `1;${data.who.id}`);
                this.setState({newMessage: true});
            }
        })
    }

    async load() {
        const user = await this.props.context.loadUser();
        this.setState({user: user.id, loading: true});
        const data = { id: this.state.user.id, tipo: this.state.user.tipo };
        const response = await request.post("/users/images/single", data);
        if (response) {
            this.setState({ message: response.message });
            if (response.empty) {
                this.setState({ empty: response.empty });
            } else {
                if (response.image) {
                    this.setState({ imagen: response.image.imagen });
                }
            }
        }
        this.setState({ loading: false });

        const allMenuItems = document.getElementsByClassName('item-bar');
        for (let index = 0; index < allMenuItems.length; index++) {
            allMenuItems[index].classList.remove('active-menu');
        }

        switch (this.props.active) {
            case 'menuHome':
                document.getElementById(this.props.active).classList.add('active-menu');
                break;
            case 'menuProfile':
                document.getElementById(this.props.active).classList.add('active-menu');
                break;
            case 'menuFavorites':
                document.getElementById(this.props.active).classList.add('active-menu');
                break;
            case 'menuChats':
                document.getElementById(this.props.active).classList.add('active-menu');
                break;
            case 'menuSettings':
                document.getElementById(this.props.active).classList.add('active-menu');
                break;
            default:
                break;
        }

    }    

}

export default Consumer(LeftBar);
/**
 *  profile-influencer.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Perfil publico del influencer
*/

import React, { Component } from 'react';
import Header, { HeaderWebHomeLoggedIn } from '../components/header/header';
import BottomBar from '../components/bottom-bar/bottombar';
import PublicationCard from '../components/cards/publications';
import { Link } from 'react-router-dom';
import { Consumer } from "../context";
import Request from "../core/httpClient";
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import ImageLoader from 'react-loading-image';
import LoaderImage from '../img/loader.gif';
import ModalPublicationAdd from '../components/modals/publicationsAdd'; 
import { Helmet } from 'react-helmet';

const req = new Request();

class PublicBrand extends Component {

    constructor(props) {
        super(props);
        this.state = {
            marca: '', user: {id: 0, tipo: 0}, favorito: false
        }
    }

    componentWillUnmount() {
        if(!window.localStorage.getItem('waala_location_chat')) {
            this.props.context.setPrevLocationProfile(this.props.location);
        }
        this.mounted = false;
    }    

    async addFav() {
        this.setState({ loadingFav: true });
        const data = {
            id_influencer: this.state.user.id, id_marca: this.state.marca.id_marca,
            tipo: this.state.user.tipo, favorito: !this.state.favorito,
			tipo_pub: this.state.marca.tipo_pub
        }
        const res = await req.post('/favoritos/put', data);
        if(res.error) {
            console.log(res.message);
        } else {
            this.setState({favorito: !this.state.favorito});
        }
        this.setState({loadingFav: false});
	}

    render() {
        let marca = '', publications = '', empty = '', imagen = '', interests = [];   
        const user = this.state.user;
        const tipo = user.tipo ? user.tipo.toString() : '0';     
        if (this.state.marca) {  
            marca = this.state.marca;
            interests = JSON.parse(marca.intereses);
            imagen = this.state.marca.imagen_principal_url;
        }
        if (this.state.publications) { publications = this.state.publications; }
        if (this.state.empty) { empty = this.state.empty }

        return (
            <div className="profile-brand column">
                <Helmet>
                    <title>WAALA - Marca</title>
                    <meta property="og:title" content="WAALA - Marca" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <HeaderWebHomeLoggedIn />
                        <div className="row">
                            <LeftBar />
                            <div className="column">
                                <div className="row justify-center">
                                    <div className="container column align-center justify-center">
                                        <div className="white-space-32"></div>
                                        <div className="row header-profile">
                                            <div className="column profile-column profile-public justify-center align-center">
                                                <div className="responsive-img justify-center align-center">
                                                    <ImageLoader src = { imagen } alt="Logo de Marca" title="Logo de Marca" className="profile-image"
                                                    loading = { () => <img className="profile-image loader-img" src = { LoaderImage } /> } />
                                                </div>
                                            </div>
                                            <div className="column data-head justify-start align-center">
                                                <div className="data-head-top column">
                                                    <div className="row">
                                                        <div className="column public-profile-column">
                                                            <h2 className="weight-semi">
                                                                { marca.nombre }
                                                            </h2>
                                                            <p>
                                                                @{ marca.usuario }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="white-space-24"></div>
                                                <div className="row data-head-bottom">
                                                    <div className="column column-located">
                                                        <h4 className="font-small">
                                                            Ciudad
                                                        </h4>
                                                        <p className="text-start">
                                                            { marca.ciudad }
                                                        </p>
                                                    </div>
                                                    <div className="column column-followers">
                                                        <h4 className="font-small text-center">
                                                            Usuario
                                                        </h4>
                                                        <p className="text-center">
                                                            @{ marca.usuario }
                                                        </p>
                                                    </div>
                                                    <div className="column column-price">
                                                        <h4 className="font-small text-center">
                                                            Sitio web
                                                        </h4>
                                                        <p className="text-center">
                                                            <a href = { marca.sitio_web } target="_blank" rel="noopener noreferrer" className="text-center">
                                                                { marca.sitio_web }
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column content-btns align-center justify-center">
                                                <div className="btn-container btn-splash justify-center">
                                                    <button className="btn-public-fav btn-pink text-center color-white" onClick = { this.addFav.bind(this)} >
                                                    {
                                                        this.state.loadingFav ?
                                                        (
                                                            (<i className="fas fa-spin fa-spinner color-white font-tiny"></i>)
                                                        ) :
                                                        (
                                                            this.state.favorito ? 
                                                            (
                                                                <div className="column">
                                                                    <i className="fas fa-heart icon-btn font-tiny"></i>
                                                                    <p className="color-white weight-semi">
                                                                        ELIMINAR FAVORITO
                                                                    </p>
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                <div className="column">
                                                                    <i className="far fa-heart icon-btn font-tiny"></i>
                                                                    <p className="color-white weight-semi">
                                                                        AÑADIR A FAVORITOS
                                                                    </p>
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                    </button>
                                                </div>
                                                <div className="white-space-8"></div>
                                                <a onClick = {this.selectPublication.bind(this, marca.usuario)} className="btn-public btn-purple btn-contact text-center color-white column">
                                                    <i className="fas fa-comment-dots font-tiny"></i>
                                                    <p className="color-white weight-semi">CHAT</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="skills row wrap">
                                        {
                                            interests ?
                                            (
                                                interests.map((interest, key) => (
                                                    <div className="interes" key = { key } >
                                                        <p className="weight-semi text-center color-white wow animated fadeIn">
                                                            { interest.cat }
                                                        </p>
                                                    </div>
                                                    )
                                                )
                                            )
                                            :
                                            (
                                                <div className="column loading-center">
                                                    <div className="white-space-64"></div>
                                                    <div className="justify-center">
                                                        <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        </div>
                                        <div className="row justify-center">
                                            <div className="cards-container  column align-center justify-center">
                                                    <div className = { `wrap ${empty ? null : 'grid-container'}` }>
                                                    {
                                                        empty ? 
                                                        (
                                                            <div className="column message-center">
                                                                <div className="white-space-32"></div>
                                                                <i className="far fa-images text-center color-pink"></i>
                                                                <div className="white-space-8"></div>
                                                                <div className="empty justify-center align-center">
                                                                    <p className="text-center weight-semi font-regular">
                                                                        SIN PUBLICACIONES
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ) :
                                                        (
                                                            publications ? 
                                                            (
                                                                publications.map((publication, key) => (
                                                                    <PublicationCard imagen = { publication.imagen_url } nombre = { marca.nombre } usuario = { marca.usuario } tarifa = { publication.pago } ciudad = { marca.ciudad } 
                                                                    key = { key } id = { publication.id_publicacion } favorito = { publication.favorito } userId={ this.state.user.id } tipo_pub = { publication.tipo_pub } tipo = { this.state.user.tipo } 
                                                                    descripcion = {publication.descripcion} titulo = {publication.titulo}/>
                                                                ))
                                                            ) 
                                                            :
                                                            ( 
                                                                <div className="column loading-center">
                                                                    <div className="white-space-64"></div>
                                                                    <div className="justify-center">
                                                                        <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                    </div>
                                                </div>
                                            </div>                          
                                        <div className="white-space-32"></div>
                                    </div>
                                </div>
                                <ModalPublicationAdd/>
                            </div>
                        </div>
                    </div>
              </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                    <Header context = { this.props.context } showBack = { true }  tipo = { tipo }/>
                    <div className="brand-cards column">
                        <div className="banner-brand justify-center">
                            <div className="container">
                                <div className="brand-picture responsive-img justify-center align-center">
                                    <ImageLoader src = { imagen } alt="Logo de Marca" title="Logo de Marca" className="cover-img"
                                    loading = { () => <img className="cover-img loader-img" alt="Logo de Marca" title="Logo de Marca" src = { LoaderImage } /> } />
                                </div>
                            </div>
                        </div>
                        <div className="banner-brand justify-center"> 
                            <div className="container column">
                                <div className="data justify-center">
                                    <div className="container">
                                        <div className="column">
                                            <h4 className="text-center">
                                                { marca.nombre }
                                            </h4>
                                            <div className="white-space-8"></div>
                                            <div className="row justify-arround">
                                                <p>
                                                    @{ marca.usuario }
                                                </p>
                                                <p>
                                                    { marca.ciudad }
                                                </p>
                                            </div>
                                            <div className="white-space-8"></div>
                                            <div className="justify-center">
                                                    <a href = { marca.sitio_web } target="_blank" rel="noopener noreferrer" className="text-center">
                                                    { marca.sitio_web }
                                                </a>
                                            </div>
                                            <div className="white-space-8"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tabs-container-brand">
                            <button className="btn btn-square btn-pink text-center font-regular" onClick = { this.handleGallery.bind(this) } >
                                <i className="far fa-images color-pink" id="gallery-tab"></i>
                            </button>
                            <button className="btn btn-square btn-pink text-center font-regular" onClick = { this.handleInterest.bind(this) }>
                                <i className="fas fa-hashtag color-gray" id="interest-tab"></i>
                            </button>
                        </div>
                    </div>
                    <div className="justify-center" id="gallery">
                        <div className="cards-container container justify-center column">
                            {
                                empty ?
                                (
                                    <div className="empty justify-center align-center">
                                        <h4 className="text-center">
                                            SIN PUBLICACIONES
                                        </h4>
                                    </div>
                                ) :
                                (
                                    publications ? 
                                    (
                                        publications.map((publication, key) => (
                                            <PublicationCard imagen = { publication.imagen_url } usuario = { marca.usuario } tarifa = { publication.pago } ciudad={ marca.ciudad } key = { key } id = { publication.id_publicacion } favorito = { publication.favorito } userId = { this.state.user.id } tipo_pub = { publication.tipo_pub }
                                            tipo = { this.state.user.tipo } nombre = { marca.nombre } descripcion = {publication.descripcion} titulo = {publication.titulo}/>
                                        ))
                                    ) :
                                    ( 
                                        <div className="empty justify-center align-center">
                                            <i className="fa-spin fas fa-spinner fa-2x color-purple"></i>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </div>
                    <div className="intereses-container wrap hidden" id="intereses">
                        {
                            interests ?
                            (
                                interests.map((interest, key) => (
                                    <div className="interes" key = { key } >
                                         <div className="column justify-center movile-labels">
                                        <p className="weight-semi text-center color-white wow animated fadeIn">
                                            { interest.cat }
                                        </p>
                                        </div>
                                    </div>
                                    )
                                )
                            )
                            :
                            (
                                <div className="empty justify-center align-center">
                                    <i className="fa-spin fas fa-spinner fa-2x color-purple"></i>
                                </div>
                            )
                        }
                    </div>
                    <div className="white-space-64"></div>
                    <div className="white-space-32"></div>
                    <div className="fixed-chat">
                        <a onClick = {this.selectPublication.bind(this, marca.usuario)} className="btn btn-square btn-pink font-weight color-white text-center">
                            CHAT
                        </a>
                        <button className="btn btn-square btn-purple font-weight color-white" onClick = { this.addFav.bind(this)} >
                            {
                                this.state.loadingFav ?
                                    (
                                        (<i className="fas fa-spin fa-spinner color-white"></i>)
                                    ) :
                                    (
                                        this.state.favorito ?
                                            "ELIMINAR FAVORITO" :
                                            "AÑADIR A FAVORITOS"
                                    )
                            }
                        </button>
                    </div>
                    <BottomBar active = "" />
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        window.localStorage.removeItem('waala_location_profile')
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({user: user.id});
            this.getMarca();
        }
    }

    async getMarca() {
        let userName = String(this.props.location.pathname).split("/");
        const data = { 
            userName: userName[2],
            id_influencer: this.state.user.id
        };
        const marca = await req.post("/marcas/get", data);
        if (marca) {
            this.setState({ message: marca.message, marca: marca.marca, favorito: marca.marca.favorito });
            this.getBrandPublications();
        }
    }

    async getBrandPublications() {
        let userName = String(this.props.location.pathname).split("/");
        const data = { userName: userName[2], id_influencer: this.state.user.id };
        const publications = await req.post("/publications/getByBrand", data);
        if (publications) {
            if (publications.empty) {
                this.setState({ empty: publications.empty });
            } else {
                this.setState({ message: publications.message, publications: publications.publications });
            }
        }
    }

    handleGallery() {
        let gallery = document.getElementById('gallery');
        let intereses = document.getElementById('intereses');
        let galleryTab = document.getElementById('gallery-tab');
        let interestTab = document.getElementById('interest-tab');
        if (interestTab.classList.contains('color-pink')) {
            interestTab.classList.remove('color-pink');
            interestTab.classList.add('color-gray');
            galleryTab.classList.remove('color-gray');
            galleryTab.classList.add('color-pink');
        }
        intereses.classList.add('hidden');
        gallery.classList.remove('hidden');
    }

    handleInterest() {
        let gallery = document.getElementById('gallery');
        let intereses = document.getElementById('intereses');
        let galleryTab = document.getElementById('gallery-tab');
        let interestTab = document.getElementById('interest-tab');
        if (galleryTab.classList.contains('color-pink')) {
            galleryTab.classList.remove('color-pink');
            galleryTab.classList.add('color-gray');
            interestTab.classList.remove('color-gray');
            interestTab.classList.add('color-pink');
        }
        gallery.classList.add('hidden');
        intereses.classList.remove('hidden');
    }

    selectPublication(usuario) {
		window.localStorage.removeItem('waalapub');
		this.props.history.push(`/chat/${usuario}`);
	}

}

export default Consumer(PublicBrand);
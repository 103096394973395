import React, { Component } from "react";

class FinishPay extends Component {
 
  componentDidMount() {
    let modalNewsLetter = document.getElementById('modal-no-publish');
    document.getElementById('close-modal-delete').onclick = () => {
    	modalNewsLetter.classList.add('fadeOut','faster');
      	setTimeout(() => {
          	modalNewsLetter.classList.add('hidden');
      	}, 500);
    }
  }

  render() {
    return (    
        <div className="column">
            <div className="modal justify-center hidden" id="modal-no-publish">
                <div className="modal-content align-center column" >
                	<div className="white-space-16"></div>                      
                    	<div className="column">
                      		<h4 className="text-center">
                        		¿Confirmar que no se ha realizado la publicación?
                      		</h4>                       
                      		<div className="white-space-16"></div>
							<div className="modal-controlls">
								<button className="btn btn-square btn-pink color-white weight-semi" id="btn-deleteChat" onClick = { this.props.nopublished.bind(this)} >
									SI
								</button>
								<button className="btn btn-square btn-purple color-white weight-semi" id="close-modal-delete">
									NO
								</button>
							</div> 
                      </div>
                </div>
            </div>
        </div>
     );
  }
}

export default FinishPay;
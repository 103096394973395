/**
 *  messages.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Componente de de la vista contenedora de mensajes de chat
*/

import React, { Component } from 'react';
import socket from '../../core/socket';

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state={
            messages: this.props.messages
        }
    }

    componentDidUpdate() {
        var last = document.getElementById("chat_messages_last");
        if(last) {
            last.scrollIntoView({ behavior: 'smooth' });
            setTimeout(()=>{
                socket.emit('visto', this.props.messages);
            },500);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        var last = document.getElementById("chat_messages_last");
        if(last) {
            last.scrollIntoView({ behavior: 'smooth' });
        };
        setTimeout(()=>{
            if(this.props.messages[this.props.messages.length - 1]) {
                if(this.props.messages[this.props.messages.length - 1].id !== this.props.user.id) {
                    socket.emit('visto', this.props.messages);
                }
            }
        },1000);
    }

    render() {
        return (
            <div className="column" id="chat_messages">
                {this.props.messages.map((element, index) => {
                    const lastScroll = index === (this.props.messages.length - 1)? 'chat_messages_last' : undefined;
                    if(element.tipo === 0) {
                        return (
                            <React.Fragment key={index}>
                                <div className="white-space-8"></div>
                                <div className="message-alert justify-center" id={lastScroll}>
                                    <p className="auto text-left" id={`message${index}`}>
                                        {element.mensaje}
                                    </p>
                                </div>
                                <div className="white-space-8"></div>
                            </React.Fragment>
                        )
                    }
                    if(this.props.user.id === element.id && this.props.user.tipo === element.tipo) {
                        return (
                            <React.Fragment key={index}>
                                <div className="message message-me justify-end" id={lastScroll || `message${index}`}>
                                    <p className="auto text-left p-img">
                                        {element.imagen == 1? 
                                            <a href={element.mensaje} target="_blank" className="responsive-img auto justify-center align-center">
                                                <img src={element.mensaje} className="cover-img auto"></img>
                                            </a>
                                            :element.mensaje
                                        }
                                    </p>
                                </div>
                                <div className="white-space-8"></div>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment key={index}>
                                <div className="message message-user justify-start" id={lastScroll || `message${index}`}>
                                    <p className="auto text-right p-img">
                                        {element.imagen == 1? 
                                            <a href={element.mensaje} target="_blank" className="auto responsive-img justify-center align-center">
                                                <img src={element.mensaje} className="auto cover-img" />
                                            </a>
                                            :element.mensaje
                                        }
                                    </p>
                                </div>
                                <div className="white-space-8"></div>
                            </React.Fragment>
                        )
                    }
                })}
                <div className="white-space-8"></div>
            </div>
        );
    }

}

export default Messages;
/**
 *  publication-edit.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de editar publicación
*/

import React, { Component } from 'react';
import Header, { HeaderWebHomeLoggedIn }  from '../components/header/header';
import Request from "../core/httpClient";
import { Consumer } from '../context';
import ImageLoader from 'react-loading-image';
import LoaderImage from '../img/loader.gif';
import { Helmet } from 'react-helmet';
import ModalAlert from '../components/modals/profileAlert';
import BottomBar from '../components/bottom-bar/bottombar';
import LeftBar from '../components/left-bar/left-bar';
import ModalPublicationAdd from '../components/modals/publicationsAdd'; 
import MediaQuery from 'react-responsive';

const request = new Request();

class PublicationEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: '', network: false, userData: {id: 0, tipo: 0},
            publication: '', pago: '', descripcion: '', imagen_url: '', envio: '', condiciones: '', titulo: ''
        };
    }

    render() {
        let loading = '', back = '';
        if (this.state.loading) { loading = this.state.loading; }
        if (this.state.userData.id.tipo) { this.state.userData.id.tipo === 1 ? back = '/perfil/influencer' : back = '/perfil/marca' }

        const user = this.state.userData;
        const tipo = user.tipo ? user.tipo.toString() : '0'; 
        return (
        <div className="publication publication-edit column">
                <Helmet>
                    <title>WAALA - Editar publicación</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Editar publicación" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 769 }>
                <div className="view-web column">
                <HeaderWebHomeLoggedIn/>
                <div className="row">
                 <LeftBar active = "menuHome" />
                 <div className="justify-center">
                {
                    loading ? (
                        <div className="column">
                            <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                        </div>
                    ) : 
                    (
                        <div className="justify-start publication-grow column align-center">
                        <div className="white-space-24"></div>
                        <form className="container-publication container row" id="form-edit" onSubmit = { this.handleSubmit.bind(this) }>
                            <div className="image-edit">
                                <ImageLoader src = { this.state.imagen } alt="Imagen de la publicación" title="Imagen de la publicación" className="cover-img "
                                loading = { () => <img className="cover-img loader-img" src = { LoaderImage } /> } />
                            </div>
                            <div className="details column">
                                <div className="white-space-8"></div>
                                <div className="title column">
                                    <div>
                                        <div className="icon auto">
                                            <i className="fas fa-pencil-alt color-pink"></i>
                                        </div>
                                        <p className="weight-semi">
                                            Título
                                        </p>
                                    </div>
                                    <input type="text" name="title" className="input input-edit" maxLength = "60" minLength = "8" required placeholder="Título de la publicación" value = { this.state.titulo } onChange = { event => this.setState({ titulo: event.target.value }) }  />
                                </div>
                                <div className="white-space-8"></div>
                                <div className="row">
                                    <div className="price column">
                                        <div className="row">
                                            <div className="icon auto">
                                                <i className="fas fa-dollar-sign color-pink"></i>
                                            </div>
                                            <div className="info column">
                                                <p className="weight-semi">
                                                    Pago
                                                </p>
                                            </div>
                                        </div>
                                        <input type="number" name="price" max="999.90" className="input input-edit" id="price-edit" required placeholder="€"
                                        value = { this.state.pago } onChange={event => this.setState({ pago: event.target.value })}  />
                                    </div>
                                    <div className="shipping column">
                                        <div className="row">
                                            <div className="icon auto">
                                                <i className="fab fa-telegram-plane color-pink"></i>
                                            </div>
                                            <p className="weight-semi">
                                                ¿Envías el producto?
                                            </p>
                                        </div>
                                        <div className="info auto column">
                                            <select name="shipping" id="select" className="input input-edit color-black" value = { this.state.envio } onChange = { event => this.setState({ envio: event.target.value }) }>
                                                <option value="1">
                                                    Con envío
                                                </option>
                                                <option value="0">
                                                    Sin envío
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="row">
                                    <div className="description column">
                                        <div className="row">
                                            <div className="icon auto">
                                                <i className="fas fa-align-justify color-pink"></i>
                                            </div>
                                            <p className="weight-semi">
                                                Descripción
                                            </p>
                                        </div>
                                        <textarea name="description" minLength="24" maxLength="300" cols="30" rows="4" required className="input description-detail" placeholder="Descripción detalla (300 caracteres máximo)" value = { this.state.descripcion } onChange = { event => this.setState({ descripcion: event.target.value })}></textarea>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="row">
                                    <div className="description column">
                                        <div className="row">
                                            <div className="icon auto">
                                                <i className="fas fa-exclamation-circle color-pink"></i>
                                            </div>
                                            <p className="weight-semi">
                                                Condiciones
                                            </p>
                                        </div>
                                        <textarea name="conditions" maxLength="200" cols="30" rows="4" className="input description-detail" placeholder="Condiciones detalladas, opcional (200 caracteres máximo)" value = { this.state.condiciones } onChange = { event => this.setState({ condiciones: event.target.value })}></textarea>
                                    </div>
                                </div>
                                <div className="white-space-24"></div>
                                <div className="btn-container" id="btn-save">
                                    <button type="submit" className="btn btn-purple btn-square weight-semi color-white" disabled = {this.state.network }>
                                        {
                                            this.state.loadingUpdate ? 
                                            (                                                            
                                                <i className="fas fa-spin fa-spinner color-white"></i>
                                            ) :
                                            (
                                                this.state.message ?
                                                (this.state.message) : ("GUARDAR CAMBIOS")
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                        </div>
                    )
                }
                </div>
                </div>
            </div>
            </MediaQuery>
            <MediaQuery maxWidth = { 768 }>
            <Header context = { this.props.context } showBack = { true } tipo = { tipo }/>
            <div className="justify-center">
                {
                    loading ? (
                        <div className="column">
                            <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                        </div>
                    ) : 
                    (
                        <form className="container-publication container column" id="form-edit" onSubmit = { this.handleSubmit.bind(this) }>
                            <div className="image">
                                <ImageLoader src = { this.state.imagen } alt="Imagen de la publicación" title="Imagen de la publicación" className="cover-img"
                                loading = { () => <img className="cover-img loader-img" src = { LoaderImage } /> } />
                            </div>
                            <div className="details column">
                                <div className="white-space-8"></div>
                                <div className="title column">
                                    <div>
                                        <div className="icon auto">
                                            <i className="fas fa-pencil-alt color-pink"></i>
                                        </div>
                                        <p className="weight-semi">
                                            Título
                                        </p>
                                    </div>
                                    <input type="text" name="title" className="input input-edit" maxLength = "60" minLength = "8" required placeholder="Título de la publicación" value = { this.state.titulo } onChange = { event => this.setState({ titulo: event.target.value }) }  />
                                </div>
                                <div className="white-space-8"></div>
                                <div className="row">
                                    <div className="price column">
                                        <div className="row">
                                            <div className="icon auto">
                                                <i className="fas fa-dollar-sign color-pink"></i>
                                            </div>
                                            <div className="info column">
                                                <p className="weight-semi">
                                                    Pago
                                                </p>
                                            </div>
                                        </div>
                                        <input type="number" name="price" max="999.90" className="input input-edit" id="price-edit" required placeholder="€"
                                        value = { this.state.pago } onChange={event => this.setState({ pago: event.target.value })}  />
                                    </div>
                                    <div className="shipping column">
                                        <div className="row">
                                            <div className="icon auto">
                                                <i className="fab fa-telegram-plane color-pink"></i>
                                            </div>
                                            <p className="weight-semi">
                                                ¿Envías el producto?
                                            </p>
                                        </div>
                                        <div className="info auto column">
                                            <select name="shipping" id="select" className="input input-edit color-black" value = { this.state.envio } onChange = { event => this.setState({ envio: event.target.value }) }>
                                                <option value="1">
                                                    Con envío
                                                </option>
                                                <option value="0">
                                                    Sin envío
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="row">
                                    <div className="description column">
                                        <div className="row">
                                            <div className="icon auto">
                                                <i className="fas fa-info color-pink"></i>
                                            </div>
                                            <p className="weight-semi">
                                                Descripción
                                            </p>
                                        </div>
                                        <textarea name="description" minLength="24" maxLength="300" cols="30" rows="10" required className="input description-detail" placeholder="Descripción detalla (300 caracteres máximo)" value = { this.state.descripcion } onChange = { event => this.setState({ descripcion: event.target.value })}></textarea>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="row">
                                    <div className="description column">
                                        <div className="row">
                                            <div className="icon auto">
                                                <i className="fas fa-exclamation color-pink"></i>
                                            </div>
                                            <p className="weight-semi">
                                                Condiciones
                                            </p>
                                        </div>
                                        <textarea name="conditions" maxLength="200" cols="30" rows="6" className="input description-detail" placeholder="Condiciones detalladas, opcional (200 caracteres máximo)" value = { this.state.condiciones } onChange = { event => this.setState({ condiciones: event.target.value })}></textarea>
                                    </div>
                                </div>
                                <div className="white-space-24"></div>
                                <div className="btn-container" id="btn-save">
                                    <button type="submit" className="btn btn-purple btn-square weight-semi color-white" disabled = {this.state.network }>
                                        {
                                            this.state.loadingUpdate ? 
                                            (                                                            
                                                <i className="fas fa-spin fa-spinner color-white"></i>
                                            ) :
                                            (
                                                this.state.message ?
                                                (this.state.message) : ("GUARDAR CAMBIOS")
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    )
                }
            </div>
            <BottomBar />
            </MediaQuery>
            <ModalAlert title="PUBLICACIÓN ACTUALIZADA" description="" />
        </div>
        );
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({userData: user.id})
            this.validatePublication();
        }
    }

    async validatePublication() {
        this.setState({ loading: true });
        if(this.state.userData) {
            let brand = this.state.userData.id;
            let idPublication = String(this.props.location.pathname).split("/");
            const data = { idPublication: idPublication[2], idMarca: brand };
            const publication = await request.post("/publications/validate", data);
            if (publication && !publication.error) {
                if (publication.redirect) {
                    window.location = "/inicio";
                } else {
                    this.setState({
                        imagen: publication.publication.imagen_url,
                        pago: publication.publication.pago,
                        envio: publication.publication.envio,
                        descripcion: publication.publication.descripcion,                        
                        condiciones: publication.publication.condiciones,
                        titulo: publication.publication.titulo,
                        idPublicacion: publication.publication.id_publicacion,
                        idMarca: publication.publication.id_marca,
                        loading: false
                    });
                }
            } else {
                this.setState({network: true});
            }
        } else {
            this.validatePublication();
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({loadingUpdate: true});
        const form = event.target;
        const data = {
            idPublicacion: this.state.idPublicacion, idMarca: this.state.idMarca, imagenUrl: this.state.imagen,
            pago: form.price.value, envio: form.shipping.value, descripcion: form.description.value, 
            condiciones: form.conditions.value, titulo: form.title.value
        };
        const updateValues = await request.post("/publications/update", data);        
        if (updateValues.update) {
            this.setState({ loadingUpdate: false, message: updateValues.message });
            let modal = document.getElementById('modal-profileAlert');
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
            setTimeout(() => {
                window.location = `/publicacion/${this.state.idPublicacion}`;
            }, 2500);
        }
    }

}


export default Consumer(PublicationEdit);
/**
 *  index.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Punto de Entrada de React
 * 	@process: 1
*/

import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import WebFont from 'webfontloader';
import * as serviceWorker from './serviceWorker';
import App from './App';
import WOW from 'wowjs';

// Hoja de estilos
//import './css/style.css';
import './sass/style.scss';
import './css/animate.css';

// Método para cargar la fuente de Google de manera asíncrona

WebFont.load({
    google: {
        families: ['Open Sans:400,600,700', 'sans-serif']
    }
});

ReactDOM.render(<App />, document.getElementById('root'));

new WOW.WOW({
    live: false
}).init();

// Si quieres que tu aplicación funcione sin conexión y se cargue más rápido, puedes cambiar unregister() a register()
serviceWorker.register();
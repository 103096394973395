/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath, Sergio
 *  @description: Editar Contraseña
*/

import React, { Component } from 'react';
import BottomBar from '../components/bottom-bar/bottombar';
import { Link } from 'react-router-dom';
import { HeaderCuentaBan, HeaderWebHomeLoggedIn } from '../components/header/header';
import { Consumer } from "../context";
import Request from "../core/httpClient";
import LeftBar from '../components/left-bar/left-bar';
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';
import ModalAlert from '../components/modals/profileAlert';
import LeftBarSetting from '../components/left-bar/left-settings-bar';

const request = new Request();

class CuentaBan extends Component {

    constructor(props) {
        super(props);
        this.state = {
			userData: { id: 0, tipo: 0},
			cuenta_ban: '',
			cuenta_nom: ''
        };
    }

    render() {
        return(
            <div className="settings column">
                <Helmet>
                    <title>WAALA - Datos Bancarios</title>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                <div className="view-web column">
                <HeaderWebHomeLoggedIn/>
                <div className="row">
                    <LeftBar active = "menuSettings"/>
                    <div className="column align-center">
                    <div className="white-space-32"></div>
                       <div className="row-responsive container">
                            <LeftBarSetting link="/ajustes/perfil" option = "4"/>
                            <div className="column">
                            <form className="column" id="desktop-form"> 
                                <div className="row">
                                    <div className="column right-main content-forms align-center">
                                    <h4>
                                        Datos Bancarios
                                    </h4>
                                    <div className="white-space-8"></div>
                                    <h5>
                                        Proporciona tu información bancaria donde podamos realizarte las transferencias.
                                    </h5>
                                    <div className="white-space-8"></div>
                                    <div className="white-space-8"></div>
                                        <input type="text" name="nombreBan" placeholder="Nombre del propetario de cuenta" className="input input-password" value = { this.state.cuenta_nom } onChange = { event => this.setState({ cuenta_nom: event.target.value })} minLength="3" maxLength="60" />
                                        <div className="white-space-8"></div>
                                        <input type="text" name="cuentaBan" placeholder="Cuenta Depósito (IBAN)" className="input input-password" required value = { this.state.cuenta_ban } onChange = { event => this.setState({ cuenta_ban: event.target.value }) }/>
                                        <div className="white-space-8"></div>
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                {
                                    this.state.message ?
                                    (
                                        <div className="column">
                                            {
                                                this.state.loading ? 
                                                (
                                                    <div className="justify-center">
                                                        <i className="fas fa-spin fa-spinner color-pink"></i>
                                                    </div>
                                                ) : ( null )
                                            }
                                            <p className="text-center">
                                                { this.state.message }
                                            </p>
                                        </div>
                                    ) :
                                    ( null )
                                }
                                    <div className="btn-container auto btn-splash justify-center">
                                        <button type="button" className="btn-web-forms btn-purple font-small  text-center color-white weight-bold" onClick = { this.updateCuentaBan.bind(this, true) }>
                                            {
                                            this.state.loading ?
                                                (<i className="fas fa-spin fa-spinner font-small color-white i-right"></i>) :
                                                ("ACTUALIZAR DATOS BANCARIOS")
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                       </div>
                    </div>
                </div>
                </div>
                </MediaQuery>
                
                <MediaQuery maxWidth = { 768 }>
                <HeaderCuentaBan/>
                <Link to="/ajustes">
                    <i className="fas fa-times font-small color-white i-left i-left-center"></i> 
                </Link>
                <form className="password justify-center" id="mobile-form">
                    <div className="container column">  
                        <div className="white-space-16"></div>
                        <div className="column">
                            <div className="white-space-8"></div>
                            <h5>
                                Proporciona tu información bancaria donde podamos realizarte las transferencias.
                            </h5>
							<div className="white-space-16"></div>
                            <p className="color-darkgray weight-semi">
                                Nombre completo del titular:
                            </p>
                            <div className="white-space-8"></div>
                            <div className="input-container align-center justify-center">
                                <input type="text" name="nombreBan" placeholder="Nombre del propetario de cuenta" className="input input-password" value = { this.state.cuenta_nom } onChange = { event => this.setState({ cuenta_nom: event.target.value })} minLength="3" maxLength="60" />
                            </div>
                            <div className="white-space-8"></div>
                            <p className="color-darkgray weight-semi">
                                Cuenta de depósito (IBAN):
                            </p>
                            <div className="white-space-8"></div>
                            <div className="input-container align-center justify-center">
                                <input type="text" name="cuentaBan" placeholder="Cuenta Depósito (IBAN)" className="input input-password" required value = { this.state.cuenta_ban } onChange = { event => this.setState({ cuenta_ban: event.target.value }) }/>
                            </div>
                            {
                                this.state.message ?
                                (
                                    <div className="column">
                                        <div className="white-space-16"></div>
                                        {
                                            this.state.loading ? 
                                            (
                                                <div className="justify-center">
                                                    <i className="fas fa-spin fa-spinner color-pink"></i>
                                                </div>
                                            ) : ( null )
                                        }
                                        <p className="text-center weight-semi">
                                            { this.state.message }
                                        </p>
                                    </div>
                                ) :
                                ( null )
                            }
                            <div className="white-space-16"></div>
                            <button type="button" onClick = { this.updateCuentaBan.bind(this, false) }>
                                {
                                    this.state.loading ?
                                        (<i className="fas fa-spin fa-spinner font-small color-white i-right i-right-center"></i>) :
                                        (<i className="fas fa-check font-small color-white i-right i-right-center"></i>)
                                }
                            </button>
                        </div>
                    </div>
                </form>               
                <BottomBar />
                </MediaQuery>
                <ModalAlert title="DATOS ACTUALIZADOS" description="" />
            </div>
        );
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if(user) {
			this.setState({userData: user.id});
			const data = {
				user: user.id
			}
			const res = await request.post("/users/influencers/databan", data);
			this.setState({message: res.message});
			if(res.data) {
				this.setState({cuenta_ban: res.data.cuenta_ban, cuenta_nom: res.data.cuenta_nom});
			}
        }
    }

    async updateCuentaBan(desktop) {
		this.setState({loading: true});
        const data = {
			cuenta_ban: this.state.cuenta_ban,
			cuenta_nom: this.state.cuenta_nom,
			userId: this.state.userData.id
		}

		const res = await request.post('/users/update/cuenta_ban', data);
		this.setState({message: res.message});
		console.log(res);
		if(res.update) {
            let modal = document.getElementById('modal-profileAlert');
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
            if (desktop) {
                setTimeout(() => {
                    this.props.history.push('/ajustes/bancario')
                }, 1000);
            } else {
                setTimeout(() => {
                    this.props.history.push('/ajustes')
                }, 1000);
            }
		}
		this.setState({loading: false});
    }

}

export default Consumer(CuentaBan);
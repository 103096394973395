import React, { Component } from "react";
import shava from '../../img/girl1.jpg';

class ShowImage extends Component {

    constructor(props) {
        super(props);
       
    }

  componentDidMount() {
      let modalNewsLetter = document.getElementById('modal-show');
      document.getElementById('close-modal-show').onclick = () => {
      modalNewsLetter.classList.add('fadeOut');
      setTimeout(() => {
          modalNewsLetter.classList.add('hidden');
      }, 500);

    }
  }

  render() {
    return (

        <div className="column">       
            <div className="white-space-32"></div>
            <div className="modal justify-center align-center hidden" id="modal-show">
                <div className="modal-content container align-center column" >                  
                    <button className="btn auto" id="close-modal-show">
                        <i className="fas fa-times"></i>
                    </button>
                    <div className="logo auto responsive-img justify-center align-center">
                    <img src={this.props.imageUrl} className="modal-image" />
                     </div>
                </div>
            </div>
        </div>
      
     );
  }
}



export default ShowImage;

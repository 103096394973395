/**
 *  publication.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de detalles de la publicación
*/

import React, { Component } from 'react';
import Header, { HeaderWebHomeLoggedIn } from '../components/header/header';
import BottomBar from '../components/bottom-bar/bottombar';
import Request from "../core/httpClient";
import HolderImage from '../img/placeholder.png';
import ImageLoader from 'react-loading-image';
import LoaderImage from '../img/loader.gif';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Consumer } from '../context';
import LeftBar from '../components/left-bar/left-bar';
import ModalPublicationAdd from '../components/modals/publicationsAdd'; 
import MediaQuery from 'react-responsive';

class Publication extends Component {

    constructor(props) {
        super(props);
        this.state = {
            publication: '', user: { id: 0, tipo: 0 }
        };
    }    

    componentWillUnmount() {
        if(!window.localStorage.getItem('waala_location_chat')) {
            this.props.context.setPrevLocationProfile(this.props.location);
        }
        this.mounted = false;
    }

    render() {
        let publication = '', imagen = '', empty = '', tipo = '';
        if (this.state.publication) {
            publication = this.state.publication;
            if (publication.imagen_url) {
                imagen = this.state.publication.imagen_url;
            } else {
                imagen = HolderImage;
            }
        }
        if (this.state.empty) {
            empty = this.state.empty;
            imagen = HolderImage;
        }

        if (this.state.userData) { tipo = this.state.userData.tipo.toString(); }

        return (
            <div className="publication column">
                <Helmet>
                    <title>WAALA - Publicación</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Publicación" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                <div className="view-web column">
                <HeaderWebHomeLoggedIn/>
                <div className="row">
                 <LeftBar active = "menuHome" />
                {
                    this.state.loading ?
                    (
                        <div className="column">
                            <div className="white-space-16"></div>
                            <div className="justify-center">
                                <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                            </div>
                        </div>
                    )
                    :
                    (
                        <div className="justify-start publication-grow column align-center">
                         <div className="white-space-24"></div>
                            <div className="container-publication container row">
                                <div className="column image justify-center align-center">
                                    <ImageLoader src = { imagen } alt="Imagen de la publicación" title="Imagen de la publicación" className="cover-img"
                                    loading={() => <img className="cover-img loader-img" src = { LoaderImage } />} />
                                </div>
                                <div className="details column">
                                    <div className="white-space-16"></div>
                                    <div className="titles column">
                                        <h4 className="text-center">
                                            { publication.titulo }
                                        </h4>
                                    </div> 
                                    <div className="white-space-16"></div>
                                    <div className="row">
                                        <div className="price">
                                            <div className="icon auto text-center">
                                                <i className="fas fa-dollar-sign color-pink font-regular"></i>
                                            </div>
                                            <div className="info column">
                                                <p className="weight-semi">
                                                    Pago
                                                </p>
                                                <p>
                                                    { publication.pago } €
                                                </p>
                                            </div>
                                        </div>
                                        <div className="shipping justify-end">
                                            <div className="icon auto text-center">
                                                <i className="fab fa-telegram-plane color-pink font-regular"></i>
                                            </div>
                                            <div className="info auto column">
                                                <p className="weight-semi">
                                                    Envío
                                                </p>
                                                <p>
                                                    {
                                                        publication.envio ?
                                                        (
                                                            publication.envio === 1 ?
                                                            "Con envío" :
                                                            "Sin envío"                                            
                                                        ) : "Sin especificar"
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="row">
                                        <div className="description">
                                            <div className="icon auto text-center">
                                                <i className="fas fa-info color-pink"></i>
                                            </div>
                                            <div className="info auto column">
                                                <p className="weight-semi">
                                                    Descripción
                                                </p>
                                                <p className="text-justify">
                                                    { publication.descripcion }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="row">
                                        <div className="description">
                                            <div className="icon auto text-center">
                                                <i className="fas fa-exclamation color-pink"></i>
                                            </div>
                                            <div className="info auto column">
                                                <p className="weight-semi">
                                                    Condiciones
                                                </p>
                                                <p className="text-justify">
                                                    { publication.condiciones }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-space-16"></div>
                                    {
                                        empty ?
                                        (
                                            <div></div>
                                        ):
                                        (
                                            <div className="column author">
                                                <p className="text-center weight-semi font-mini">
                                                    Publicado por: @{ publication.usuario }
                                                </p>
                                                <div className="white-space-8"></div>
                                                <p className="text-center">
                                                    <Link to = { `/marca/${publication.usuario}` } className="text-center color-purple weight-semi font-mini">
                                                        VISITAR PERFIL
                                                    </Link>
                                                </p>
                                                {
                                                    tipo === "2" ? (<div className="white-space-16"></div>) : (<div className="white-space-64"></div>)
                                                }
                                                {
                                                    tipo === "2" ?
                                                    ( null )
                                                    :
                                                    (
                                                        <div className="btn-container" id="to-bottom">
                                                            <a onClick={this.selectPublication.bind(this, publication.usuario)}  className="btn btn-purple btn-square weight-semi color-white text-center btn-chat">
                                                                CHAT
                                                            </a>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <ModalPublicationAdd/>
                        </div>
                    )
                }
                </div>
                </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                <Header context = { this.props.context } showBack = { true } tipo = { tipo }/>
                {
                    this.state.loading ?
                    (
                        <div className="column">
                            <div className="white-space-16"></div>
                            <div className="justify-center">
                                <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                            </div>
                        </div>
                    )
                    :
                    (
                        <div className="justify-center publication-grow">
                            <div className="container-publication container column">
                                <div className="image justify-center align-center">
                                    <ImageLoader src = { imagen } alt="Imagen de la publicación" title="Imagen de la publicación" className="cover-img"
                                    loading={() => <img className="cover-img loader-img" src = { LoaderImage } />} />
                                </div>
                                <div className="details column">
                                    <div className="white-space-8"></div>
                                    <div className="titles column">
                                        <h4 className="text-center">
                                            { publication.titulo }
                                        </h4>
                                    </div> 
                                    <div className="white-space-16"></div>
                                    <div className="row">
                                        <div className="price">
                                            <div className="icon auto text-center">
                                                <i className="fas fa-dollar-sign color-pink font-regular"></i>
                                            </div>
                                            <div className="info column">
                                                <p className="weight-semi">
                                                    Pago
                                                </p>
                                                <p>
                                                    { publication.pago } €
                                                </p>
                                            </div>
                                        </div>
                                        <div className="shipping justify-end">
                                            <div className="icon auto text-center">
                                                <i className="fab fa-telegram-plane color-pink font-regular"></i>
                                            </div>
                                            <div className="info auto column">
                                                <p className="weight-semi">
                                                    Envío
                                                </p>
                                                <p>
                                                    {
                                                        publication.envio ?
                                                        (
                                                            publication.envio === 1 ?
                                                            "Con envío" :
                                                            "Sin envío"                                            
                                                        ) : "Sin especificar"
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="row">
                                        <div className="description">
                                            <div className="icon auto text-center">
                                                <i className="fas fa-info color-pink"></i>
                                            </div>
                                            <div className="info auto column">
                                                <p className="weight-semi">
                                                    Descripción
                                                </p>
                                                <p className="text-justify">
                                                    { publication.descripcion }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="row">
                                        <div className="description">
                                            <div className="icon auto text-center">
                                                <i className="fas fa-exclamation color-pink"></i>
                                            </div>
                                            <div className="info auto column">
                                                <p className="weight-semi">
                                                    Condiciones
                                                </p>
                                                <p className="text-justify">
                                                    { publication.condiciones }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-space-16"></div>
                                    {
                                        empty ?
                                        (
                                            <div></div>
                                        ):
                                        (
                                            <div className="column author">
                                                <p className="text-center weight-semi font-mini">
                                                    Publicado por: @{ publication.usuario }
                                                </p>
                                                <div className="white-space-8"></div>
                                                <p className="text-center">
                                                    <Link to = { `/marca/${publication.usuario}` } className="text-center color-purple weight-semi font-mini">
                                                        VISITAR PERFIL
                                                    </Link>
                                                </p>                                                
                                                {
                                                    tipo === "2" ? (<div className="white-space-16"></div>) : (<div className="white-space-64"></div>)
                                                }
                                                {
                                                    tipo === "2" ?
                                                    ( null )
                                                    :
                                                    (
                                                        <div className="btn-container" id="to-bottom">
                                                            <a onClick={this.selectPublication.bind(this, publication.usuario)} className="btn btn-purple btn-square weight-semi color-white text-center btn-chat">
                                                                CHAT
                                                            </a>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
                <BottomBar />
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({userData: user.id})
            this.getPublication();
        }
    }

    async getPublication() {
        this.setState({loading: true});
        const request = new Request();
        let idPublication = String(this.props.location.pathname).split("/");
        const data = { idPublication: idPublication[2] };
        const publication = await request.post("/publications/get", data);
        if (publication) {
            if (publication.empty) {
                this.setState({ empty: publication.empty, loading: false });
            } else {
                this.setState({
                    message: publication.message, publication: publication.publication, loading: false
                });
            }
        }
    }

    selectPublication(usuario) {
        const publication = this.state.publication;
		window.localStorage.setItem('waalapub', `${publication.id_publicacion};${publication.titulo}`);
		this.props.history.push(`/chat/${usuario}`);
	}
}
export default Consumer(Publication);
/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath, Sergio
 *  @description: vista de ajustes legalidad
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HeaderWebHomeLoggedIn } from '../components/header/header';
import LeftBar from '../components/left-bar/left-bar';
import MediaQuery from 'react-responsive';
import LeftBarSetting from '../components/left-bar/left-settings-bar';

const SettingsLegality = () => (
    <div className="settings column">
        <MediaQuery minDeviceWidth = { 768 }>
            <HeaderWebHomeLoggedIn/>
            <div className="row">
                <LeftBar active = "menuSettings"/>
                <div className="column align-center">
                <div className="white-space-32"></div>
                    <div className="row-responsive container">
                        <LeftBarSetting link="/ajustes/perfil" option = "6"/>
                        <div className="column">
                            <form className="column">                               
                                <div className="row">
                                    <div className="column right-main content-forms align-center">
                                        <h4>
                                            Legalidad
                                        </h4>
                                        <div className="white-space-8"></div>
                                        <div className="input-container align-center justify-center">
                                            <i className="fas fa-file-alt  font-medium color-pink"></i>
                                            <Link to="/files/politica-de-privacidad.pdf" target="_blank" className="input input-profile">Política de privacidad</Link>
                                        </div>  
                                        <div className="white-space-8"></div>
                                        <div className="input-container align-center justify-center">
                                            <i className="fas fa-file-alt font-medium color-pink"></i>
                                            <Link to="/files/terminos-y-condiciones.pdf" target="_blank" className="input input-profile">Términos de uso</Link>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="input-container align-center justify-center">
                                            <i className="fas fa-file-alt font-medium color-pink"></i>
                                            <Link to="/files/politica-de-cookies.pdf" target="_blank" className="input input-profile">Política de Cookies</Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </MediaQuery>
    </div>
);

export default SettingsLegality;
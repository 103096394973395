/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Kath
 *  @description: Editar perfil marca
*/

import React, { Component } from 'react';
import BottomBar from '../components/bottom-bar/bottombar';
import { HeaderWebHomeLoggedIn } from '../components/header/header';
import HolderImage from '../img/user.png';
import { Link } from 'react-router-dom';
import { Consumer } from '../context';
import Request from "../core/httpClient";
import superagent from 'superagent';
import Logo from '../img/logotransparente.png';
import WaalaText from '../img/waalatext.png';
import { Helmet } from 'react-helmet';
import ModalAlert from '../components/modals/profileAlert';
import ModalPublicationAdd from '../components/modals/publicationsAdd'; 
import LeftBar from '../components/left-bar/left-bar';
import LeftBarSetting from '../components/left-bar/left-settings-bar';
import MediaQuery from 'react-responsive';

const request = new Request();

class ProfileBrands extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {id: 0, tipo: 0},
            updateImage: false, ciudad: '', email: '', fotoPerfil: HolderImage, nombre: '',
            web: '', tipo: '', user: '', telefono: '', first_login: '', web: '', sector: '', sectores: []
        };
    }
    
    changeImage() {
        document.getElementById("img-src").click();
        this.setState({ updateImage: true });
    }

    handleChange(event) {
        let resizedImage = "";
        const src = event.target;
        let fr = new FileReader();
        fr.onload = function (e) {
            const image = new Image();
            image.onload = function () {
                const canvas = document.createElement("canvas");
                const max_size = 600;
                let width = image.width;
                let height = image.height;
                if (width > height) {
                    if (width > max_size) {
                        height *= max_size / width;
                        width = max_size;
                    }
                } else {
                    if (height > max_size) {
                        width *= max_size / height;
                        height = max_size;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext("2d").drawImage(image, 0, 0, width, height);
                resizedImage = canvas.toDataURL("image/png");
                document.getElementById("img-preview").src = resizedImage;
                this.setState({ imagen: resizedImage });
            }.bind(this);
            image.src = e.target.result;
        }.bind(this);
        if (src.files.length > 0) {
            fr.readAsDataURL(src.files[0]);
        }
    }

    imageError(event) { event.target.src = "https://via.placeholder.com/600?text=Click+para.actualizar+imagen"; }
    
    render() {
        let firstTime = '', tipo = '';
        if (this.state.first_login === 1) { firstTime = this.state.first_login; }
        if (this.state.userData) { tipo = this.state.userData.tipo.toString(); }
        return (
            <div className="profile profile-settings column" >
                <Helmet>
                    <title>WAALA - Editar Perfil</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Editar Perfil" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                <div className="settings view-web column">
                <HeaderWebHomeLoggedIn/>
                <div className="row">
					<LeftBar active = "menuSettings"/>
                    <div className="column align-center">
                    <div className="white-space-32"></div>
                       <div className="row-responsive container">
							<LeftBarSetting link = { tipo === "1" ? '/ajustes/perfil' : '/ajustes/perfil/marca' } option="1" />
                              <form className="column" id="desktop-form">
                                <div className="row"> 
                                    <div className="column right-main align-center">
                                    <div className="white-space-8"></div>
                                    <h4>Cambiar foto de perfil</h4>
                                    <div className="white-space-8"></div>
                                    <div className="content auto responsive-img justify-center align-center" onClick = { this.changeImage.bind(this) }>
                                        <img name="image" id="img-preview" src = { this.state.fotoPerfil ? this.state.fotoPerfil : HolderImage } alt="Lodo de Marca" title="Lodo de Marca" className="image-profile-edit" />
                                        <div className="overlay-image column align-center justify-center" >
                                            <i className="far fa-edit color-white fa-2x"></i>
                                        </div>
										<input type="file" name="img-src" id="img-src" className="hidden" onChange = { this.handleChange.bind(this) }/>
                                    </div>
                                    <div className="white-space-8"></div>
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="row">
                                    <div className="column right-main content-forms align-center">
                                    <h4>Información personal</h4>
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center justify-center">
									<i className="fas fa-user-edit font-regular color-pink"></i>
									<input type="text" name="name" placeholder="Nombre" className="input input-profile" required minLength="3" maxLength="60"
									value = { this.state.nombre } onChange = { event => this.setState({ nombre: event.target.value }) }   />
                                    </div>                                   
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-map-marked-alt font-regular color-pink"></i>
                                        <i className="fas fa-sort-down font-tiny color-pink icon-options"></i>
                                        <select name="ciudad" className="input input-profile select-options" id="city" value = { this.state.ciudad } onChange = {event => this.setState({ ciudad: event.target.value })}>
										<option value="A Coruña">A Coruña</option>
										<option value="Álava/Araba">Álava/Araba</option>
										<option value="Albacete">Albacete</option>
										<option value="Alicante/Alacant">Alicante/Alacant</option>
										<option value="Almería">Almería</option>
										<option value="Asturias">Asturias</option>
										<option value="Ávila">Ávila</option>
										<option value="Badajoz">Badajoz</option>
										<option value="Barcelona">Barcelona</option>
										<option value="Burgos">Burgos</option>
										<option value="Cáceres">Cáceres</option>
										<option value="Cádiz">Cádiz</option>
										<option value="Cantabria">Cantabria</option>
										<option value="Castellón/Castelló">Castellón/Castelló</option>
										<option value="Ceuta">Ceuta</option>
										<option value="Ciudad Real">Ciudad Real</option>
										<option value="Córdoba">Córdoba</option>
										<option value="Cuenca">Cuenca</option>
										<option value="Girona">Girona</option>
										<option value="Granada">Granada</option>
										<option value="Guadalajara">Guadalajara</option>
										<option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</option>
										<option value="Huelva">Huelva</option>
										<option value="Huesca">Huesca</option>
										<option value="Illes Balears">Illes Balears</option>
										<option value="Jaén">Jaén</option>
										<option value="La Rioja">La Rioja</option>
										<option value="Las Palmas">Las Palmas</option>
										<option value="León">León</option>
										<option value="Lleida">Lleida</option>
										<option value="Lugo">Lugo</option>
										<option value="Madrid">Madrid</option>
										<option value="Málaga">Málaga</option>
										<option value="Melilla">Melilla</option>
										<option value="Murcia">Murcia</option>
										<option value="Navarra">Navarra</option>
										<option value="Ourense">Ourense</option>
										<option value="Palencia">Palencia</option>
										<option value="Pontevedra">Pontevedra</option>
										<option value="Salamanca">Salamanca</option>
										<option value="Santa Cruz de Tenerife">Santa Cruz de Tenerife</option>
										<option value="Segovia">Segovia</option>
										<option value="Sevilla">Sevilla</option>
										<option value="Soria">Soria</option>
										<option value="Tarragona">Tarragona</option>
										<option value="Teruel">Teruel</option>
										<option value="Toledo">Toledo</option>
										<option value="Valencia/València">Valencia/València</option>
										<option value="Valladolid">Valladolid</option>
										<option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</option>
										<option value="Zamora">Zamora</option>
										<option value="Zaragoza">Zaragoza</option>
									</select>
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-tools font-regular color-pink"></i>
                                        <i className="fas fa-sort-down font-tiny color-pink icon-options"></i>
                                        <select name="sector" className="input input-profile select-options" id="city"
                                        value = { this.state.sector } onChange = { event => this.setState({ sector: event.target.value }) }>
                                            <option value="">Sector</option>
                                                {
                                                    this.state.sectores.map(
                                                        (arr, index) => 
                                                            <option key = { index } value = { arr.id_sector }> { arr.sector } </option> 
                                                        )
                                                }
                                        </select>
                                    </div>                                   
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center justify-center">
										<i className="fas fa-hashtag font-regular color-pink"></i>
										<Link to="/ajustes/intereses" className="input input-profile">Intereses</Link>                                       
                                    </div>									
									<div className="white-space-8"></div>
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                               
                                <div className="row">
                                    <div className="column right-main content-forms align-center">
                                    <h4>Información privada</h4>
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center justify-center">
									<i className="fas fa-at font-regular color-pink"></i>
                                    <input disabled = { true } type="email" name="email" placeholder="Correo electrónico" className="input input-profile" 
                                    value = { this.state.email } onChange = { event => this.setState({ email: event.target.value })} />
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center justify-center">
									<i className="fas fa-globe font-regular color-pink"></i>
                                    <input type="text" name="web" placeholder="Sitio Web" className="input input-profile" 
                                    value = { this.state.web } onChange = { event => this.setState({ web: event.target.value })} />                                    
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center justify-center">
                                        <i className="fas fa-phone font-regular color-pink font-medium color-pink fa-flip-horizontal "></i>
                                        <input type="text" name="tel" placeholder="Teléfono" className="input input-profile" required minLength="8" maxLength="16"
									value = { this.state.telefono } onChange = { event => this.setState({ telefono: event.target.value }) }/>
                                    </div>
                                    <div className="white-space-8"></div>
                                    </div>
                                </div>
								<div className="white-space-8"></div>
								<div className="row">
                                    <div className="column right-main content-forms align-center">
                                    <h4>Instagram</h4>
									<div className="white-space-8"></div>
									<div className="input-container align-center justify-center">
                                        <i className="fab fa-instagram font-regular color-pink color-pink "></i>
										{
											this.state.disabledInstagram ?
											(
												this.state.loading ?
												(
													<div className="column">
														<div className="justify-start">
															<i className="fas fa-spin fa-spinner color-pink"></i>
														</div>
														<p className="text-center weight-semi">
															ACTUALIZANDO INFORMACIÓN
														</p>
													</div>
												)
												:
												(
													this.state.message ?
													(
														<div className="column">
															<p className=" weight-semi">
																{ this.state.message }
															</p>
														</div>
													)
													:
													( 
														<p className=" weight-semi font-tiny">
															INSTAGRAM CONECTADO
														</p>
													)
												)
											)
											:
											(
												<div className="input cursor-pointer input-profile weight-semi color-white" id="connect-instagram" onClick={this.connectInstagram.bind(this)}>
													{
													this.state.loadingInstagram ?
														(
															<span>
																<i className="fas fa-spin fa-spinner color-pink"></i>
																&nbsp; RECUPERANDO INFORMACIÓN
															</span>
														)
														:
														(
															<span>                                                   
																&nbsp; CONECTAR CON INSTRAGRAM
															</span>
														)
													}
												</div>
											)
										}							
									</div>								
                                    <div className="white-space-8"></div>
                                    <div className="input-container align-center justify-center">
									<i className="fa fa-edit font-regular color-pink"></i>
									<input type="text" name="userName" className="input input-profile " disabled = { true } value={this.state.user} onChange={event => this.setState({ user: event.target.value })} />
                                    </div>
									<div className="white-space-8"></div>
                                    </div>									
                                </div>
                                <div className="white-space-8"></div>
                                <div className="btn-container auto btn-splash justify-center">
                                    <button type="button" className="btn-web-forms btn-purple font-small text-center color-white weight-bold" onClick = {this.updateUserData.bind(this, true)} >
									{
										this.state.loading || this.state.loadingData ? 
										(<i className="fas fa-spin fa-spinner font-small color-white i-right"></i>) :
										("ACTUALIZAR DATOS")
									}
                                    </button>
                                </div>
                                <div className="white-space-32"></div>
                            </form>
					   </div>
                       <ModalPublicationAdd/>
                    </div>
                </div>
                </div>
                </MediaQuery>
				
                <MediaQuery maxWidth = { 768 }>
                <form className="column" id="mobile-form">
                    <div className="profile-container column">
                        <Link to = "/ajustes">
                            <i className="fas fa-times font-small color-white i-left"></i>
                        </Link>
                        <div className="image column align-center justify-center">
                            <div className="responsive-img justify-center align-center">
                                <img name="image" id="img-preview" src = { this.state.fotoPerfil ? this.state.fotoPerfil : HolderImage } alt="Lodo de Marca" title="Lodo de Marca" className="image-profile" onClick = { this.changeImage.bind(this) } />
                            </div>
                        </div>
                        <div className="row justify-center align-center">
                            <h5>
                                Cambiar foto de perfil
                            </h5>
                            <input type="file" name="img-src" id="img-src" className="hidden" onChange = { this.handleChange.bind(this) }/>
                        </div>
                    </div>
                    <div className="justify-center">
                        <div className="container column">  
                            <div className="white-space-16"></div>
                            <div className="row justify-center align-center">
                                <h5 className="weight-bold">
                                    Información personal
                                </h5>
                            </div>
                        <div className="white-space-8"></div>
                            <div className="column">
                                <div className="basic-data column justify-center align-center">
                                    <div className="input-container align-center justify-center">
                                        <div className="justify-center icon">
                                            <i className="fas fa-user-edit font-tiny color-pink"></i>
                                        </div>
                                        <input type="text" name="name" placeholder="Nombre" className="input input-profile" maxLength = "60" minLength = "3"
                                        value = { this.state.nombre } onChange = { event => this.setState({ nombre: event.target.value }) } />
                                    </div>
                                    <div className="input-container align-center justify-center">
                                        <div className="justify-center icon">
                                            <i className="fas fa-map-marked-alt font-tiny color-pink"></i>
                                        </div>
                                        <select name="ciudad" className="input input-profile" id="city"
                                        value = { this.state.ciudad } onChange = { event => this.setState({ ciudad: event.target.value }) }>
                                            <option value="A Coruña">A Coruña</option>
                                            <option value="Álava/Araba">Álava/Araba</option>
                                            <option value="Albacete">Albacete</option>
                                            <option value="Alicante/Alacant">Alicante/Alacant</option>
                                            <option value="Almería">Almería</option>
                                            <option value="Asturias">Asturias</option>
                                            <option value="Ávila">Ávila</option>
                                            <option value="Badajoz">Badajoz</option>
                                            <option value="Barcelona">Barcelona</option>
                                            <option value="Burgos">Burgos</option>
                                            <option value="Cáceres">Cáceres</option>
                                            <option value="Cádiz">Cádiz</option>
                                            <option value="Cantabria">Cantabria</option>
                                            <option value="Castellón/Castelló">Castellón/Castelló</option>
                                            <option value="Ceuta">Ceuta</option>
                                            <option value="Ciudad Real">Ciudad Real</option>
                                            <option value="Córdoba">Córdoba</option>
                                            <option value="Cuenca">Cuenca</option>
                                            <option value="Girona">Girona</option>
                                            <option value="Granada">Granada</option>
                                            <option value="Guadalajara">Guadalajara</option>
                                            <option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</option>
                                            <option value="Huelva">Huelva</option>
                                            <option value="Huesca">Huesca</option>
                                            <option value="Illes Balears">Illes Balears</option>
                                            <option value="Jaén">Jaén</option>
                                            <option value="La Rioja">La Rioja</option>
                                            <option value="Las Palmas">Las Palmas</option>
                                            <option value="León">León</option>
                                            <option value="Lleida">Lleida</option>
                                            <option value="Lugo">Lugo</option>
                                            <option value="Madrid">Madrid</option>
                                            <option value="Málaga">Málaga</option>
                                            <option value="Melilla">Melilla</option>
                                            <option value="Murcia">Murcia</option>
                                            <option value="Navarra">Navarra</option>
                                            <option value="Ourense">Ourense</option>
                                            <option value="Palencia">Palencia</option>
                                            <option value="Pontevedra">Pontevedra</option>
                                            <option value="Salamanca">Salamanca</option>
                                            <option value="Santa Cruz de Tenerife">Santa Cruz de Tenerife</option>
                                            <option value="Segovia">Segovia</option>
                                            <option value="Sevilla">Sevilla</option>
                                            <option value="Soria">Soria</option>
                                            <option value="Tarragona">Tarragona</option>
                                            <option value="Teruel">Teruel</option>
                                            <option value="Toledo">Toledo</option>
                                            <option value="Valencia/València">Valencia/València</option>
                                            <option value="Valladolid">Valladolid</option>
                                            <option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</option>
                                            <option value="Zamora">Zamora</option>
                                            <option value="Zaragoza">Zaragoza</option>
                                        </select>
                                    </div>
                                    <div className="input-container align-center justify-center">
                                        <div className="justify-center icon">
                                            <i className="fas fa-tools font-tiny color-pink"></i>
                                        </div>
                                        <select name="sector" className="input input-profile" id="city"
                                        value = { this.state.sector } onChange = { event => this.setState({ sector: event.target.value }) }>
                                            <option value="">Sector</option>
                                                {
                                                    this.state.sectores.map(
                                                        (arr, index) => 
                                                            <option key = { index } value = { arr.id_sector }> { arr.sector } </option> 
                                                        )
                                                }
                                        </select>
                                    </div>
                                    <div className="input-container align-center justify-center">
                                        <div className="justify-center icon">
                                            <i className="fas fa-hashtag font-tiny color-pink"></i>
                                        </div>
                                        <Link to="/ajustes/intereses" className="input input-profile-noline">Intereses</Link>
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="row justify-center align-center">
                                    <h5 className="weight-bold">Información privada</h5>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="column">
                                <div className="basic-data column justify-center align-center">
                                    <div className="input-container align-center justify-center">
                                        <div className="justify-center icon">
                                            <i className="fas fa-at font-tiny color-pink"></i>
                                        </div>
                                        <input disabled = { true } type="email" name="email" placeholder="Correo electrónico" className="input input-profile" 
                                        value = { this.state.email } onChange = { event => this.setState({ email: event.target.value })} />
                                    </div>
                                    <div className="input-container align-center justify-center">
                                        <div className="justify-center icon">
                                            <i className="fas fa-globe font-tiny color-pink"></i>
                                        </div>
                                        <input type="text" name="web" placeholder="Sitio Web" className="input input-profile" 
                                        value = { this.state.web } onChange = { event => this.setState({ web: event.target.value })} />
                                    </div>
                                    <div className="input-container align-center justify-center">
                                        <div className="justify-center icon">
                                            <i className="fas fa-phone font-tiny color-pink color-pink fa-flip-horizontal"></i>
                                        </div>
                                        <input type="text" name="tel" placeholder="Teléfono" className="input input-profile-noline" 
                                        value = { this.state.telefono } onChange = { event => this.setState({ telefono: event.target.value })} />
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="row justify-center align-center">
                                    <h5 className="weight-bold">Instagram</h5>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="basic-data column justify-center align-center">
                                    <div className="input-container align-center justify-center">
                                        <div className="justify-center icon">
                                            <i className="fas fa-edit font-tiny color-pink"></i>
                                        </div>
                                        <input type="text" name="userName" className="input input-profile-noline" disabled = { true } 
                                        value = { this.state.user } onChange = { event => this.setState({ user: event.target.value }) } />
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                {
                                    this.state.disabledInstagram ?
                                    (
                                        this.state.loading ?
                                        (
                                            <div className="column">
                                                <div className="justify-center">
                                                    <i className="fas fa-spin fa-spinner color-pink"></i>
                                                </div>
                                                <p className="text-center weight-semi">
                                                    ACTUALIZANDO INFORMACIÓN
                                                </p>
                                            </div>
                                        )
                                        :
                                        (
                                            this.state.message ?
                                            (
                                                <div className="column">
                                                    <p className="text-center weight-semi">
                                                        { this.state.message }
                                                    </p>
                                                </div>
                                            )
                                            :
                                            ( 
                                                <p className="text-center weight-semi font-tiny">
                                                    INSTAGRAM CONECTADO, PULSE &nbsp;
                                                    <i className="fas fa-check font-small color-pink"></i> PARA GUARDAR
                                                </p>
                                            )
                                        )
                                    )
                                    :
                                    (
                                        <button type="button" className="btn btn-pink weight-semi color-white" id="connect-instagram" onClick={this.connectInstagram.bind(this)}>
                                            {
                                            this.state.loadingInstagram ?
                                                (
                                                    <span>
                                                        <i className="fas fa-spin fa-spinner color-white"></i>
                                                        &nbsp; RECUPERANDO INFORMACIÓN
                                                    </span>
                                                )
                                                :
                                                (
                                                    <span>
                                                        <i className="fab fa-instagram" />
                                                        &nbsp; CONECTAR CON INSTRAGRAM
                                                    </span>
                                                )
                                            }
                                        </button>
                                    )
                                }
                                <div className="white-space-16"></div>
                                <button type="button" onClick = { this.updateUserData.bind(this, false) } >
                                    {
                                        this.state.loading || this.state.loadingData ?
                                            (<i className="fas fa-spin fa-spinner font-small color-white i-right"></i>) :
                                            (<i className="fas fa-check font-small color-white i-right"></i>)
                                    }
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="white-space-64"></div>
                <BottomBar active = "bottomProfile" />
                </MediaQuery>
                <ModalAlert title="DATOS ACTUALIZADOS" description="" />
                {
					firstTime ? 
					(
						<div className="overlay justify-center" id="overlay-settings">
							<div className="container column justify-center align-center">
								<div className="white-space-64"></div>
                                <div className="logo justify-center align-center wow fadeIn">
	                                <img src = { Logo } alt="Logo Waala" title="Logo Waala" />
                            	</div>
								<div className="white-space-16"></div>
								<div className="waala responsive-img justify-center align-center">
									<img src = { WaalaText } alt="Waala texto" title="Waala texto" />
								</div>
								<div className="white-space-32"></div>
								<p className="text-center color-white">
                                    Para sacar el máximo partido a waala, conecta tu cuenta de Instagram y completa tu perfil
								</p>
								<div className="white-space-16"></div>
								<div className="btn-container justify-center">
									<button type="button" className="btn btn-pink color-white weight-semi" id="btn-close-overlay" onClick = { this.handleClose.bind(this) }>
										ACEPTAR
									</button>
								</div>
								<div className="white-space-64"></div>
							</div>
						</div>
					)
					:
					( null )
                }
            </div>
        );
    }

    componentDidMount() {
        this.load();
        if(this.props.context.updated_interest) {
            this.props.context.interestUpdated(false);
        }
    }

    async load() {
		const user = await this.props.context.loadUser();
		if(user) {
			this.setState({userData: user.id});
			this.loadUserData();
		}
	}

    async loadUserData() {
        const request = new Request();
        this.setState({ loading: true, loadingData: true });
        const data = { id: this.state.userData.id, tipo: this.state.userData.tipo };
        const response = await request.post("/users/profile", data);
        if (response && response.user && !response.error) {
            this.setState({
                userId: response.user.id_marca, fotoPerfil: response.user.imagen_principal_url,
                nombre: response.user.nombre, user: response.user.usuario, ciudad: response.user.ciudad, sector: response.user.sector,
                email: response.user.email, telefono: response.user.telefono, web: response.user.sitio_web, id_instagram: response.user.id_instagram, loading: false, loadingData: false, first_login: response.user.first_login
            });
            if (this.props.location.hash) {
                this.setState({ first_login: false, disabledInstagram: true });
                const hash = String(this.props.location.hash).split("=");
                this.instagramRequest(hash[1]);
            }
        }
        const sectores = await request.get('/sectores');
        if (!sectores.error) {
            this.setState({ sectores: sectores.sectores });
        }
    }

    async instagramRequest(token) {
        this.setState({ loadingInstagram: true });
        const accesToken = token;
        try {
            const instagramData = await superagent.get(`https://api.instagram.com/v1/users/self/?access_token=${accesToken}`);
            console.log(instagramData);
            if (instagramData && instagramData.body.data) {
                this.setState({ user: instagramData.body.data.username, id_instagram: instagramData.body.data.id, loadingInstagram: false});
            }
        } catch (error) {
            console.log(error);
            this.setState({ loadingInstagram: false, disabledInstagram: false });
        }
    }

    handleClose() {
        document.getElementById('overlay-settings').classList.add('hidden');
    }

    async updateUserData(desktop) {
        let form = '';
        if (desktop) {
            form = document.getElementById('desktop-form');
        } else {
            form = document.getElementById('mobile-form');
        }
        this.setState({ loading: true });
        if (this.state.updateImage) {
            if (form.image.src.includes('data:image')) {
                this.setState({ disabled: true, message: '' });
                const storageRef = window.firebase.storage().ref();
                let dateTime = new Date();
                let fileName = dateTime.getFullYear() + "-" + (dateTime.getMonth() + 1) + "-" + dateTime.getDate() + "-" + dateTime.getHours() + "/" + dateTime.getMinutes() + ":" + dateTime.getSeconds() + ":" + dateTime.getMilliseconds();
                const ref = storageRef.child(`profile/${fileName}`);
                const imageURL = await ref.putString(form.image.src, 'data_url')
                .then(async snapshot => {
                    const url = await snapshot.ref.getDownloadURL()
                        .then(downloadURL => {
                            return downloadURL;
                        });
                    return url
                });
                if (this.state.id_instagram) {
                    const data = {
                        userId: this.state.userId, nombre: form.name.value, user: form.userName.value,
                        ciudad: form.ciudad.value, sector: form.sector.value, email: form.email.value, web: form.web.value,
                        fotoPerfil: imageURL, telefono: form.tel.value, id_instagram: this.state.id_instagram
                    };
                    const userDataUpdate = await request.post("/marcas/updatemarca", data);
                    if (userDataUpdate) {
                        if (userDataUpdate.update) {
                            this.setState({ message: userDataUpdate.message, update: userDataUpdate.update });
                            let modal = document.getElementById('modal-profileAlert');
                            if (modal.classList.contains('hidden')) {
                                modal.classList.remove('fadeOut');
                                modal.classList.add('animated', 'fadeIn');
                                modal.classList.remove('hidden');
                            }
                            if (desktop) {
                                setTimeout(() => {
                                    window.location = "/ajustes/perfil/marca";
                                }, 2000);
                            } else {
                                setTimeout(() => {
                                    window.location = "/ajustes";
                                }, 2000);
                            }
                        } else  {
                            if (userDataUpdate.unavailable) {
                                this.setState({ message: userDataUpdate.message, loading: false });
                            }
                        }
                        this.setState({ loading: false });
                    } else {
                        this.setState({ message: userDataUpdate.message, loading: false });
                    }
                    this.setState({ loading: false });
                } else {
                    alert("VINCULA TU CUENTA INSTAGRAM");
                    this.setState({ loading: false });
                }
            } else {
                this.setState({ message: 'Por favor selecciona una imagen valida' });
                return;
            }
        } else {
            if (this.state.id_instagram) {
                const data = {
                    userId: this.state.userId, nombre: form.name.value, user: form.userName.value,
                    ciudad: form.ciudad.value, sector: form.sector.value, email: form.email.value, web: form.web.value,
                    fotoPerfil: this.state.fotoPerfil, telefono: form.tel.value, id_instagram: this.state.id_instagram
                };
                const userDataUpdate = await request.post("/marcas/updatemarca", data);
                if (userDataUpdate) {
                    if (userDataUpdate.update) {
                        this.setState({ message: userDataUpdate.message, update: userDataUpdate.update });
                        let modal = document.getElementById('modal-profileAlert');
                        if (modal.classList.contains('hidden')) {
                            modal.classList.remove('fadeOut');
                            modal.classList.add('animated', 'fadeIn');
                            modal.classList.remove('hidden');
                        }
                        if (desktop) {
                            setTimeout(() => {
                                window.location = "/ajustes/perfil/marca";
                            }, 2000);
                        } else {
                            setTimeout(() => {
                                window.location = "/ajustes";
                            }, 2000);
                        }
                    } else {
                        if (userDataUpdate.unavailable) {
                            this.setState({ message: userDataUpdate.message, loading: false });
                        }
                    }
                    this.setState({ loading: false });
                } else {
                    this.setState({ message: userDataUpdate.message, loading: false });
                }
                this.setState({ loading: false });
            } else {
                alert("VINCULA TU CUENTA INSTAGRAM");
                this.setState({ loading: false });
            }
        }
    }

    async connectInstagram() {
        this.setState({ loadingInstagram: true });
        window.location = 'https://api.instagram.com/oauth/authorize/?client_id=e889ae006edb437dbdc8bddc7d8c859a&redirect_uri=https://www.waalainfluencers.com/ajustes/perfil/marca&response_type=token&hl=en&scope=likes+follower_list';
    }

}
export default Consumer(ProfileBrands);
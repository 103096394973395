import Request from '../core/httpClient';

const req = new Request();

export async function loadUser() {
	let user = window.localStorage.getItem("waalausr");
	if ( user ) {
		user = JSON.parse(user);
		const res = await req.post('/users/tokuser', {id: user.id});
		if(res.data) {
			user.id = JSON.parse(res.data);
		} else {
			user.id = {id: 0, tipo: 0}
		}
		this.setState({user: user});
		return user;
	}
}

export function saveRegister(data) {
	this.setState({registerData: data});
}

export function logIn(data) {
	window.localStorage.setItem("waalausr", JSON.stringify(data));
	this.setState({user: data});
}

export function logOut() {
	window.localStorage.setItem("waalausr", '{"auth": false}');
	window.localStorage.removeItem("waalausr");
	this.setState({ user: { auth: false } });
}

export function interestUpdated(updated) {
	this.setState({updated_interest: updated})
}
/**
 *  profile-influencer.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Perfil publico del influencer
*/

import React, { Component } from 'react';
import Header, { HeaderWebHomeLoggedIn } from '../components/header/header';
import BottomBar from '../components/bottom-bar/bottombar';
import PublicationCard from '../components/cards/publications';
import { Consumer } from "../context";
import { Link } from 'react-router-dom';
import Request from "../core/httpClient";
import MediaQuery from 'react-responsive';
import LeftBar from '../components/left-bar/left-bar';
import ImageLoader from 'react-loading-image';
import LoaderImage from '../img/loader.gif';
import { Helmet } from 'react-helmet';
import ModalPublicationAdd from '../components/modals/publicationsAdd'; 
import ModalDeleteBrandPublication from '../components/modals/deletePublication';

class ProfileBrand extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {id: 0, tipo: 0}, marca: ''
        }
    }

    componentWillUnmount() {
        this.props.context.setPrevLocation(this.props.location);
        this.mounted = false;
    }

    render() {
        let marca = '', publications = '', empty = '', interests = [];
        if (this.state.marca) { 
            marca = this.state.marca; 
            interests = JSON.parse(marca.intereses);
        }
        if (this.state.publications) { publications = this.state.publications; }
        if (this.state.empty) { empty = this.state.empty }
        return (
            <div className="profile-brand column">
                <Helmet>
                    <title>WAALA - Perfil</title>
                    <meta name="robots" content="noindex" />
                    <meta property="og:title" content="WAALA - Perfil" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <HeaderWebHomeLoggedIn />
                        <div className="row">
                            <LeftBar active = "menuProfile" />
                            <div className="column">
                                <div className="row justify-center">
                                    <div className="container column align-center justify-center">
                                        <div className="white-space-32"></div>
                                        <div className="row header-profile">
                                            <div className="column profile-column justify-center align-center">
                                                <div className="responsive-img justify-center align-center">
                                                    <ImageLoader src = { marca.imagen_principal_url } alt="Logo de Marca" title="Logo de Marca" className="profile-image"
                                                    loading = { () => <img className="profile-image loader-img" alt="Logo de Marca" title="Logo de Marca" src = { LoaderImage } />} />
                                                </div>
                                            </div>
                                            <div className="column justify-start align-center">
                                                <div className="white-space-24"></div>
                                                <div className="row">
                                                    <div className="column">
                                                        <h2 className="weight-semi">
                                                            { marca.nombre }
                                                        </h2>
                                                        <p className="">
                                                            @{ marca.usuario }
                                                        </p>
                                                    </div>
                                                    <div className="auto align-center">
                                                        <Link to="/ajustes/perfil/marca" className="setting-icon">
                                                            <i className="fas fa-cog icon-config"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="white-space-24"></div>
                                                <div className="row">
                                                    <div className="column column-located">
                                                        <h4 className="font-small">
                                                            Ciudad
                                                        </h4>
                                                        <p className="text-start">
                                                            { marca.ciudad }
                                                        </p>
                                                    </div>
                                                    <div className="column column-followers">
                                                        <h4 className="font-small text-center">
                                                            Usuario
                                                        </h4>
                                                        <p className="text-center">
                                                            @{ marca.usuario }
                                                        </p>
                                                    </div>
                                                    <div className="column column-price">
                                                        <h4 className="font-small text-center">
                                                            Sitio web
                                                        </h4>
                                                        <p className="text-center"> 
                                                            <a href = { marca.sitio_web } target="_blank" rel="noopener noreferrer" className="text-center">
                                                                { marca.sitio_web }
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    <div className="white-space-8"></div>
                                    <div className="skills row wrap">
                                    {
                                        interests ?
                                        (
                                            interests.map((interest, key) => (
                                                <div className="interes" key = { key } >
                                                    <p className="weight-semi text-center color-white wow animated fadeIn">
                                                        { interest.cat }
                                                    </p>
                                                </div>
                                                )
                                            )
                                        )
                                        :
                                        (
                                            <div className="column loading-center">
                                                <div className="white-space-64"></div>
                                                <div className="justify-center">
                                                    <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                </div>
                                            </div>
                                        )
                                    }
                                    </div>
                                    <div className="row justify-center">
                                            <div className="cards-container cards-container-mine column align-center justify-center">                              
                                                <div className = { `wrap ${empty ? null : 'grid-container'}` }>
                                                {
                                                    empty ? 
                                                    (
                                                        <div className="column message-center">
                                                            <div className="white-space-32"></div>
                                                            <i className="far fa-images text-center color-pink"></i>
                                                            <div className="white-space-8"></div>
                                                            <div className="empty justify-center align-center">
                                                                <p className="text-center weight-semi font-regular">
                                                                Añade imágenes de tus productos, e indica cuanto pagarías a los influencers por que te ayuden a promocionarlas en sus perfiles de Redes Sociales.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    (
                                                        publications ? 
                                                        (
                                                            publications.map((publication, key) => (
                                                                <PublicationCard imagen = { publication.imagen_url } nombre = { marca.nombre } tarifa = { publication.pago } ciudad = { marca.ciudad } 
                                                                key = { key } role = "owner" id = { publication.id_publicacion } idMarca = { this.state.userData.id } />
                                                            ))
                                                        ) :
                                                        ( 
                                                            <div className="column loading-center">
                                                                <div className="white-space-64"></div>
                                                                <div className="justify-center">
                                                                    <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    )
                                                }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="white-space-32"></div>
                                    </div>                       
                                </div>
                                <ModalPublicationAdd />
                                <ModalDeleteBrandPublication id = { this.props.context.publicationBrandToDelete.idPublication } idMarca = { this.props.context.publicationBrandToDelete.idMarca } />
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                <Header showSettings = {true} />
                <div className="brand-cards column">
                    <div className="banner-brand justify-center">
                        <div className="container">
                            <div className="brand-picture responsive-img justify-center align-center">
                                <ImageLoader src = { marca.imagen_principal_url } alt="Logo de Marca" title="Logo de Marca" className="cover-img"
                                loading={ () => <img className="cover-img loader-img" alt="Logo de Marca" title="Logo de Marca" src = { LoaderImage } /> } />
                            </div>
                        </div>
                    </div>
                    <div className="banner-brand justify-center"> 
                        <div className="container column">
                            <div className="data justify-center">
                                <div className="container">
                                    <div className="column">
                                        <Link to="/ajustes" className="icon-favorites">
                                            <i className="fas fa-cog font-medium color-white icon-favorites"></i>
                                        </Link>
                                        <h4 className="text-center">
                                            { marca.nombre }
									    </h4>
                                        <div className="white-space-8"></div>
                                        <div className="row justify-arround">
                                            <p>
                                                @{ marca.usuario }
                                            </p>
                                            <p>
                                                { marca.ciudad }
                                            </p>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="justify-center">
                                                <a href = { marca.sitio_web } target="_blank" rel="noopener noreferrer" className="text-center">
                                                { marca.sitio_web }
                                            </a>
                                        </div>
                                        <div className="white-space-8"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tabs-container-brand">
                        <button className="btn btn-square btn-pink text-center font-regular" onClick = { this.handleGallery.bind(this) } >
                            <i className="far fa-images color-pink" id="gallery-tab"></i>
                        </button>
                        <button className="btn btn-square btn-pink text-center font-regular" onClick = { this.handleInterest.bind(this) }>
                            <i className="fas fa-hashtag color-gray" id="interest-tab"></i>
                        </button>
                    </div>
                </div>
                <div className="justify-center" id="gallery">
                    <div className="cards-container container column">
                        {
                            empty ? 
                            (
                                <div className="empty justify-center align-center">
                                    <p className="text-center">
                                        Añade imágenes de tus productos, e indica cuanto pagarías a los influencers por que las promocionen en sus perfiles de Redes Sociales
                                    </p>
                                </div>
                            ) :
                            (
                                publications ? 
                                (
                                    publications.map((publication, key) => (
                                        <PublicationCard imagen = { publication.imagen_url } nombre = { marca.nombre } tarifa = { publication.pago } ciudad = { marca.ciudad } 
                                        key = { key } role = "owner" id = { publication.id_publicacion } idMarca = { this.state.userData.id } />
                                    ))
                                ) :
                                ( 
                                    <div className="empty justify-center align-center">
                                        <i className="fa-spin fas fa-spinner fa-2x color-purple"></i>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
                <div className="intereses-container wrap hidden" id="intereses">
                    {
                        interests ?
                        (
                            interests.map((interest, key) => (
                                <div className="interes" key = { key } >
                                     <div className="column justify-center movile-labels">
                                    <p className="weight-semi text-center color-white wow animated fadeIn">
                                        { interest.cat }
                                    </p>
                                    </div>
                                </div>
                                )
                            )
                        )
                        :
                        (
                            <div className="empty justify-center align-center">
                                <i className="fa-spin fas fa-spinner fa-2x color-purple"></i>
                            </div>
                        )
                    }
                </div>
                <div className="white-space-64"></div>
                    <ModalDeleteBrandPublication id = { this.props.context.publicationBrandToDelete.idPublication } idMarca = { this.props.context.publicationBrandToDelete.idMarca } />
                    <BottomBar active = "bottomProfile" />
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({user: user.id});
            this.getMarca();
        }
    }

    async getMarca() {
        const user = await this.props.context.loadUser();
        if (user) { this.setState({userData: user.id}) }
        const request = new Request();
        const data = { idMarca: user.id.id };
        const marca = await request.post("/marcas/getProfile", data);
        if (marca) {
            this.setState({ message: marca.message, marca: marca.marca });
        }
        this.getBrandPublications();
    }

    async getBrandPublications() {
        if(this.state.marca) {
            const request = new Request();
            const data = { userName: this.state.marca.usuario };
            const publications = await request.post("/publications/getByBrand", data);
            if (publications) {
                if (publications.empty) {
                    this.setState({ empty: publications.empty });
                } else {
                    this.setState({
                        message: publications.message,
                        publications: publications.publications
                    });
                }
            }
        }
    }

    handleGallery() {
        let gallery = document.getElementById('gallery');
        let intereses = document.getElementById('intereses');
        let galleryTab = document.getElementById('gallery-tab');
        let interestTab = document.getElementById('interest-tab');
        if (interestTab.classList.contains('color-pink')) {
            interestTab.classList.remove('color-pink');
            interestTab.classList.add('color-gray');
            galleryTab.classList.remove('color-gray');
            galleryTab.classList.add('color-pink');
        }
        intereses.classList.add('hidden');
        gallery.classList.remove('hidden');
    }

    handleInterest() {
        let gallery = document.getElementById('gallery');
        let intereses = document.getElementById('intereses');
        let galleryTab = document.getElementById('gallery-tab');
        let interestTab = document.getElementById('interest-tab');
        if (galleryTab.classList.contains('color-pink')) {
            galleryTab.classList.remove('color-pink');
            galleryTab.classList.add('color-gray');
            interestTab.classList.remove('color-gray');
            interestTab.classList.add('color-pink');
        }
        gallery.classList.add('hidden');
        intereses.classList.remove('hidden');
    }

}

export default Consumer(ProfileBrand);
/**
 *  login.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio, Fernando
 *  @description: Página de login
*/

import React, { Component } from "react";
import { HeaderSingle } from "../components/header/header";
import { Link, withRouter } from "react-router-dom";
import Request from "../core/httpClient";
import { Consumer } from "../context/";
import superagent from "superagent";
import WOW from 'wowjs';
import { Helmet } from 'react-helmet';

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false, message: "", user: {id: 0, tipo: 0}
		};
	}	

  render() {
    return (
    	<div className="login column">
			<Helmet>
				<title>WAALA - Login</title>
				<meta property="og:title" content="WAALA - Login" />
			</Helmet>
        	<HeaderSingle />
        	<div className="white-space-32" />
        	<div className="justify-center">
				<div className="container column">
					<h2 className="text-center wow fadeIn">Inicio de sesión</h2>
					<div className="white-space-32" />
					<form className="column" onSubmit={this.handleSubmit.bind(this)}>
						<div className="white-space-16" />
						<div className="input-container align-center wow fadeInUp faster">
							<i className="fas fa-at font-small text-center" />
							<input type="email" name="email" placeholder="Correo electrónico" minLength="8" maxLength="50" required className="input" />
						</div>
						<div className="white-space-8" />
						<div className="input-container align-center wow fadeInUp faster">
							<i className="fas fa-key font-small text-center" />
							<input type="password" name="password" placeholder="Contraseña" required className="input" minLength="8" maxLength="16" />
						</div>
						<div className="white-space-48" />
						<div className="btn-container justify-center wow fadeInUp faster">
							<button className="btn btn-pink weight-semi font-regular color-white">
								{
									this.state.loading ?
										(<i className="fas fa-spin fa-spinner color-white"></i>) :
										("INICIAR SESIÓN")
								}
							</button>
						</div>
						<div className="white-space-16" />
						<div className="btn-container justify-center wow fadeInUp faster">
							<button type="button" className="btn btn-pink weight-semi color-pink" id="btn-instagram" onClick={this.connectInstagram.bind(this)}>
								{
									this.state.loadingInstagram ?
										(
											<span>
												<i className="fas fa-spin fa-spinner color-pink"></i>
												&nbsp; RECUPERANDO INFORMACIÓN
											</span>
										)
										:
										(
											<span>
												<i className="fab fa-instagram" />
												&nbsp; INICIAR CON INSTRAGRAM
											</span>
										)
								}
							</button>
						</div>
						<div className="white-space-16" />
						{
							this.state.message ? (
								<div className="justify-center">
									<p>
										<strong>{ this.state.message }</strong>
									</p>
								</div>
							) : 
							null
						}
						<div className="white-space-24" />
						<div className="justify-center wow fadeIn faster">
							<Link to="/recoverpassword" className="text-center">
								¿Olvidaste tu contraseña?
							</Link>
						</div>
						<div className="white-space-8" />
					</form>
				</div>
        	</div>
			<div className="white-space-32" />
    	</div>
		);
	}

	componentDidMount() {
        new WOW.WOW({ live: false }).init();
		this.load();
		this.validateUser();
    }    

    async load() {
		const user = await this.props.context.loadUser();
		if (user) { this.setState({user: user.id}); }
	}

    validateUser() {
        if (this.state.user && this.state.user.id) { 
		window.location = '/inicio'; 
		} else {
			if (this.props.location.hash) {
				const hash = String(this.props.location.hash).split("=");
				this.instagramRequest(hash[1]);
			}
		}
  	}

	async connectInstagram() {
		this.setState({ loadingInstagram: true });
		window.location = 'https://api.instagram.com/oauth/authorize/?client_id=e889ae006edb437dbdc8bddc7d8c859a&redirect_uri=https://www.waalainfluencers.com/login&response_type=token&hl=en&scope=likes+follower_list';
	}

	async instagramRequest(token) {
		this.setState({ loadingInstagram: true });
		const accesToken = token;
		try {
			const instagramData = await superagent.get(`https://api.instagram.com/v1/users/self/?access_token=${accesToken}`)
			if (instagramData && instagramData.body.data) {
				this.setState({ id_instagram: instagramData.body.data.id, loadingInstagram: false });
				this.hanldeSubmitInstagram();
			}
		} catch (error) {
			console.log(error);
			this.setState({ loadingInstagram: false });
		}
	}

	async hanldeSubmitInstagram() {
		this.setState({ loading: true });
		const data = { id_instagram: this.state.id_instagram };
		const request = new Request();
		const response = await request.post("/users/login/instagram", data);
		if (response) {
			if (response.error) {
				this.setState({ message: response.message });
			} else {
				if ( response.user ) {
					this.props.context.logIn({ id: response.user, auth: true });
					const user = await this.props.context.loadUser();
					this.setState({ user: user.id });
					if (user) {
						if (user.id.tipo === 1) {
							if (user.id.first_login === 1) {
								this.props.history.push("/ajustes/perfil");
							} else {
								this.props.history.push("/inicio");
							}
						} else {
							window.localStorage.removeItem('whtab');
							window.localStorage.removeItem('wftab');
							if (user.id.tipo === 2) {
								if (user.id.first_login === 1) {
									this.props.history.push("/ajustes/perfil/marca");
								} else {
									this.props.history.push("/inicio");
								}
							}
						}
					} else {
						this.setState({ message: "NO EXISTE EL USUARIO" });
					}
				} else {
					this.setState({ message: "NO EXISTE EL USUARIO SOLICIDATO" });
				}
			}
		} else {
			this.setState({ message: "ERROR DE COMUNICACIÓN LA WEB" });
		}
		this.setState({ loading: false });
	}

	async handleSubmit(event) {
		this.setState({ loading: true });
		event.preventDefault();
		const form = event.target;
		const data = { Email: form.email.value, Password: form.password.value };
		const request = new Request();
		const response = await request.post("/users/login", data);
		if (response) {
			if (response.error) {
				this.setState({ message: response.message });
			} else {
				if ( response.user ) {
					this.props.context.logIn({ id: response.user, auth: true });
					const user = await this.props.context.loadUser();
					this.setState({ user: user.id });
					if (user) {
						if (user.id.tipo === 1) {
							if (user.id.first_login === 1) {
								this.props.history.push("/ajustes/perfil");
							} else {
								this.props.history.push("/inicio");
							}
						} else {
							window.localStorage.removeItem('whtab');
							window.localStorage.removeItem('wftab');
							if (user.id.tipo === 2) {
								if (user.id.first_login === 1) {
									this.props.history.push("/ajustes/perfil/marca");
								} else {
									this.props.history.push("/inicio");
								}
							}
						}
					} else {
						this.setState({ message: "NO EXISTE EL USUARIO" });
					}
				} else {
					this.setState({ message: "NO EXISTE EL USUARIO SOLICIDATO" });
				}
			}
		} else {
			this.setState({ message: "ERROR DE COMUNICACIÓN LA WEB" });
		}
		this.setState({ loading: false });
	}


}

export default withRouter(Consumer(Login));

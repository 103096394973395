import React from 'react';

const ModalAlert = (props) => 
    (
        <div className="modal column justify-center align-center hidden faster" id="modal-profileAlert">
            <div className="container modal-content align-center column" >
                <div className="column modal-head">
                    <div className="white-space-64"></div>
                    <h3 className="text-center">
                        {  props.title }
                    </h3>
                    <div className="white-space-8"></div>
                    <p className="text-center">
                        { props.description }
                    </p>
                    <div className="white-space-64"></div>
                </div>
            </div>
        </div>
    );    

export default ModalAlert;
/**
 *  splash.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio - kath
 *  @description: LandingPage de Inicio
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InfluencersImage from '../img/splash.jpg';
import Logo from '../img/logotransparente.png';
import WaalaText from '../img/waalatext.png';
import MediaQuery from 'react-responsive';
import { HeaderWebLogin } from '../components/header/header';
import { Consumer } from '../context';
import Login from '../components/modals/Login';
import ModalRegister from '../components/modals/register';
import Contact from '../components/modals/contact';
import { Helmet } from 'react-helmet';
import WOW from 'wowjs';
import Previewinflucencer from '../img/influencerpreview.png';
import Previewbrand from '../img/brandpreview.png';
class Splash extends Component {

    constructor(props) {
        super(props);
        this.state = { user: {id: 0, tipo: 0}, type : 'influencers' }
        this.loadFlickity();
    }   


    loadFlickity() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js';
        document.body.append(script);
    } 

    handleChangeView(types){
        this.setState({type: types});
    } 

    render() {
        return (
            <div className="splash justify-center"  style={{ backgroundImage: `url(${InfluencersImage })`}}>
                <Helmet>
                    <title>WAALA - Influencers</title>
                    <meta property="og:title" content="WAALA - Influencers" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                    <HeaderWebLogin />
                        <div className="overlay-desk justify-center column align-center" style={{ backgroundImage: `url(${InfluencersImage })`}}>
                            <div className="container column align-center justify-center">
                                <div className="white-space-64"></div>
                                <div className="white-space-64"></div>
                                <div className="white-space-64"></div>
                                <div className="white-space-64"></div>
                                <div className="white-space-64"></div>
                                <div className="container-card auto">
                                    <div className="row justify-end align-center">
                                        <div className="content card-splash-influencer auto column justify-center align-center">
                                            <i className="fas fa-user fa-2x color-white"></i>
                                            <div className="white-space-8"></div>
                                            <h1 className="color-white text-tittle-card">
                                                SOY INFLUENCER
                                            </h1>
                                            <div className="overlay-card column">
                                                <h4 className="text-cards">Gana dinero promocionando productos</h4>
                                                <h4 className="text-cards">de tus marcas favoritas.</h4>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="row justify-start align-center">
                                        <div className="content card-splash-marca auto column justify-center align-center">
                                            <i className="fas fa-tag fa-2x color-white"></i>
                                            <div className="white-space-8"></div>
                                            <h1 className="color-white text-tittle-card">
                                                SOY MARCA
                                            </h1>
                                            <div className="overlay-card2 column">
                                                <h4 className="text-cards">Busca personas que promocionen tus</h4>
                                                <h4 className="text-cards">productos en sus RRSS.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="more align-center">
                                    <div className="justify-center">
                                        <a href="#info">
                                            <p className="color-white weight-semi text-center font-regular">
                                                Así funciona waala
                                            </p>
                                        </a>
                                    </div>
                                    <div className="auto">
                                        <a href="#info">
                                            <i className="fas fa-chevron-down color-white"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            this.state.type === "influencers" ? 
                            (
                                <div className="information justify-center" id="info">
                                    <div className="container column wow animated fadeIn">
                                        <div className="white-space-64"></div>
                                        <div className="white-space-32"></div>
                                        <div className="row">
                                            <div className="left column">
                                                <div className="buttons">
                                                    <div className="btn-container-buttons">
                                                        <button className="button-information weight-semi active-influencer">
                                                            Para Influencers
                                                        </button>                                
                                                    </div>
                                                    <div className="btn-container-buttons">
                                                        <button className="button-information weight-semi inactive-brand" onClick = { this.handleBrand.bind(this) }>
                                                            Para Marcas
                                                        </button>                                
                                                    </div>
                                                </div>
                                                <div className="white-space-32"></div>
                                                <h2 className="text-center">
                                                    Gana dinero promocionando productos de tus marcas favoritas
                                                </h2>
                                                <div className="white-space-32"></div>
                                                <div className="row">
                                                    <div className="info-cards column">
                                                        <div className="icon justify-center">
                                                            <i className="fas fa-dollar-sign font-double color-pink weight-bold"></i>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="title justify-center">
                                                            <h3 className="">
                                                                Establece tu tarifa
                                                            </h3>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="description justify-center">
                                                            <p className="text-justify">
                                                                Indica el precio que querrias cobrar por realizar colaboraciones con las marcas.
                                                            </p>
                                                        </div>
                                                    </div>  
                                                    <div className="info-cards column">
                                                        <div className="icon justify-center">
                                                            <i className="fas fa-tag font-double color-pink weight-bold"></i>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="title justify-center">
                                                            <h3 className="">
                                                                Busca marcas
                                                            </h3>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="description justify-center">
                                                            <p className="text-justify">
                                                                Busca marcas por su nombre, sector, o precio que paguen por colaboración, y contacta con ellas.
                                                            </p>
                                                        </div>
                                                    </div>              
                                                </div>
                                                <div className="white-space-32"></div>
                                                <div className="row">
                                                    <div className="info-cards column">
                                                        <div className="icon justify-center">
                                                            <i className="fas fa-comments font-double color-pink weight-bold"></i>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="title justify-center">
                                                            <h3 className="">
                                                                Contacta con ellas
                                                            </h3>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="description justify-center">
                                                            <p className="text-justify">
                                                                Contacta con las marcas a través del chat para acordar las condiciones de la promoción, o la forma de pago.
                                                            </p>
                                                        </div>
                                                    </div>  
                                                    <div className="info-cards column">
                                                        <div className="icon justify-center">
                                                            <i className="fas fa-tag font-double icon-transparent"></i>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="title justify-center">
                                                            <h3 className="color-pink weight-bold">
                                                                ¡Es totalmente gratis!
                                                            </h3>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="description justify-center">
                                                            <p className="text-justify">
                                                                
                                                            </p>
                                                        </div>
                                                    </div>              
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="screen-influencer">
                                                    <div className="responsive-img justify-center align-center">
                                                        <img src = { Previewinflucencer } alt="Captura de la aplicación" title="Captura de la aplicación"/>            
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="white-space-64"></div>
                                        <div className="white-space-32"></div>
                                    </div>
                                </div>
                            )
                            :
                            (
                                <div className="information justify-center" id="info">
                                    <div className="container column wow animated fadeIn">
                                        <div className="white-space-64"></div>
                                        <div className="white-space-32"></div>
                                        <div className="row">
                                            <div className="left-invert">
                                                <div className="screen-influencer">
                                                    <div className="responsive-img justify-center align-center">
                                                        <img src = { Previewbrand } alt="Captura de la aplicación" title="Captura de la aplicación"/>            
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right-invert column">
                                                <div className="buttons">
                                                    <div className="btn-container-buttons">
                                                        <button className="button-information weight-semi inactive-influencer" onClick = { this.handleInfluencer.bind(this) }>
                                                            Para Influencers
                                                        </button>                                
                                                    </div>
                                                    <div className="btn-container-buttons">
                                                        <button className="button-information weight-semi active-brand">
                                                            Para Marcas
                                                        </button>                                
                                                    </div>
                                                </div>
                                                <div className="white-space-32"></div>
                                                <h2 className="text-center">
                                                    Contacta con influencers que promocionen tus productos.
                                                </h2>
                                                <div className="white-space-32"></div>
                                                <div className="row">
                                                    <div className="info-cards column">
                                                        <div className="icon justify-center">
                                                            <i className="fas fa-tag font-double color-pink weight-bold"></i>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="title justify-center">
                                                            <h3 className="">
                                                                Sube tus productos
                                                            </h3>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="description justify-center">
                                                            <p className="text-justify">
                                                                Sube a tu perfil las fotos de los productos que te gustaría que promocionaran los influencers, e indica cuanto pagarías por realizar colaboraciones con ellos.
                                                            </p>
                                                        </div>
                                                    </div>  
                                                    <div className="info-cards column">
                                                        <div className="icon justify-center">
                                                            <i className="fas fa-users font-double color-pink weight-bold"></i>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="title justify-center">
                                                            <h3 className="">
                                                                Busca influencers
                                                            </h3>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="description justify-center">
                                                            <p className="text-justify">
                                                                Busca en base a las necesidades que tengas, por su numero de seguidores, tarifa que cobran, intereses, ciudad, etc.
                                                            </p>
                                                        </div>
                                                    </div>              
                                                </div>
                                                <div className="white-space-32"></div>
                                                <div className="row">
                                                    <div className="info-cards column">
                                                        <div className="icon justify-center">
                                                            <i className="fas fa-comments font-double color-pink weight-bold"></i>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="title justify-center">
                                                            <h3 className="">
                                                                Contacta con ellos
                                                            </h3>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="description justify-center">
                                                            <p className="text-justify">
                                                                Contacta con los influencers a través del chat para acordar las condiciones de la promoción, o la forma de pago.
                                                            </p>
                                                        </div>
                                                    </div>  
                                                    <div className="info-cards column" id="last-brand">
                                                        <div className="icon justify-center">
                                                            <i className="fas fa-dollar-sign font-double color-pink"></i>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="title justify-center">
                                                            <h3 className="">
                                                                Pagos
                                                            </h3>
                                                        </div>
                                                        <div className="white-space-8"></div>
                                                        <div className="description justify-center">
                                                            <p className="text-justify">
                                                                Opcionalmente, podrás realizar el pago a través de la app, y el influencer recibirá el dinero sólo cuando la publicación se haya realizado.
                                                            </p>
                                                        </div>
                                                    </div>              
                                                </div>
                                            </div>
                                        </div>
                                        <div className="white-space-64"></div>
                                        <div className="white-space-32"></div>
                                    </div>
                                </div>
                            )
                        }
                        <div className="footer align-center justify-center ">
                            <div className="row align-center justify-arround"> 
                                <Link to="/files/terminos-y-condiciones.pdf" target="_blank">
                                    <h4 className="font-tiny color-white">
                                        Términos y condiciones
                                    </h4>
                                </Link>
                                <Link to="/files/politica-de-privacidad.pdf" target="_blank">
                                    <h4 className="font-tiny color-white">
                                        Privacidad
                                    </h4>
                                </Link>
                                <Link to="/files/politica-de-cookies.pdf" target="_blank">
                                    <h4 className="font-tiny color-white">
                                        Cookies
                                    </h4>
                                </Link>
                                <a href="#info" className="auto" onClick = { this.handleContact.bind(this) }>
                                    <h4 className="font-tiny color-white">
                                        Contacto
                                    </h4>
                                </a>
                                <div className="column social-media">
                                    <div className="row justify-arround">
                                        <a href="https://www.instagram.com/waala_influencers/?hl=es" target="_blank">
                                            <i className="fab fa-instagram color-white font-medium"></i>
                                        </a>
                                        <a href="https://www.facebook.com/waala.influencers.3" target="_blank">
                                            <i className="fab fa-facebook-square color-white font-medium"></i>
                                        </a>
                                        <a href="https://twitter.com/WAALA45159765" target="_blank">
                                            <i className="fab fa-twitter-square color-white font-medium"></i>
                                        </a>
                                    </div>
                                </div>
                                <p className="color-white"><i className="far fa-copyright font-tini color-whitse"></i> 2019 Waala</p>
                            </div>
                        </div>
                        <Contact single = { true } />
                        <Login />
                        <ModalRegister />
                    </div>
                </MediaQuery>

                <MediaQuery maxWidth = { 768 }>
                    <div className="overlay justify-center">
                        <div className="container column">
                            <div className="white-space-64"></div>
                            <div className="white-space-64"></div>
                            <div className="logo justify-center align-center wow fadeIn">
                                <img src = { Logo } alt="Logo Waala" title="Logo Waala" />
                            </div>
                            <div className="white-space-16"></div>
                            <div className="text responsive-img justify-center align-center wow fadeIn">
                                <img src = { WaalaText } alt="Waala texto" title="Waala texto" />
                            </div>
                            <div className="white-space-64"></div>
                            <div className="slider justify-center align-center wow fadeIn">
                                <div className="content" data-flickity='{ "cellAlign": "left", "contain": true, "autoPlay": true }'>
                                    <div className="slide column">
                                        <p className="color-white text-center">
                                            <strong>¿Quieres ser influencer?</strong> 
                                        </p>
                                        <p className="color-white text-center">
                                            Gana dinero promocionando productos de tus marcas favoritas.
                                        </p>
                                    </div>
                                    <div className="slide column">
                                        <p className="color-white text-center">
                                            <strong>¿Eres marca?</strong> 
                                        </p>
                                        <p className="color-white text-center">
                                            Encuentra personas que promocionen tus productos en RRSS.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="white-space-64"></div>
                            <div className="btn-container justify-center">
                                <Link to="/registro" className="btn btn-pink btn-full text-center color-white">
                                    EMPEZAR 
                                </Link>
                            </div>
                            <div className="white-space-24"></div>
                            <div className="justify-center">
                                <Link to="/login" className="color-white">
                                    ¿Ya tienes cuenta? <span className="weight-semi">Inicia sesión</span>
                                </Link>
                            </div>
                            <div className="white-space-64"></div>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }
    
    componentDidMount() {
        new WOW.WOW({ live: false }).init();
        this.load();
    }    

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
			this.setState({user: user.id});
        	this.validateUser();
		}
    }

    validateUser() {
        if (this.state.user && this.state.user.id) { window.location = '/inicio'; }
    }

    handleBrand() {
        this.setState({ type: "brand" });
    }

    handleInfluencer() {
        this.setState({ type: "influencers" });
    }

    handleContact() {
        const modal = document.getElementById('modal-contact');
        if (modal) {
            if (modal.classList.contains('hidden')) {
                modal.classList.remove('fadeOut');
                modal.classList.add('animated', 'fadeIn');
                modal.classList.remove('hidden');
            }
        }
    }

}

export default Consumer(Splash);
/**
 *  bottombar.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Menu inferior
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Consumer } from '../../context';
import socket from '../../core/socket';
import Request from '../../core/httpClient';

const req = new Request();

class BottomBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {id: 0, tipo: 0},
            newMessage: false
        }
    }

    componentDidMount() {

        const allMobileMenus = document.getElementsByClassName('mobile-menu');
        for (let index = 0; index < allMobileMenus.length; index++) {
            allMobileMenus[index].classList.remove('active-mobile');
        }        
        switch (this.props.active) {
            case 'bottomHome':
                document.getElementById(this.props.active).classList.add('active-bottom');
                document.getElementById(this.props.active).parentNode.querySelector("p").style.color = "#FF0357";
                break;
            case 'bottomFavorites':
                document.getElementById(this.props.active).classList.add('active-bottom');
                document.getElementById(this.props.active).parentNode.querySelector("p").style.color = "#FF0357";
                break;
            case 'add-new':
                document.getElementById(this.props.active).classList.add('active-bottom');
                document.getElementById(this.props.active).parentNode.querySelector("p").style.color = "#FF0357";
                break;
            case 'bottomChats':
                document.getElementById(this.props.active).classList.add('active-bottom');
                document.getElementById(this.props.active).parentNode.querySelector("p").style.color = "#FF0357";
                break;
            case 'bottomProfile':
                document.getElementById(this.props.active).classList.add('active-bottom');
                document.getElementById(this.props.active).parentNode.querySelector("p").style.color = "#FF0357";
                break;
            default:
                break;
        }

        this.load();
        socket.on('newMessage', (data) => {
            const chatsMenu = document.getElementById('bottomChats_link');
            if(chatsMenu) {
                window.localStorage.setItem('wnm', `1;${data.who.id}`);
                this.setState({newMessage: true});
            }
        })
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) { this.setState({userData: user.id}); }
    }

    render() {
        let tipo = "0";
        if (this.state.userData.tipo) { tipo = this.state.userData.tipo.toString(); }
        let wnm = '';
        if(window.localStorage.getItem('wnm')) {
            wnm = window.localStorage.getItem('wnm');
        }
        return (
            <div className="bottom-bar">
                <div className="justify-center">
                    <div className="container align-center">
                        <div className="menu-item justify-start">
                            <Link to="/inicio" className="column">
                                <i className="fas fa-images mobile-menu" id="bottomHome"></i>
                                <p className={`text-center ${this.props.active === "bottomHome" ? 'active-bottom' : ''}`}>
                                    Inicio
                                </p>
                            </Link>
                        </div>
                        <div className="menu-item justify-start">
                            <Link to="/favoritos" className="column">
                                <i className="fas fa-heart fav mobile-menu" id="bottomFavorites"></i>
                                <p className={`text-center ${this.props.active === "bottomFavorites" ? 'active-bottom' : ''}`}>
                                    Favoritos
                                </p>
                            </Link>
                        </div>
                        <div className="menu-item justify-center">
                            <Link to="/nuevo">
                                <i className="fas fa-plus-circle mobile-menu color-pink" id="add-new"></i>
                            </Link>
                        </div>
                        <div className="menu-item justify-end" id="chats">
                            <Link to="/chats" className="column" id="bottomChats_link">
                                <i className="fas fa-comments chats mobile-menu" id="bottomChats"></i>
                                {
                                    wnm.includes("1") || this.state.newMessage ?
                                        <i className="fas fa-circle" id="chat-circle"></i>
                                    : null
                                }
                                <p className={`text-center ${this.props.active === "bottomChats" ? 'active-bottom' : null}`}>
                                    Chats
                                </p>
                            </Link>
                        </div>
                        <div className="menu-item justify-end">
                            <Link to = { `${ tipo === "1" ? "/perfil/influencer" : tipo === "2" ? "/perfil/marca" : "/inicio" }` } className="column">
                                <i className="fas fa-user mobile-menu bottom-profile-icon" id="bottomProfile"></i>
                                <p className={`text-center ${this.props.active === "bottomProfile" ? 'active-bottom' : null}`}>
                                    Perfil
                                </p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        );   
    }
}

export default Consumer(BottomBar);
/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio - kath
 *  @description: Página inicial tras Login
*/

import React, { Component } from 'react';
import Header, { HeaderWebHomeLoggedIn } from '../components/header/header';
import BottomBar from '../components/bottom-bar/bottombar';
import LeftBar from '../components/left-bar/left-bar';
import PublicationCard from '../components/cards/publications';
import BrandCard from '../components/cards/brands';
import InfluencerCard from '../components/cards/influencers';
import MediaQuery from 'react-responsive';
import { Consumer } from "../context";
import Request from '../core/httpClient';
import Tabs from '../components/tabs/tabs';
import ModalPublicationAdd from '../components/modals/publicationsAdd'; 
import { Helmet } from 'react-helmet';

const req = new Request();

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {id: 0, tipo: 0},
            cards: [], tab: 'first'
        }
    }

    componentWillUnmount() {
        this.props.context.setPrevLocation(this.props.location);
        this.mounted = false;
    }

    handleWebAdd() {
        let modalAdd = document.getElementById('modal-Add');
        modalAdd.classList.remove('fadeOut');
        modalAdd.classList.add('animated', 'fadeIn','faster');
        modalAdd.classList.remove('hidden');
    }
    
    render() {
        const user = this.state.user;
        const tipo = user.tipo ? user.tipo.toString() : '0';
        const cardIni = (props) => {
            return user.tipo.toString() === "2" ? 
                <InfluencerCard { ...props } /> : 
                this.state.tab === 'first'? <PublicationCard { ...props } /> : <BrandCard { ...props } />;
        }
        return (
            <div className="home column">
                <Helmet>
                    <title>WAALA - Inicio</title>
                    <meta property="og:title" content="WAALA - Inicio" />
                </Helmet>
                <MediaQuery minDeviceWidth = { 768 }>
                    <div className="view-web column">
                        <HeaderWebHomeLoggedIn/>
                        <div className="row">
                            <LeftBar active = "menuHome" />
                            <div className="column">
                                <div className="row justify-center">
                                    <div className="container column align-center justify-center">
                                        <div className="white-space-16"></div>
                                        {
                                            tipo === "1" ?
                                                <Tabs tab = { this.state.tab } first = { tipo === "1"? "PUBLICACIONES" : "" }
                                                second = "MARCAS" user = { this.state.user }  changeTab = { this.changeTab.bind(this)} />
                                            : null
                                        }
                                        <div className="cards-container wrap">
                                        {
                                            this.state.loading ?
                                            (
                                                <div className="column loading-center">
                                                    <div className="white-space-16"></div>
                                                    <div className="justify-center">
                                                        <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            (
                                                this.state.cards.map(card => cardIni({
                                                    key: card.id, id: card.id, usuario: card.usuario,
                                                    tarifa: card.pago, ciudad: card.ciudad, seguidores: card.seguidores,
                                                    imagen: card.imagen, favorito: card.favorito, userId: this.state.user.id,
                                                    tipo: this.state.user.tipo, tipo_pub: card.tipo_pub, nombre: card.nombre,
                                                    descripcion: card.descripcion, titulo: card.titulo
                                                })
                                                )
                                            )
                                        }
                                        </div>
                                    </div>
                                </div>                               
                                <ModalPublicationAdd/>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth = { 768 }>
                    <div className="column sticky-header">
                        <Header back='/inicio' showBack={false} tipo={tipo} />
                        {
                            tipo === "1" ?
                                <Tabs tab={this.state.tab} first={tipo === "1" ? "PUBLICACIONES" : ""}
                                    second="MARCAS" user={this.state.user} changeTab={this.changeTab.bind(this)} />
                                : null
                        }
                    </div>
                    <div className="justify-center">
                        <div className="cards-container container column">
                            {
                                this.state.loading ?
                                (
                                    <div className="column">
                                        <div className="white-space-16"></div>
                                        <div className="justify-center">
                                            <i className="fas fa-spin fa-2x fa-spinner color-purple"></i>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    this.state.cards.map(card => cardIni({
                                        key: card.id, id: card.id, usuario: card.usuario,
                                        tarifa: card.pago, ciudad: card.ciudad, seguidores: card.seguidores,
                                        imagen: card.imagen, favorito: card.favorito, userId: this.state.user.id,
                                        tipo: this.state.user.tipo, tipo_pub: card.tipo_pub, nombre: card.nombre,
                                        descripcion: card.descripcion, titulo: card.titulo
                                    })
                                    )
                                )
                            }
                            <div className="white-space-64"></div>
                        </div>
                    </div>
                    <BottomBar active="bottomHome" />
                </MediaQuery>
            </div>
        );
    }

    componentDidMount() {
        this.mounted = true;
        this.load();
    }    

    async load() {
        this.setState({loading: true});
        const tab = window.localStorage.getItem('whtab');
        if(tab) {
            this.setState({tab});
        }
        const user = await this.props.context.loadUser();
        if(user && this.mounted) {
            this.setState({user: user.id});
        }
        const data = { user: this.state.user };
        const res = await req.post('/inicio/get', data);
        if (res.data && this.mounted) {
            const cards = res.data.filter(card => {
                if (this.state.tab === 'first') {
                    if (card.tipo_pub === 'publicacion' || card.tipo_pub === 'influencer') {
                        return card;
                    }
                } else {
                    if (card.tipo_pub === 'marca') {
                        return card;
                    }
                }
            })
            this.setState({ cards, loading: false });
        } else {
            this.setState({ loading: false });
        }
    }

    async loadMenu() {
        this.setState({ loading: true });
        const data = { id: this.state.user.id, tipo: this.state.user.tipo };
        const image = await req.post("/users/images/single", data);
        if (image) {
            this.setState({ message: image.message });
            if (image.empty) {
                this.setState({ empty: image.empty });
            } else {
                if (image.image) {
                    this.setState({ imagen: image.image });
                }
            }
        }
        this.setState({ loading: false });
    }

    changeTab(tab) {
        window.localStorage.setItem('whtab', tab)
        this.setState({ tab, cards: [] });
        this.load();
    }

}

export default Consumer(Home);
